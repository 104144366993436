<footer>
    <div class="container">
        <div class="footerTop">
            <div class="footerTopLeft">
                <div class="logo"><a href="{{isElseverse ? '/elseverse' : '#'}}"><img src="{{isElseverse ? 'assets/images/elseverse/elseverse-logo.svg' : 'assets/images/logo.png'}}" alt=""></a></div>
                <form action="">
                    <input type="email" placeholder="E-mail">
                    <button style="{{isElseverse ? 'background-color: #00F0FF' : ''}}">Subscribe</button>
                </form>
            </div>
            <div class="footerTopRight">
                <ul class="footerNav">
                    <li><a routerLink="/" routerLinkActive="activ" [routerLinkActiveOptions]="{exact: true}">Marketplace</a></li>
                    <li><a routerLink="/about" routerLinkActive="activ">About</a></li>
                    <li><a href="https://app.gamespad.io/" target="_blank">Launchpad</a></li>
                    <li><a routerLink="/lottery" routerLinkActive="activ" [routerLinkActiveOptions]="{exact: true}">Lottery</a></li>
                    <li *ngIf="isConnected"><a routerLink="/user-page" routerLinkActive="activ">My NFTs</a></li>
                    <li *ngIf="elseverseService.canShowPage"><a routerLink="/elseverse" routerLinkActive="activ">Elseverse</a></li>
                </ul>

                <div *ngIf="isElseverse" class="social">
                    <a href="https://t.me/ElseVerseAnnouncements" target="_blank"><img src="assets/images/telegram.svg" alt="telegram"></a>
                    <a href="https://twitter.com/ElseVerseWorld" target="_blank"><img src="assets/images/twitter.svg" alt="twitter"></a>
                    <a href="https://www.instagram.com/elseverse_world/" target="_blank"><img src="assets/images/instagram.svg" alt="instagram"></a>
                    <a href="http://www.tiktok.com/@elseverseworld" target="_blank"><img src="assets/images/tiktok.svg" alt="tiktok" /></a>
                    <a href="https://discord.gg/elseverseworld" target="_blank"><img src="assets/images/discord.svg" alt="discord"></a>
                    <a href="https://www.reddit.com/user/ElseVerseWorld/" target="_blank"><img src="assets/images/reddit.svg" alt="reddit" /></a>
                    <a href="https://medium.com/@elseverseworld" target="_blank"><img src="assets/images/medium.svg" alt="medium" /></a>
                  <!--<a href="https://www.linkedin.com/company/80384085" target="_blank"><img src="assets/images/linkedin.svg" alt="linkedin"></a>-->
                </div>

                <div *ngIf="!isElseverse" class="social">
                    <a href="https://t.me/GamesPadAnnouncements" target="_blank"><img src="assets/images/telegram.svg" alt="telegram"></a>
                    <a href="https://www.facebook.com/gamespad.vc" target="_blank"><img src="assets/images/facebook.svg" alt="facebook"></a>
                    <a href="https://twitter.com/gamespad_vc" target="_blank"><img src="assets/images/twitter.svg" alt="twitter"></a>
                    <a href="https://www.linkedin.com/company/gamespad/" target="_blank"><img src="assets/images/linkedin.svg" alt="linkedin"></a>
                    <a href="https://www.instagram.com/gamespad.io/" target="_blank"><img src="assets/images/instagram.svg" alt="instagram"></a>
                </div>
            </div>
        </div>

        <div class="footerBottom">
            <div class="footerBottom_gp">© GamesPad 2024</div>

            <ul class="footerBottom_nav">
                <li><a href="/documents/terms-of-condition" >Terms Of Conditions</a></li>
                <li><a href="/documents/cookie-policy" >Cookie Policy</a></li>
                <li><a href="/documents/privacy-policy" >Privacy Policy</a></li>
            </ul>
        </div>
    </div>
</footer>
