import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ElseverseService } from '../../services/elseverse.service';
import { Router } from '@angular/router';
import { INftInfo } from '../../models/nft-info.interface';
import { ElseverseApiService } from '../../services/api/elseverse-api.service';
import { DOCUMENT } from '@angular/common';
import { LandsaleMapComponent } from "./landsale-map/landsale-map.component";
import { PositionsApiService } from "../../services/api/positions-api.service";
import { Web3Service } from "../../services/web3.service";

@Component({
  selector: 'elseverse',
  templateUrl: './elseverse.component.html',
  styleUrls: ['./elseverse.component.scss'],
})
export class ElseverseComponent implements OnInit {
  public readonly sortOptions: any[] = [
    { name: 'ID number 1-9', field: 'id', direction: 'asc'},
    { name: 'ID number 9-1', field: 'id', direction: 'desc'},
    { name: 'Increasing Price', field: 'price', direction: 'asc' },
    { name: 'Decreasing Price', field: 'price', direction: 'desc' },
    { name: 'Rarity: Low to High', field: 'rarity ', direction: 'asc' },
    { name: 'Rarity: High to Low', field: 'rarity ', direction: 'desc' },
    { name: 'Biome Type: A to Z', field: 'biomeType', direction: 'asc' },
    { name: 'Biome Type: Z to A', field: 'biomeType', direction: 'desc' },
  ];

  public readonly sellTypeOptions: any[] = [
    { name: 'All', value: 'all' },
    { name: 'Fixed Price', value: 'fixedPrice' },
    { name: 'Auction', value: 'auction' }
  ];

  public readonly biomeTypeOptions: any[] = [
    { name: 'All', value: 'all' },
    { name: 'Jungle', value: 'jungle' },
    { name: 'Mountains', value: 'mountains' },
    { name: 'Shoreline', value: 'shoreline' }
  ];

  public readonly rarityTypeOptions: any[] = [
    { name: 'All', value: 'all' },
    { name: 'Common', value: 'common' },
    { name: 'Uncommon', value: 'uncommon' },
    { name: 'Rare', value: 'rare' },
    { name: 'Epic', value: 'epic' },
    { name: 'Legendary', value: 'legendary' }
  ];

  public selectedSort: any = this.sortOptions[0];
  public selectedSellType: any = this.sellTypeOptions[0];
  public selectedBiomeType: any = this.biomeTypeOptions[0];
  public selectedRarityType: any = this.rarityTypeOptions[0];
  public lands: any[] = [];
  public filteredLands: any[] = [];
  public data: any[] = [];
  public currentPage: number = 1;
  public pageSize: number = 8;
  public showLoadMore: boolean = true;
  private isLoading: boolean = false;

  @ViewChild("map") Map: ElementRef;
  @ViewChild(LandsaleMapComponent) mapComponent: LandsaleMapComponent;

  constructor(
    private readonly api: ElseverseApiService,
    private readonly elseverseService: ElseverseService,
    private readonly positionsApi: PositionsApiService,
    public readonly web3: Web3Service,
    private readonly router: Router,
    @Inject(DOCUMENT) private document: any,
  ) {}

  async ngOnInit() {
    if (!this.elseverseService.canShowPage) {
      this.router.navigate(['/']);
    }
    this.makeRequest();
  }

  async makeRequest() {
    this.startLoading();

    try {
      const [lands, positions] = await Promise.all([
        this.api.getLands().toPromise(),
        this.positionsApi.getAllPositions().toPromise()
      ]);

      const positionItems = positions?.items.filter(x =>
        x.tokenAddress.toLowerCase() == this.web3.chain.landsAddress?.toLowerCase()
      );

      this.lands = lands.map(land => {
        const position = positionItems?.find(x => x.tokenId == land.tokenId);
        land.isOnSale = !!position;
        land.positionId = position?.id || null;
        land.isAuction = position?.isAuction || false;
        if (position) {
          land.isSold = false;
        }
        return land;
      }).filter(land => !land.isSold);

      this.filteredLands = this.lands;
      this.data = this.filteredLands.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    }
    finally {
      this.stopLoading();
    }
  }

  loadMore() {
    let maxPages = Math.ceil(this.filteredLands.length / this.pageSize);
    if (this.currentPage < maxPages) {
      this.currentPage++;
      this.data.push(
        ...this.filteredLands.slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize
        )
      );
      if (this.currentPage === maxPages) {
        this.showLoadMore = false;
      }
    }
  }

  buyClick(item: INftInfo) {
    this.Map.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.mapComponent.selectLand(item.id, true);
  }

  private startLoading() {
    if (!this.isLoading) {
      this.document.body.classList.add('wait_openid');
    }
    this.isLoading = true;
  }

  private stopLoading() {
    if (this.isLoading) {
      this.document.body.classList.remove('wait_openid');
    }
    this.isLoading = false;
  }

  public filterAndSortItems() {
    this.filteredLands = this.lands.filter((land) => {
      const sellType = land.isAuction ? "auction": "fixedPrice";
      if (this.selectedSellType.value !== "all" && sellType !== this.selectedSellType.value) {
        return false;
      }else {
        this.filteredLands = this.lands
      }
      if (this.selectedBiomeType.value !== "all" && land.landType.biome !== this.selectedBiomeType.value) {
        return false;
      }else {
        this.filteredLands = this.lands
      }
      if (this.selectedRarityType.value !== "all" && land.landType.rarity !== this.selectedRarityType.value) {
        return false;
      }else {
        this.filteredLands = this.lands
      }
      return true;
    });

    if (this.selectedSort.name === "ID number 1-9") {
      this.filteredLands.sort((a, b) => a.id - b.id);
    } else if (this.selectedSort.name === "ID number 9-1") {
      this.filteredLands.sort((a, b) => b.id - a.id);
    } else if (this.selectedSort.name === "Increasing Price") {
      this.filteredLands.sort((a, b) => a.landType.price - b.landType.price);
    } else if (this.selectedSort.name === "Decreasing Price") {
      this.filteredLands.sort((a, b) => b.landType.price - a.landType.price);
    } else if (this.selectedSort.name === "Rarity: Low to High") {
      const rarityOrder = ['common', 'uncommon', 'rare', 'epic', 'legendary'];
      this.filteredLands.sort((a, b) => rarityOrder.indexOf(a.landType.rarity) - rarityOrder.indexOf(b.landType.rarity));
    } else if (this.selectedSort.name === "Rarity: High to Low") {
      const rarityOrder = ['legendary', 'epic', 'rare', 'uncommon', 'common'];
      this.filteredLands.sort((a, b) => rarityOrder.indexOf(a.landType.rarity) - rarityOrder.indexOf(b.landType.rarity));
    } else if (this.selectedSort.name === "Biome Type: A to Z") {
      this.filteredLands.sort((a, b) => a.landType.biome.localeCompare(b.landType.biome));
    } else if (this.selectedSort.name === "Biome Type: Z to A") {
      this.filteredLands.sort((a, b) => b.landType.biome.localeCompare(a.landType.biome));
    }

    this.currentPage = 1;
    this.data = this.filteredLands.slice(
      (this.currentPage - 1) * this.pageSize,
      this.currentPage * this.pageSize
    );
  }

  public setSort(sortOption: any) {
    this.selectedSort = sortOption;
    this.filterAndSortItems();
  }

  public setSellType(sellTypeOption: any) {
    this.selectedSellType = sellTypeOption;
    this.filterAndSortItems();
  }

  public setBiomeType(biomeTypeOption: any) {
    this.selectedBiomeType = biomeTypeOption;
    this.filterAndSortItems();
  }

  public setRarityType(rarityTypeOption: any) {
    this.selectedRarityType = rarityTypeOption;
    this.filterAndSortItems();
  }

  public resetFilter(): void {
    this.selectedSort = this.sortOptions[0];
    this.selectedSellType = this.sellTypeOptions[0];
    this.selectedBiomeType = this.biomeTypeOptions[0];
    this.selectedRarityType = this.rarityTypeOptions[0];
    this.currentPage = 1;
    this.filteredLands = this.lands
    this.data = this.filteredLands.sort((a, b) => a.id - b.id).slice(
      (this.currentPage - 1) * this.pageSize,
      this.currentPage * this.pageSize
    )
  }
}
