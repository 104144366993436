import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { Web3Service } from 'src/app/services/web3.service';
import { environment } from 'src/environments/environment';
import { BetModel, LotteryDto } from '../../dto/lottery.dto';
import { LotteryApiService } from "../../services/api/lottery-api.service";
import { LotteryModel, LOTTERY_PRICE_DIGIT } from "../../models/lottery.model";
import { AlertService } from "../../services/alert.service";
import { TokensApiService } from "../../services/api/tokens-api.service";
import { ModalService } from "../modal/modal.service";
import { Router } from "@angular/router";
import { RealtimeService } from "../../services/realtime.service";
import { LotteryWinnerEvent } from "../../dto/events/lottery-winner.event";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: 'app-lottery-page',
  templateUrl: './lottery-page.component.html',
  styleUrls: ['./lottery-page.component.scss']
})
export class LotteryPageComponent implements OnInit, OnDestroy {

  tokens: any[] = [
    { value: 'gmpd', viewValue: 'GMPD', img: 'assets/images/icon_t.png', address: environment.gmpd },
    { value: 'blp', viewValue: 'BLP', img: 'assets/images/icon_blp.png', address: environment.blp },
  ];
  networks: any[] = [
    { value: 'bsc', viewValue: 'BNB Chain', img: 'assets/images/network/bsc.svg' },
    { value: 'ethereum', viewValue: 'Ethereum', img: 'assets/images/network/eth.svg' },
    { value: 'polygon', viewValue: 'Polygon', img: 'assets/images/network/polygon.svg' },
  ];
  networkInfo: any = {
    bsc: 0,
    ethereum: 1,
    polygon: 2
  };
  now: number = Date.now();
  isModalActive: boolean = false;
  isAdmin: boolean = false;
  collectionFormControl = new FormControl('', [Validators.required]);
  nftIDFormControl = new FormControl('', [Validators.required]);
  startFormControl = new FormControl('', [Validators.required]);
  endFormControl = new FormControl('', [Validators.required]);
  minFormControl = new FormControl('', [Validators.required]);
  maxFormControl = new FormControl('', [Validators.required]);
  currentPositionID = 0;
  currentTokenAmount = 0;
  currentTokenType = '';
  validateCreateDataError = "";
  selectedNetwork = "bsc";
  createLotteryData = {
    networkId: <number>null,
    lotteryID: 0,
    collection: '',
    nftID: <number>null,
    start: <number>null,
    end: <number>null,
    min: <number>null,
    max: <number>null,
    blpPrice: <number>null,
    gmpdPrice: <number>null
  };

  lotteries: LotteryModel[];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly web3: Web3Service,
    private readonly api: LotteryApiService,
    private readonly tokensApi: TokensApiService,
    public readonly modalService: ModalService,
    private readonly alertSrv: AlertService,
    private readonly router: Router,
    private readonly realtimeService: RealtimeService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.api.getLotteries().subscribe((data: LotteryModel[]) => {
      this.lotteries = data;
      for (let lottery of this.lotteries) {
        this.tokensApi.getNftInfo(lottery.collectionAddress, lottery.nftId, lottery.nftNetworkId).subscribe(async tokenMetaData => {
          if (tokenMetaData.tokenUri) {
            let metadata;
            try {
              metadata = await this.tokensApi.getInfo(tokenMetaData.tokenUri).toPromise();
              console.log('metadata >>', metadata)
            } catch(err) {
              console.log('metadata err', err)
            }
            if (metadata) tokenMetaData.metadata = {...tokenMetaData.metadata, ...metadata};
          }
          lottery.metadata = tokenMetaData;
        });
      }
      console.log('getLottery >>>', this.lotteries);
    });

    this.subscriptions.push(this.realtimeService.lotteryWinnerEvent$.subscribe((winner: LotteryWinnerEvent) => {
      const lottery = this.lotteries?.filter(l => l.id == winner.lotteryId)[0];
      if (lottery) {
        lottery.winnerAddress = winner.winner;
      }
    }));

    this.subscriptions.push(this.realtimeService.lotteryCreatedEvent$.subscribe((newLottery: LotteryModel) => {
      this.lotteries.unshift(Object.assign({}, newLottery));
    }));
  }

  public floor(data: any) {
    return Math.floor(data);
  }

  public ngOnInit(): void {
    window.setInterval(() => {
      this.now = Date.now();
    }, 1000);
    this.isAdmin = this.web3.isLotteryAdmin();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub?.unsubscribe();
    }
  }

  public openModal(index: number, amount: number): void {
    const selectedLottery: LotteryModel = this.lotteries[index];
    if (selectedLottery.isFinished || !selectedLottery.isStarted)
      return;
    if (!this.web3.currentAccountValue) {
      this.alertSrv.show('Please connect wallet to participate');
      return;
    }
    this.currentTokenAmount = amount;
    this.isModalActive = true;
    this.currentPositionID = selectedLottery.id;
    this.currentTokenType = this.tokens.filter(item => item.address.toLowerCase() == selectedLottery.currencyAddress.toLowerCase())[0].value;
    document.body.classList.add('jw-modal-open');
  }

  public closeModal(): void {
    document.body.classList.remove('jw-modal-open');
    this.isModalActive = false;
  }

  private startLoading() {
    this.document.body.classList.add('wait_openid');
  }

  private stopLoading() {
    this.document.body.classList.remove('wait_openid');
  }

  public async createLottery(): Promise<void> {
    let succeed = false;
    this.collectionFormControl.markAllAsTouched();
    this.nftIDFormControl.markAllAsTouched();
    this.startFormControl.markAllAsTouched();
    this.endFormControl.markAllAsTouched();
    this.minFormControl.markAllAsTouched();
    this.maxFormControl.markAllAsTouched();

    if (
      this.createLotteryData.collection == "" ||
      this.createLotteryData.min == 0 ||
      this.createLotteryData.max == 0 ||
      this.createLotteryData.start == 0 ||
      this.createLotteryData.end == 0
    ) {
      this.validateCreateDataError = "Please fill in all fields";
      return;
    }

    if (this.createLotteryData.min < 0) {
      this.validateCreateDataError = "Min price can't be lower than zero";
      return;
    }

    if (this.createLotteryData.start < 0) {
      this.validateCreateDataError = "Start time can't be lower than zero";
      return;
    }

    if (this.createLotteryData.min > this.createLotteryData.max) {
      this.validateCreateDataError = "Max price can't be lower than min price";
      return;
    }
    if (this.createLotteryData.end < Math.floor(Date.now() / 1000)) {
      this.validateCreateDataError = "End date can't be before than now";
      return;
    }
    if (this.createLotteryData.start > this.createLotteryData.end) {
      this.validateCreateDataError = "End date can't be before than start date";
      return;
    }

    this.createLotteryData.networkId = this.networkInfo[this.selectedNetwork];
    this.validateCreateDataError = "";
    if (this.createLotteryData.networkId != 0) {
      let ifNftExist = await this.web3.checkNftExist(this.createLotteryData.networkId, this.createLotteryData.collection, this.createLotteryData.nftID);
      if (!ifNftExist) {
        this.alertSrv.show(`This NFT is not locked`);
        return;
      }
    }
    this.startLoading();
    let createdLotteryID;
    try {
      let blpData = await this.tokensApi.getTokenInfoFromCoingecko('bullperks').toPromise();
      let gmpdData = await this.tokensApi.getTokenInfoFromCoingecko('gamespad').toPromise();
      this.createLotteryData.blpPrice = blpData.market_data.current_price.usd;
      this.createLotteryData.gmpdPrice = gmpdData.market_data.current_price.usd;

      createdLotteryID = await this.web3.createLottery(
        this.createLotteryData.networkId,
        this.createLotteryData.collection,
        this.createLotteryData.nftID,
        this.createLotteryData.start,
        this.createLotteryData.end,
        this.createLotteryData.min,
        this.createLotteryData.max,
        Math.floor(this.createLotteryData.blpPrice * LOTTERY_PRICE_DIGIT),
        Math.floor(this.createLotteryData.gmpdPrice * LOTTERY_PRICE_DIGIT)
      );
      succeed = true;
    } catch (e: any) {
      this.alertSrv.show(`Unexpected error happened: ${e.message}`);
    }

    if (succeed) {
      try {
        console.log('createdLotteryID >>>>>>', createdLotteryID);
        const data: LotteryDto = {
          nftNetworkId: environment.chainInfo[this.createLotteryData.networkId],
          lotteryId: createdLotteryID,
          startDate: (new Date(this.createLotteryData.start * 1000)).toISOString(),
          endDate: (new Date(this.createLotteryData.end * 1000)).toISOString(),
          minPrice: this.createLotteryData.min,
          maxPrice: this.createLotteryData.max,
          collectionAddress: this.createLotteryData.collection,
          nftId: this.createLotteryData.nftID.toString(),
          blpPrice: this.createLotteryData.blpPrice,
          gmpdPrice: this.createLotteryData.gmpdPrice
        }
        let createResult = await this.api.createLottery(data);
      } catch (e) {
      }
      this.alertSrv.show(`Lottery # ${createdLotteryID} created.`);
    }
    this.stopLoading();
  }

  openLottery(lottery: LotteryModel) {
    this.router.navigate(['lottery', lottery.id]);
  }
}
