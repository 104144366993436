import {
  HttpClient, HttpHeaders
} from "@angular/common/http";
import {Observable} from 'rxjs/internal/Observable';
import {environment} from "../../../environments/environment";

export abstract class BaseApiService {
  private get baseUrl(): string {
    return `${environment.apiUrl}`;
  }

  private get elseVerseUrl(): string {
    return `${environment.elseVerseApiUrl}`;
  }

  private get moralisUrl(): string {
    return `https://deep-index.moralis.io/api/v2`;
  }

  private get wertUrl(): string {
    return environment.wert.origin;
  }

  private get moralisHeaders(): HttpHeaders {
    return new HttpHeaders({'X-API-KEY': environment.moralisApiKey});
  }

  private get wertHeaders(): HttpHeaders {
    return new HttpHeaders({'X-Partner-ID': environment.wert.partnerId});
  }

  private get coingeckoUrl(): string {
    return `https://api.coingecko.com/api/v3/coins`;
  }

  protected constructor(
    protected httpClient: HttpClient
  ) {
  }

  protected getRequest<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(`${this.baseUrl}/${url}`);
  }

  protected getElseVerseRequest<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(`${this.elseVerseUrl}/${url}`);
  }

  protected postRequest<T>(url: string, body: object): Observable<T> {
    return this.httpClient.post<T>(`${this.baseUrl}/${url}`, body);
  }

  protected postElseVerseRequest<T>(url: string, body: object): Observable<T> {
    return this.httpClient.post<T>(`${this.elseVerseUrl}/${url}`, body);
  }

  protected postWertRequest<T>(url: string, body: object): Observable<T> {
    return this.httpClient.post<T>(`${this.wertUrl}/${url}`, body, {headers: this.wertHeaders});
  }

  protected putRequest<T>(url: string, object: object): Observable<T> {
    return this.httpClient.put<T>(`${this.baseUrl}/${url}`, object);
  }

  protected deleteRequest<T>(url: string): Observable<T> {
    return this.httpClient.delete<T>(`${this.baseUrl}/${url}`);
  }

  protected getFromMoralis<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(`${this.moralisUrl}/${url}`, {headers: this.moralisHeaders});
  }

  protected getTokenInfo<T>(token: string): Observable<T> {
    return this.httpClient.get<T>(`${this.coingeckoUrl}/${token}`);
  }

  protected get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url);
  }
}
