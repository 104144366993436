<section class="cabinet_wallet">
    <div class="container">
        <div class="cabinet_wallet_top">
            <div class="cabinet_wallet_top_bg"><img src="assets/images/connect_wallet.png" alt=""></div>
            <div class="cabinet_wallet_top_img"><img src="assets/images/user-c.png" alt=""></div>                
        </div>

        <div class="cabinet_wallet_info">
            <h2>User Info</h2>
            <div class="copy-block"  style="position:relative">
                <p>{{currentAccount | async}}</p>
                <button  class="--copy" [cdkCopyToClipboard]="currentAccount | async"><img  src="assets/images/copy.png" alt=""></button>
            </div>
        </div>



        <app-user-nfts></app-user-nfts>
        <!-- <div class="cabinet_wallet_bottom">
            <a href="#" class="cabinet_wallet_bottom_a">Edit your profile</a>

            <form action="#">
                <div class="block-form">
                    <label for="user_name">Username</label>
                    <input type="text" id="user_name" placeholder="@ alexlook">
                </div>

                <div class="block-form">
                    <label for="name">Name</label>
                    <input type="text" id="name" placeholder="Alex Look">
                </div>

                <div class="block-form">
                    <label for="descr">Description</label>
                    <input type="text" id="descr" placeholder="All we ">
                </div>

                <div class="block-form">
                    <label for="twitter">Twitter</label>
                    <input type="text" id="twitter" placeholder="@ alexlook">
                </div>

                <div class="block-form">
                    <label for="instagram">Instagram</label>
                    <input type="text" id="instagram" placeholder="@ alexlook">
                </div>

                <div class="block-form">
                    <label for="instagram">Telegram</label>
                    <input type="text" id="telegram" placeholder="@ alexlook">
                </div>

                <div class="border-href"><button>Save</button></div>
            </form>
        </div> -->
    </div>
</section>