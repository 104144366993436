import { Injectable, NgZone } from "@angular/core";
/*import * as anchor from "@project-serum/anchor";
import { ConfirmOptions, Connection } from "@solana/web3.js";*/
import { BehaviorSubject } from "rxjs";
import networks from '../../app/components/shared/networks.data';
import { AuthService } from "./auth.service";
import { ChainType } from "../enums/chain-type";

declare let Connection: any;
class ConfirmOptions{};

@Injectable({ providedIn: 'root' })
export class SolanaService {
  provider: any;//anchor.AnchorProvider;
  connection: any;//anchor.web3.Connection;
  private currentProvider: any;
  public isConnected: boolean = false;

  private readonly currentAccount = new BehaviorSubject<string>('');
  public readonly currentAccount$ = this.currentAccount.asObservable();

  private readonly currentNetwork = new BehaviorSubject<string>('');
  public readonly currentNetwork$ = this.currentNetwork.asObservable();

  constructor(private readonly ngZone: NgZone, private authService: AuthService) {
    const chainType = this.authService.getChainType();
    if (chainType == ChainType.Solana) {
      const account = localStorage.getItem('accountAddress');
      const network = localStorage.getItem('networkId');

      if (account && network) {
        this.connectWallet(account, parseInt(network));
      }
    }
  }

  public async connectWallet(account: string = null, network: number = null) {
    this.currentProvider = (window as any).solana;
    network = network || this.authService.getChainId();
    account = account || (await this.currentProvider.connect()).publicKey.toString();
    this.isConnected = true;
    this.setAccount(account);
    this.setNetwork(network.toString());

    const networkData = networks.find((n) => n.chainId == network);
    this.connection = new Connection(networkData.networkParams.rpcUrls[0], 'confirmed');
    this.provider = {};//new anchor.AnchorProvider(this.connection, this.currentProvider, 'processed' as ConfirmOptions);
  }

  public get currentAccountValue(): string {
    return this.currentAccount.value;
  };

  public async sign(message: string): Promise<string> {
    const messageBytes = new TextEncoder().encode(message);
    const signedMessage = await this.currentProvider.signMessage(messageBytes, 'utf8');
    return this.toHexString(signedMessage.signature);
  }

  public disconnect():void{
    this.isConnected = false;
    this.currentAccount.next(null);
  }

  public toHexString(byteArray: any) {
    return Array.from(byteArray, function (byte: any) {
      return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('')
  }

  private setAccount(account: string): void {
    this.ngZone.run(() => {
      this.currentAccount.next(account);
      localStorage.setItem('accountAddress', account);
    })
  }

  private setNetwork(networkId: string): void {
    this.ngZone.run(() => {
      this.currentNetwork.next(networkId);
      localStorage.setItem('networkId', networkId);
    })
  }
}
