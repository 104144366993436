import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment, CanLoad } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { Web3Service } from '../services/web3.service';

@Injectable()
export class Guard implements CanActivate, CanLoad {

    public isLoading: boolean = false;

    constructor(private auth: AuthService, private location: Location, private readonly web3: Web3Service) {
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        console.log("guard", this.auth.isAuthorized, this.auth.isTokenExpired(this.auth.token))
        return this.auth.isAuthorized && !this.auth.isTokenExpired(this.auth.token) && this.web3.isConnected;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('canActivate')
        if (this.auth.isTokenExpired(this.auth.token) || !this.web3.isConnected) {
            console.log('redirect from guard')
            this.auth.logout();
            return false;
        }
        return true;
    }
    // canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    //     return this.auth.isAuthorized() && !this.auth.isTokenExpired();
    // }
}