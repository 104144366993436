<div class="time-left"
     (click)="switchMode($event)"
     [ngStyle]="getStyle()"
     [matTooltip]="ended ? '' : isTimer ? 'Show date' : 'Show time left'"
     matTooltipClass="normal"
     matTooltipPosition="above"
>
  <ng-container *ngIf="ended && textIfEnded; else timer">
    {{textIfEnded}}
  </ng-container>
</div>

<ng-template #timer>
  <img *ngIf="!ended" src="assets/images/clock.png">
  <ng-container *ngIf="isTimer || ended; else dateEnd">
    {{isStarted ? '' : 'Starts in '}}{{ date$ | async | timeLeft }}
  </ng-container>
</ng-template>

<ng-template #dateEnd>
  <ng-container>{{isStarted ? 'Ends at ' : 'Starts at '}}{{ (isStarted ? endDate : startDate) * 1000 | date : 'short' }}</ng-container>
</ng-template>
