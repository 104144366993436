import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {NoopScrollStrategy} from '@angular/cdk/overlay';
import {DlgAlertComponent} from "../components/shared/dlg-alert.component";
import { MatSnackBar } from "@angular/material/snack-bar";


@Injectable()
export class AlertService {
  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
  }

  show(message?: MatDialogConfig<any> | string, icon: string = ''): Promise<any> {
    if (typeof message === 'string') {
      message = {
        data: {
          icon,
          message
        }
      };
    }

    const dialogRef = this._dialog.open(DlgAlertComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
      ...message
    });

    return dialogRef.afterClosed().toPromise();
  }


  showMinedSwap(): Promise<any> {
    var message = {
      data: {
        message: "Transaction was confirmed.",
        innerHTML: "Once there are enough confirmations from oracles,<br/> it can be claimed in the target blockchain "
      }
    };

    const dialogRef = this._dialog.open(DlgAlertComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
      ...message
    });

    return dialogRef.afterClosed().toPromise();
  }


  showMinedTx(): Promise<any> {
    var message = {
      data: {
        message: "Transaction was confirmed.",
        innerHTML: ""
      }
    };

    const dialogRef = this._dialog.open(DlgAlertComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
      ...message
    });

    return dialogRef.afterClosed().toPromise();
  }

  public showAlert(text: string, isError?: boolean, durationInMilliseconds?: number):void {
    this._snackBar.open(text, 'Close', {
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: durationInMilliseconds || 3000,
      panelClass: isError ? 'err-msg' : 'info-msg'
    });
  }
}
