import { Component, DoCheck, Inject } from "@angular/core";
import {Web3Service} from "../../services/web3.service";
import {AuthService} from "../../services/auth.service";
import {ElseverseService} from "../../services/elseverse.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements DoCheck{
  public get isConnected(): boolean {
    return this.web3.isConnected && this.auth.isAuthorized;
  }
  public isElseverse: boolean;

  constructor(
    private readonly web3: Web3Service,
    private readonly auth: AuthService,
    public readonly elseverseService: ElseverseService) {
  }

  ngDoCheck() {
    this.isElseverse = window.location.pathname.includes("elseverse");
  }
}
