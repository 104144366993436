import { DOCUMENT } from '@angular/common';
import { Component, DoCheck, Inject } from "@angular/core";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Web3Service } from 'src/app/services/web3.service';
import { ModalService } from '../modal/modal.service';
import { ElseverseService } from '../../services/elseverse.service';
import { SolanaService } from "../../services/solana.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements DoCheck{
  public get isConnected(): boolean {
    return (this.web3.isConnected || this.solana.isConnected) && this.auth.isAuthorized;
  }

  public get currentAccount(): Observable<string> {
    if (this.web3.isConnected) return this.web3.currentAccount$;
    else if (this.solana.isConnected) return this.solana.currentAccount$;
    else return null;
  }

  public isElseverse: boolean;

  constructor(
    private readonly web3: Web3Service,
    private readonly solana: SolanaService,
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly modalService: ModalService,
    public readonly elseverseService: ElseverseService,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngDoCheck() {
    this.isElseverse = window.location.pathname.includes("elseverse");
  }

  public async connectMetamask(): Promise<void> {
    this.modalService.connectWallet();
  }

  public logout(): void {
    if (this.web3.isConnected) {
      this.web3.disconnect();
    }
    if (this.solana.isConnected) {
      this.solana.disconnect();
    }

    this.auth.logout();
    if (this.router.url == '/user-page') {
      this.router.navigateByUrl('');
    }
  }

  public toggleMenu(): void {
    this.document.body.classList.toggle('menu_openid');
  }
}
