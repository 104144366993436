import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeLeft',
  pure: false
})
export class TimeLeftPipe implements PipeTransform {
  transform(endDate: number): unknown {
    const floor = (num: number) => Math.floor(num);
    const format = (num: number) => num >= 100 ? num : ('0' + num).slice(-2);

    const now = floor(new Date().getTime() / 1000);

    if (now - endDate > 0)
      return 'Ended';

    const diff = endDate - now;
    const days = floor(diff / 3600 / 24);
    let result = '';
    if (days > 0)
      result += `${days}d `;

    const hours = floor((diff % 86400) / 3600);
    if (hours > 0 || result)
      result += `${hours}h `;
    const minutes = floor((diff % 3600) / 60);
    if (minutes > 0 || result)
      result += `${minutes}m `;
    const seconds = format(floor(diff) % 60);
    return `${result}${seconds}s`;
  }
}
