import { Component } from '@angular/core';
import { ModalService } from "../../modal/modal.service";
import { Web3Service } from "../../../services/web3.service";
import { AuthService } from "../../../services/auth.service";
import { ChainType } from "../../../enums/chain-type";
import { SolanaService } from "../../../services/solana.service";

@Component({
  selector: 'connect-wallet',
  templateUrl: './connect-wallet.dialog.component.html',
  styleUrls: ['./connect-wallet.dialog.component.scss']
})
export class ConnectWalletDialog {

  constructor(
    private readonly web3: Web3Service,
    private readonly auth: AuthService,
    public readonly modalService: ModalService,
    private readonly authService: AuthService,
    private readonly solanaService: SolanaService) {
  }

  public async connectWallet(): Promise<void> {
    const chainType = this.authService.getChainType();

    if (chainType == ChainType.Solana) {
      await this.solanaService.connectWallet();
      await this.signSolanaMessageForAuth();
    }
    else if (chainType == ChainType.EVM) {
      try {
        await this.web3.connectWallet();
        await this.signMessageForAuth();
      }
      catch (e) {
        this.web3.disconnect();
      }
    }
  }

  private async signMessageForAuth(): Promise<void> {
    const authData = {
      timeStamp: Math.floor(Date.now() / 1000),
      ethAddress: '',
      signature: '',

    }
    authData.signature = await this.web3.personalSign(authData.timeStamp.toString(), this.web3.currentAccountValue);
    if (!authData.signature) {
      return;
    }
    authData.ethAddress = this.web3.currentAccountValue;
    await this.auth.authenticate(authData);
    this.modalService.close('connect-wallet');
  }

  private async signSolanaMessageForAuth(): Promise<void> {
    const authData = {
      timeStamp: Math.floor(Date.now() / 1000),
      solanaAddress: '',
      signature: ''
    }

    authData.signature = await this.solanaService.sign(authData.timeStamp.toString());
    if (!authData.signature) {
      return;
    }
    authData.solanaAddress = this.solanaService.currentAccountValue;
    await this.auth.authenticate(authData);
    this.modalService.close('connect-wallet');
  }
}
