import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import WertWidget from '@wert-io/widget-initializer';
import { signSmartContractData } from '@wert-io/widget-sc-signer';
import { v4 as uuidv4 } from 'uuid';
import { TokenHistoryDTO } from "src/app/dto/tokenHistory.dto";
import { BuyPositionModel } from "src/app/models/buy-position.model";
import { AuthService } from "src/app/services/auth.service";
import { Web3Service } from "src/app/services/web3.service";
import { TokensApiService } from "../../services/api/tokens-api.service";
import { PositionsApiService } from "../../services/api/positions-api.service";
import { PositionModel } from "../../models/position.model";
import { AlertService } from "../../services/alert.service";
import { ModalService } from "../modal/modal.service";
import { BigNumber } from "bignumber.js";
import { MakeBidPositionModel } from "../../models/make-bid-position.model";
import { environment } from "../../../environments/environment";
import { INftInfo } from "../../models/nft-info.interface";
import { LotteryModel } from "../../models/lottery.model";
import { LotteryApiService } from "../../services/api/lottery-api.service";
import { BetModel } from "../../dto/lottery.dto";
import { UserBetModel } from "../../models/user-bet.model";
import networksData from "../shared/networks.data";
import { RealtimeService } from "../../services/realtime.service";
import { LotteryWinnerEvent } from "../../dto/events/lottery-winner.event";
import { AuctionNewBidEvent } from "../../dto/events/auction-new-bid.event";
import { AuctionExtendedEvent } from "../../dto/events/auction-extended.event";
import { PositionSoldEvent } from "../../dto/events/position-sold.event";
import { CancelPositionModel } from "../../models/cancel-position.model";
import { SpecialEventModel } from "../../models/special-event.model";
import INetworkData from "../../models/networks.data";
import { CloseAuctionModel } from "src/app/models/close-auction.model";
import { ITokenTransfer } from "../../models/token-transfer.interface";
import { ElseverseApiService } from "../../services/api/elseverse-api.service";

declare let window: any;
declare let ga: any;

@Component({
  selector: 'app-nft-item',
  templateUrl: './nft-item.component.html',
  styleUrls: ['./nft-item.component.scss'],
})
export class NftItemComponent implements OnInit, OnDestroy {

  public bidAmount: number;
  public nextBid: number;
  public showAllBids: boolean = true;

  public get specialEvent(): SpecialEventModel {
    const res = <SpecialEventModel>this.positionInfo;
    return res?.eventIsFinished === undefined ? null : res;
  }

  public landId: number | null = null;
  public lotteryId: number;
  public isLoading: boolean;
  public isLottery: boolean;
  public isSold: boolean;

  public positionInfo: PositionModel | SpecialEventModel;
  public landInfo: any | SpecialEventModel;
  public lottery: LotteryModel;
  public suggestions: PositionModel[];
  public relatedLands: any;

  public prolongTime = environment.auction.extensionSpanMinutes;

  public dealApproved: boolean;
  public isBought: boolean;
  public currentTokenAmount: number;
  public currentTokenType: string;
  public iframe_url: SafeResourceUrl;
  private subscriptions: Subscription[] = [];
  public isEnded: boolean;
  public userWallet: string = '';
  public wertWidget: any;

  public isAndroid: boolean = /android/i.test(navigator.userAgent.toLowerCase());
  public isMetamaskOrTrust = (typeof window.ethereum !== 'undefined' && window.ethereum.isTrust) || (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask);

  public get currencyDecimals(): number {
    return environment.chains.filter((c) => c.id == this.nftInfo?.chainId || c.id == this.landInfo?.chainId)[0]
      ?.currencyDecimals;
  }

  public get isFiatAvailable(): boolean {
    let allowedCollection = this.web3.chain.botsAddress;
    let owner = this.web3.chain.gamespadOwner;
    let price = (new BigNumber(this.positionInfo.price).shiftedBy(-this.currencyDecimals)).toNumber();
    if (price > 20000) return false;
    return !this.nftInfo.isAuction && this.nftInfo.collectionAddress.toLocaleLowerCase() == allowedCollection.toLocaleLowerCase() && this.nftInfo.ownerAddress.toLocaleLowerCase() == owner.toLocaleLowerCase();
  }

  tokens: any[] = [
    {
      value: 'blp',
      viewValue: 'BLP',
      img: 'assets/images/icon_blp.png',
      min: 0,
      max: 0,
    },
    {
      value: 'gmpd',
      viewValue: 'GMPD',
      img: 'assets/images/icon_t.png',
      min: 0,
      max: 0,
    },
  ];
  selectedCurrency = 'blp';
  tokenList: any = {
    blp: 0,
    gmpd: 1,
  };

  public get isMine(): boolean {
    return (
      this.nftInfo?.ownerAddress?.toLowerCase() ==
      this.web3.currentAccountValue?.toLowerCase()
    );
  }

  public get nftChain(): INetworkData {
    return networksData.filter(
      (n) => n.chainId == (this.lottery?.nftNetworkId || this.nftInfo?.chainId || this.landInfo?.chainId)
    )[0];
  }

  public get nftInfo(): INftInfo {
    return this.positionInfo || this.lottery;
  }

  public get isGmpd() {
    return (
      this.nftInfo?.collectionAddress.toLowerCase() ==
      this.web3.chain.botsAddress.toLowerCase()
    );
  }

  public get isGamespadOwner(): boolean {
    return (
      this.isLottery ||
      this.nftInfo?.ownerAddress.toLowerCase() == this.web3.chain.gamespadOwner
    );
  }

  public get isAuction() : boolean {
    return this.nftInfo?.isAuction;
  }

  public nftTypeById(): any {
    return this.nftInfo.tokenName?.replace(' ', '_').toLowerCase();
  }

  public tokenTransfers: ITokenTransfer[];
  public tokenHistory: TokenHistoryDTO[];
  public get tokenHistory5items(): TokenHistoryDTO[] {
    return this.tokenHistory.filter((t, i) => i < 5);
  }

  constructor(public readonly web3: Web3Service,
              private readonly tokensApi: TokensApiService,
              private readonly positionsApi: PositionsApiService,
              private readonly landsApi: ElseverseApiService,
              private readonly lotteryApiService: LotteryApiService,
              private readonly route: ActivatedRoute,
              private readonly auth: AuthService,
              private readonly router: Router,
              private readonly alertService: AlertService,
              private readonly realtimeService: RealtimeService,
              public readonly modalService: ModalService,
              private readonly sanitizer: DomSanitizer,
              @Inject(DOCUMENT) private document: any) {
  }

  public async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.lotteryId = +params['lotteryId'];
      this.isLottery = this.lotteryId === 0 || !!this.lotteryId;
      this.isBought = this.isSold = false;
      this.landId = parseInt(this.route.snapshot.queryParamMap.get('landId')) || null;
      this.startLoading();
      this.landId && document.body.classList.add('land');
      if (this.landId) {
        await this.getLandInfo(this.landId)
        await this.getRelatedLands(this.landId);
      }
      try {
        const chainId = params['chainId'];
        if (this.isLottery) {
          await this.getLottery(this.lotteryId);
        } else {
          const positionId = params['positionId'];
          const auctionId = +params['auctionId'];
          const isAuction = auctionId === 0 || !!auctionId;

          if (isAuction && auctionId === 0 && +chainId === 1) {
            this.router.navigate(['auction', 1, 4]);
            return;
          }

          await this.getPositionInfo(
            positionId || auctionId,
            isAuction,
            chainId
          );
        }

        await this.loadAdditionalInfo(chainId);
      } catch (e: any) {
        console.warn('Unexpected error happened');
      }
      this.setMidValue();
      this.stopLoading();
      if (this.lottery?.currency?.value) {
        this.currentTokenType = this.lottery.currency.value;
        this.selectedCurrency = this.lottery.currency.value;
      }
    });

    this.subscriptions.push(
      this.realtimeService.lotteryWinnerEvent$.subscribe(
        (winner: LotteryWinnerEvent) => {
          if (this.isLottery && this.lottery?.id == winner.lotteryId) {
            this.lottery.winnerAddress = winner.winner;
          }
        }
      )
    );

    this.subscriptions.push(
      this.realtimeService.lotteryBidEvent$.subscribe(
        (newBid: UserBetModel) => {
          if (this.isLottery && this.lottery?.id == newBid.lotteryId) {
            this.lottery.userBets.unshift(newBid);
          }
        }
      )
    );

    this.subscriptions.push(
      this.realtimeService.auctionBidEvent$.subscribe(
        (newBid: AuctionNewBidEvent) => {
          if (
            this.positionInfo?.isAuction &&
            this.positionInfo?.id == newBid.auctionId &&
            this.positionInfo.chainId == newBid.chainId
          ) {
            this.positionInfo.currentBid = newBid.amount.toString();
            this.positionInfo.price = newBid.amount.toString();
            this.bidAmount = this.nextBid = this.getNextBid(
              this.positionInfo.price
            );
          }
        }
      )
    );

    this.subscriptions.push(
      this.realtimeService.auctionExtendedEvent$.subscribe(
        (exntendEvent: AuctionExtendedEvent) => {
          if (
            this.positionInfo?.isAuction &&
            this.positionInfo?.id == exntendEvent.auctionId &&
            this.positionInfo.chainId == exntendEvent.chainId
          ) {
            this.positionInfo.auctionEndDate =
              new Date(exntendEvent.endDate).getTime() / 1000;
          }
        }
      )
    );

    this.subscriptions.push(
      this.realtimeService.positionSoldEvent$.subscribe(
        (soldEvent: PositionSoldEvent) => {
          if (
            this.positionInfo &&
            !this.positionInfo?.isAuction &&
            this.positionInfo?.id == soldEvent.positionId &&
            this.positionInfo.chainId == soldEvent.chainId
          ) {
            this.isSold = true;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub?.unsubscribe();
    }
    this.landId = null;
    document.body.classList.remove('land');
  }

  public async getPositionInfo(
    positionId: number,
    isAuction: boolean,
    chainId: number
  ): Promise<void> {
    //todo replace by api request call

    //this.positionInfo = await this.web3.getPosition(positionId);

    this.positionInfo = await this.positionsApi.getPositionInfo(
      positionId,
      isAuction,
      chainId
    );
    if (this.positionInfo.isAuction) {
      this.bidAmount = this.nextBid = this.getNextBid(this.positionInfo.price);
      const current = new Date().getTime();
      const auctionEndDate = new Date(this.positionInfo.auctionEndDate).getTime()*1000;
      this.isEnded = current > auctionEndDate;
      this.isBought = this.isEnded;
    } else {
      this.isBought = this.positionInfo.status != 'open';
    }
  }

  public async getLandInfo(
    landId: number
  ): Promise<void> {

    const [landInfo, positions] = await Promise.all([
      this.landsApi.getLand(landId).toPromise(),
      this.positionsApi.getOwnPositions(this.web3.currentAccountValue).toPromise()
    ]);

    const position = positions.items.find(x =>
      x.tokenId == landInfo.tokenId &&
      x.tokenAddress.toLowerCase() == this.web3.chain.landsAddress.toLowerCase());

    if (position) {
      landInfo.positionId = position.id;
      landInfo.isAuction = position.isAuction;
    }

    this.landInfo = landInfo;
    this.relatedLands = positions.items.filter(x => x.tokenId != landInfo.tokenId);
  }

  public async getRelatedLands(
    landId: number
  ): Promise<void> {
    //this.relatedLands = await this.landsApi.getRelated(landId).toPromise();
  }

  public async isValid(): Promise<boolean> {
    if (!(await this.checkAuth())) {
      return false;
    }

    if (
      this.lottery &&
      (this.lottery?.isFinished || !this.lottery?.isStarted)
    ) {
      return false;
    }

    if (this.positionInfo && this.positionInfo.auctionEnded) {
      this.alertService.show('Sorry, auction has finished');
      return false;
    }

    const balance = await this.web3.GetTokenBalance(
      this.web3.currentAccountValue,
      this.lottery
        ? this.web3.tokenInfo[this.selectedCurrency].tokenAddress
        : this.positionInfo.currencyAddress
    );
    const requiredValue = this.lottery
      ? this.currentTokenAmount
      : this.nftInfo.isAuction
        ? this.bidAmount
        : this.positionInfo.price;
    console.log('balance', balance, requiredValue);
    // if (balance < requiredValue) {
    //   this.alertService.show('Not enough funds');
    //   return false;
    // }

    return true;
  }

  public async makeBid(): Promise<void> {
    if (!(await this.isValid())) return;

    const positionId = this.positionInfo.id;
    try {
      this.startLoading();
      const bidPosition = await this.web3.makeBid(
        positionId,
        this.positionInfo.currencyAddress,
        this.bidAmount
      );
      const bid = new BigNumber(this.bidAmount)
        .shiftedBy(this.web3.chain.currencyDecimals)
        .toString(10);
      const model: MakeBidPositionModel = {
        id: positionId,
        transactionHash: bidPosition.transactionHash,
        amount: 1,
        buyerAddress: this.web3.currentAccountValue,
        bid: bid,
      };
      await this.positionsApi.makeBidPosition(model).toPromise();
      this.positionInfo.price = bid;
      this.positionInfo.currentBid = bid;
      if (this.positionInfo.auctionFinishSoon) {
        this.positionInfo.auctionEndDate += this.prolongTime * 60;
      }

      this.modalService.close('confirm-bet-modal');
      this.alertService.show('Transaction is confirmed');
    } catch (e) {
      console.log('buyToken', e);
      if (!e?.message?.includes('User denied transaction signature')) {
        this.alertService.show('Unexpected error happened: ' + e?.message);
      }
    } finally {
      this.stopLoading();
    }
  }

  public async openModal(): Promise<void> {
    this.dealApproved = false;
    if (this.isFiatAvailable)
      this.modalService.open('choose-method-modal');
    else {
      if (!(await this.isValid())) return;
      this.modalService.open('confirm-bet-modal');
    }
  }

  public async openConfirmBetModal(): Promise<void> {
    this.modalService.close('choose-method-modal');
    if (!(await this.isValid())) return;
    this.modalService.open('confirm-bet-modal');
  }

  public openEnterWallet(): void {
    if (this.web3.isConnected) this.userWallet = this.web3.currentAccountValue;
    this.modalService.close('choose-method-modal');
    this.modalService.open('enter-wallet-modal');
  }

  public async openWertWidget(): Promise<void> {

    if (!this.web3.isAddress(this.userWallet)) {
      this.alertService.show('Your address is not valid');
      return;
    }
    localStorage.setItem("walletForFiat", this.userWallet);
    this.modalService.close('enter-wallet-modal');
    this.modalService.open('wert-modal');
    let price = (new BigNumber(this.positionInfo.price).shiftedBy(-this.currencyDecimals)).toNumber();
    let now = new Date().getTime().toString();

    const sc_input_data = this.web3.encodeReceiverPositionInput([this.positionInfo.id.toString(10), environment.wert.USDCPolygon, price.toString(10)]);
    const privateKey = environment.wert.signer;

    const signedData = signSmartContractData({
      address: this.userWallet,
      commodity: environment.wert.commoditySign,
      commodity_amount: price,
      pk_id: 'key1', // always 'key1'
      sc_address: environment.wert.receiver,
      sc_id: uuidv4(),
      sc_input_data,
    }, privateKey);
    let otherWidgetOptions: any = {
      partner_id:  environment.wert.partnerId, // your partner id
      commodity: environment.wert.commodity,
      container_id: 'widget-wert',
      click_id: `0-${this.positionInfo.id}-${this.positionInfo.chainId}-${this.userWallet.substring(2, this.userWallet.length)}-${now.substring(now.length - 8, now.length)}`, // unique id of purhase in your system
      width: 400,
      height: 600,
    };
    if (!environment.production) otherWidgetOptions.origin = 'https://sandbox.wert.io'; // this option needed only in sandbox
    const nftOptions = {
      extra: {
        item_info: {
          author: "Gamespad",
          image_url: this.nftInfo.imageUrl,
          name: this.nftInfo.tokenName,
          seller: this.nftInfo.owner,
        }
      },
    };

    this.wertWidget = new WertWidget({
      ...signedData,
      ...otherWidgetOptions,
      ...nftOptions,
    });

    this.wertWidget.mount();

  };

  public async openCancelModal(): Promise<void> {
    if (!(await this.checkAuth())) {
      return;
    }

    if (this.positionInfo.isAuction) {
      this.modalService.open('cancel-auction');
    } else this.modalService.open('cancel-nft');
  }

  public async cancelNFT(): Promise<void> {
    try {
      const itemId = this.positionInfo?.id;
      this.startLoading();
      const cancelPosition = await this.web3.cancelSale(itemId);
      const model: CancelPositionModel = {
        id: itemId,
        transactionHash: cancelPosition.transactionHash,
      };
      await this.positionsApi.cancelPosition(model).toPromise();
      this.modalService.close('cancel-nft');
      this.router.navigate(['user-page']);
    } catch (e) {
      console.log('cancel error', e);
    } finally {
      this.stopLoading();
    }
  }

  public async stopAuction(): Promise<void> {
    try {
      const itemId = this.positionInfo?.id;
      this.modalService.close('cancel-auction');
      this.startLoading();
      await this.web3.stopAuction(itemId);
      const model: CloseAuctionModel = {
        auctionId: itemId,
        chainId: this.positionInfo.chainId,
        winnerAddress: '--',
        winnerBid: '0',
        endDate: new Date(),
      };
      await this.positionsApi.closeAuction(model).toPromise();
      this.isEnded = true;
      this.positionInfo.auctionEndDate = new Date().getTime()/1000;
      this.modalService.close('cancel-auction');
    } catch (e) {
      console.log('stop auction error', e);
    } finally {
      this.stopLoading();
    }
  }

  public async buyToken(): Promise<void> {
    if (this.isSold || this.isBought) {
      this.alertService.show('Item has been sold already.');
      this.modalService.close('confirm-bet-modal');
      return;
    }

    const positionId = this.positionInfo.id;
    try {
      this.startLoading();
      const buyPosition = await this.web3.buyToken(
        positionId,
        this.positionInfo.currencyAddress
      );
      const model: BuyPositionModel = {
        id: positionId,
        transactionHash: buyPosition.transactionHash,
        amount: 1,
        buyerAddress: this.web3.currentAccountValue,
      };
      await this.positionsApi.buyPosition(model).toPromise();

      this.modalService.close('confirm-bet-modal');
      this.alertService.show('Transaction is confirmed');
      this.isBought = true;
    } catch (e) {
      console.log('buyToken', e);
      if (!e?.message?.includes('User denied transaction signature')) {
        this.alertService.show('Unexpected error happened: ' + e?.message);
      }
    } finally {
      this.stopLoading();
    }
  }

  private startLoading() {
    this.isLoading = true;
    this.document.body.classList.add('wait_openid');
  }

  private stopLoading() {
    this.isLoading = false;
    setTimeout(() => {
      this.document.body.scrollTop = 0;
      this.document.documentElement.scrollTop = 0;
    }, 1);
    this.document.body.classList.remove('wait_openid');
  }

  private getNextBid(price: string) {
    if (!price) {
      return 0;
    }
    const priceNum = new BigNumber(price)
      .shiftedBy(-this.web3.chain.currencyDecimals)
      .toNumber();
    if (price == this.positionInfo.minBid && !this.positionInfo.currentBid) {
      return priceNum;
    }
    if (priceNum < 100) {
      return priceNum + 1;
    }
    if (priceNum < 1000) {
      return priceNum + 10;
    }
    return priceNum + 100;
  }

  approveDeal() {
    if (this.bidAmount < this.nextBid) {
      this.alertService.show(
        `Can't make a bid lower than ${this.nextBid} ${this.nftChain?.networkParams.currencyName}`
      );
      return;
    }
    this.dealApproved = true;
  }

  openNftItem(suggestion: PositionModel) {
    this.router.navigate([
      suggestion.isAuction ? 'auction' : 'nft',
      suggestion.chainId,
      suggestion.id,
    ]);
  }

  openLandCard(relatedLand: any) {
    this.router.navigate([
      "elseverse",
      relatedLand.id
    ]);
  }

  private async getLottery(lotteryId: number): Promise<void> {
    this.lottery = await this.lotteryApiService.getLottery(lotteryId).toPromise();

    if (this.lottery.metadata?.tokenUri) {
      let tokenUri = await this.tokensApi.getInfo(this.lottery.metadata.tokenUri).toPromise();
      if (tokenUri?.iframe_url)
        this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(tokenUri.iframe_url);
    }
    this.lottery.userBets.map(item => {
      if (item.currency.toLocaleLowerCase() == environment.gmpd.toLocaleLowerCase()) item.tokenId = 'gmpd';
      if (item.currency.toLocaleLowerCase() == environment.blp.toLocaleLowerCase()) item.tokenId = 'blp';
      return item;
    });
  }

  public setMidValue(): void {
    if (!this.isLottery) {
      return;
    }
    this.currentTokenAmount = Math.round(
      (this.lottery.tokens[this.tokenList[this.selectedCurrency]].min +
        this.lottery.tokens[this.tokenList[this.selectedCurrency]].max) /
      2
    );
  }

  private async loadAdditionalInfo(chainId: number): Promise<void> {
    if (!this.nftInfo.videoUrl && !this.nftInfo.imageUrl) {
      const tokenMetaData = await this.tokensApi
        .getNftInfo(
          this.nftInfo.collectionAddress,
          this.nftInfo.tokenId,
          this.isLottery ? this.lottery.nftNetworkId : chainId
        )
        .toPromise();
      this.nftInfo.setMetaData(tokenMetaData);
    }

    this.positionsApi
      .getRelatedNft(
        this.nftInfo.collectionAddress,
        this.nftInfo.tokenId,
        this.nftInfo.chainId
      )
      .subscribe((res) => {
        this.suggestions = res;
      });

    this.tokenHistory = await this.tokensApi
      .getTokenSellHistory({
        tokenAddress: this.nftInfo.collectionAddress,
        tokenId: this.nftInfo.tokenId
      })
      .pipe(map((i) => i.filter((k) => k.action == 'buy' || i.length == 1)))
      .toPromise();
  }

  public async openModalLottery(amount: number): Promise<void> {
    if (!(await this.isValid())) return;

    if (this.web3.chainIdNumber != 56 && this.web3.chainIdNumber != 97) {
      this.alertService.show('Please switch to BNB chain to participate');
      this.web3.switchChain(environment.production ? 56 : 97);
      return;
    }
    this.currentTokenAmount = amount;
    this.modalService.open('bet-modal');
  }

  bidExists(): boolean {
    return this.lottery?.userBets?.some(
      (b) =>
        b.betAmount == this.currentTokenAmount &&
        b.userAddress?.toLowerCase() ==
        this.web3.currentAccountValue?.toLowerCase()
    );
  }

  public async bet(): Promise<void> {
    this.modalService.close('bet-modal');
    if (!(await this.checkAuth())) {
      return;
    }
    this.startLoading();
    let succeed = true;
    try {
      await this.web3.approveToken(
        this.selectedCurrency,
        this.currentTokenAmount
      );
      await this.web3.bet(
        this.lottery.id,
        this.currentTokenAmount,
        this.tokenList[this.selectedCurrency]
      );
    } catch (e: any) {
      succeed = false;
      this.alertService.show(`Unexpected error happened: ${e.message}`);
    }
    if (succeed) {
      const betData: BetModel = {
        userAddressId: this.web3.currentAccountValue,
        betAmount: this.currentTokenAmount,
        lotteryId: this.lottery.id,
        currency: this.web3.tokenInfo[this.selectedCurrency].tokenAddress,
      };
      try {
        let result = await this.lotteryApiService.bet(betData);
      } catch (e) {}

      this.alertService.show(`The bet is accepted, thank you.`);
    }
    this.stopLoading();

    this.sendGtmEvent('event', 'lottery_participation');
  }

  private sendGtmEvent(eventType: string, eventCategory: string){
    ga.getAll().forEach((tracker: any)=>{
      tracker.send(/*t param*/ eventType, /* ec param */ eventCategory);
    });
  }

  changeShowBids(event: any) {
    this.showAllBids = event.value == 'allBids';
  }

  canShowBid(bid: UserBetModel) {
    return (
      this.showAllBids ||
      bid.userAddress?.toLowerCase() ==
      this.web3.currentAccountValue.toLowerCase()
    );
  }

  isAddressCurrent(address?: string) {
    return address?.toLowerCase() == this.web3.currentAccountValue.toLowerCase();
  }

  private async checkAuth(): Promise<boolean> {
    if (!window.ethereum) {
      this.alertService.show('Please install Metamask to participate');
      return false;
    }
    if (!this.web3.isConnected || !this.auth.isAuthorized) {
      this.modalService.connectWallet();
      return false;
    }
    if (
      this.positionInfo &&
      this.web3.chainIdNumber != this.positionInfo.chainId
    ) {
      if (this.web3.isConnected) {
        this.alertService.show('Please change chain in Metamask');
        if (!(await this.web3.switchChain(this.positionInfo.chainId))) {
          return false;
        }
      }
      this.auth.setChainId(this.positionInfo.chainId);
    }
    return true;
  }
}
