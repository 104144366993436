<section class="nft-home">
    <div class="container" *ngIf="selectedProject; else noCollections">
        <div *ngIf="!isBatchListVisible">
          <div class="nft-home-top">
              <h2>my nft</h2>
          </div>

          <div class="nft-home-selector" *ngIf="!showOnSellItems$.value">
              <div class="select-collection">
                <span class="nft-home-selector-label">Select collection:</span>
                <div class="nft-home-selector-dropdown">
                    <button class="btn-h dropdown-button" [matMenuTriggerFor]="availableCollection">
                        <img src="{{selectedProject.icon}}" alt=""/>
                        <span>{{selectedProject.name}}</span>
                    </button>
                    <mat-menu #availableCollection="matMenu" class="select-network-menu">
                        <button mat-menu-item *ngFor="let project of projects" (click)="changeAddress(project.address)">
                            <img *ngIf="project.name !== 'All'" src="{{project.icon}}" alt=""/>
                            {{project.name}}
                        </button>
                    </mat-menu>
                </div>
              </div>
              <div class="bunch-list-setting">
                <div class="active-btn" *ngIf="!isSetBatchList" (click)="setBatchList()">Select For Listing</div>
                <div class="bunch-list-setting-group" *ngIf="isSetBatchList">
                  <div class="info">Selected: {{checkedBulkListCount}} / {{bulkListAvailAmount}}</div>
                  <div class="primary-btn" (click)="isSetBatchList = false;">Cancel</div>
                  <div class="primary-btn" (click)="selectAllForBulkList(false)">Uncheck All</div>
                  <div class="primary-btn" (click)="selectAllForBulkList(true)">All</div>
                  <div class="active-btn" (click)="bulkList()">List Now</div>
                </div>
              </div>
          </div>

          <div class="filterWalletNft">
            <button [class.active]="!showOnSellItems$.value" (click)="showOnSale(false)">In wallet</button>
            <button [class.active]="showOnSellItems$.value" (click)="showOnSale(true)">On Sale</button>
          </div>

          <div [ngStyle]="{'display': isLoading && itemsCount == 0 ? 'none' : ''}" class="nft-home-card"
            *ngIf="myPositions">
              <div class="item_nft" *ngFor="let item of items">
                <nft-card [nftInfo]="myPositions[item.tokenId] || item"
                          [showLotTypeLabel]="isOnSale(item)"
                          [matTooltip]="isOnSale(item) ? 'Open NFT' : ''" [matTooltipPosition]="'above'"
                          [showActionButtons]="isOnSale(item)"
                          [showSellButtons]="!isOnSale(item)"
                          [showCheckBulkList]="isSetBatchList"
                          [forceSetCheckedForBunchList]="item.forceCheckedForBunchList"
                          (checkedForBulkList)="setBulkListItem($event)"
                          (clickOnCard)="openNft(item)"
                          (cancelSellEvent)="openConfirmationModal(item)"
                          (stopAuctionEvent)="stopAuctionHandler(item)"
                          (finishAuctionEvent)="finishAuctionHandler(item)"
                          (putOnSell)="openModal(item, $event)"
                ></nft-card>
              </div>
          </div>

          <div *ngIf="!isLoading && itemsTotal > 0" class="nft-home-load-more">
              <span class="nft-home-loaded">Loaded {{itemsCount}} of {{itemsTotal}} items</span>
              <div *ngIf="itemsCount < itemsTotal" class="item_nft_right">
                  <a class="btn" (click)="loadMore()">load more</a>
              </div>
          </div>

          <div *ngIf="!isLoading && !itemsCount" class="nft-home-no-items">You have no items of this collection</div>
          <div *ngIf="isLoading" class="nft-home-loading">Loading, please wait...</div>
        </div>
        <div class="batch-list-container" *ngIf="isBatchListVisible">
          <div class="settings">
            <div class="title">
              <div class="route">My NFTs > Batch List</div>
              <h2>BATCH LIST</h2>
            </div>
            <div class="select-one-price-container">
              <div class="toggle-component">
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="isActiveOnePriceForAll">
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="enable-set-for-all">
                Select one price for all
              </div>
            </div>
            <div>
              <div class="price-input-component {{ isActiveOnePriceForAll ? '' : 'disabled'}}">
                <label>Price - USDT</label>
                <input matInput id="all-price" type="number"
                       [(ngModel)]="priceForAll"
                       [min]="1"
                       (ngModelChange)="setAllPriceSame($event)"
                       [disabled]="!isActiveOnePriceForAll"
                />
              </div>
            </div>
            <div class="btn-group">
              <div class="primary-btn" (click)="isBatchListVisible = false;">Back</div>
              <div class="active-btn" (click)="listBunchNft()">Next</div>
            </div>
          </div>
          <div class="content">
            <div class="item" *ngFor="let item of itemsForBatchList">
              <video *ngIf="item?.hasVideo" src="{{ isGmpd ? 'assets/video/' + nftTypeById(item) + '.mp4' : item.videoUrl | ipfsLink }}" playsinline="" autoplay="autoplay" loop="loop" muted="muted" class="nft-card__image"></video>
              <img *ngIf="!item?.hasVideo" alt="" src="{{(item?.imageUrl || 'assets/images/no-image.png') | ipfsLink }}">
              <div class="title">
                {{ item?.tokenName }}
                <span>{{ (item?.collectionSymbol || '') + ' #' + item?.tokenId }}</span>
              </div>
              <div class="price-input-component">
                <label>Price - USDT</label>
                <input matInput type="number" [min]="1" [(ngModel)]="item.price"/>
              </div>
            </div>
          </div>
        </div>

    </div>

  <ng-template #noCollections>
    <div class="no-collections">There are no collections available on selected network</div>
  </ng-template>
</section>


<jw-modal class="new-design-modal" id="sell-nft">
  <div>
    <h3>Sell NFT - {{ isAuction ? 'Auction' : 'Fixed price' }}</h3>
    <button class="btn-close" (click)="modalService.close('sell-nft')"></button>
  </div>
  <div class="confirm-label">
    {{isAuction ? 'Please, enter the minimal price' : 'Please, enter the price' }}
  </div>
  <div class="bid-input">
    <div class="bid-wrapper">
      <img src="assets/images/currency/{{nftChain?.networkParams.currencyName.toLowerCase()}}.png" />
      <div>
        <label for="bid-value">{{ (isAuction ? 'Min. price - ' : 'Price - ') + nftChain?.networkParams.currencyName}}</label>
        <input id="bid-value" type="number"
               [min]="1"
               [placeholder]="isAuction ? 'Min price' : 'Sell price'"
               [(ngModel)]="bidAmount"
               [disabled]="dealApproved"
        />
      </div>
    </div>
    <div class="bid-wrapper" *ngIf="canChangeDescription(currentItem)">
      <div>
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>NFT Description</mat-label>
          <input matInput type="text"
                 placeholder="Description"
                 autocomplete="off"
                 [(ngModel)]="nftDescription"
                 [disabled]="dealApproved"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="bid-wrapper date-wrapper" *ngIf="isAuction">
      <div>
        <input [min]="minAuctionLength" type="number"
               [max]="maxAuctionLength"
               placeholder="Amount of days (between {{minAuctionLength}} and {{maxAuctionLength}})"
               [(ngModel)]="auctionDaysAmount"
               [disabled]="dealApproved"
        />
      </div>
    </div>
    <div class="auction-end-date" *ngIf="auctionDaysAmount">Auction will end at {{getEndDate(auctionDaysAmount)}}</div>

    <div *ngIf="isAdmin" class="special-event">
      <div class="checkbx">
        <input id="is-special-event" type="checkbox" [(ngModel)]="isSpecialEvent"/>
        <label for="is-special-event">Is special event? (admin only)</label>
      </div>
      <ng-container *ngIf="isSpecialEvent">
        <div class="bid-wrapper">
          <mat-form-field class="example-form-field" appearance="fill">
            <mat-label>Event title</mat-label>
            <input matInput type="text"
                   placeholder="Title"
                   autocomplete="off"
                   [(ngModel)]="eventTitle"
                   [disabled]="dealApproved"
            />
          </mat-form-field>
        </div>
        <div class="bid-wrapper">
          <mat-form-field class="example-form-field" appearance="fill">
            <mat-label>Event Description</mat-label>
            <input matInput type="text"
                   placeholder="Description"
                   autocomplete="off"
                   [(ngModel)]="eventDescription"
                   [disabled]="dealApproved"
            />
          </mat-form-field>
        </div>
        <br/>
        <div>Be carefull, you are going to place new position which will be highlited at the main page: </div>
        <div>
          <img src="assets/images/my-nfts/admin-instruction.png"/>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="buttons">
    <button (click)="modalService.close('sell-nft')" class="cancel">Cancel</button>
    <button (click)="approveDeal()" [disabled]="dealApproved">Approve</button>
  </div>
  <div>
    <button class="buy" [disabled]="!dealApproved || isLoading" (click)="isAuction ? createAuction(currentItem) : sellNFT(currentItem)">{{ isAuction ? 'Create Auction' : 'Sell NFT' }}</button>
  </div>
</jw-modal>

<jw-modal class="new-design-modal" id="cancel-nft">
  <div>
    <h3>Are you sure you want stop selling this nft?</h3>
    <button class="btn-close" (click)="modalService.close('cancel-nft')"></button>
  </div>
  <div class="buttons">
    <button (click)="modalService.close('cancel-nft')" class="cancel">Cancel</button>
    <button (click)="cancelNFT(myPositions[currentItem.tokenId])" [disabled]="dealApproved">Stop selling</button>
  </div>
</jw-modal>

<jw-modal class="new-design-modal" id="cancel-auction">
  <div>
      <h3>Are you sure you want stop this auction?</h3>
      <button class="btn-close" (click)="modalService.close('cancel-auction')"></button>
  </div>
  <div class="buttons">
      <button (click)="modalService.close('cancel-auction')" class="cancel">Cancel</button>
      <button (click)="stopAuctionCall()" >Stop auction</button>
  </div>
</jw-modal>


<jw-modal class="new-design-modal" id="finish-auction">
  <div>
      <h3>Are you sure you want to finish this this auction with current bid?</h3>
      <button class="btn-close" (click)="modalService.close('finish-auction')"></button>
  </div>
  <div class="buttons">
      <button (click)="modalService.close('finish-auction')" class="cancel">Cancel</button>
      <button (click)="finishAuctionCall()">Finish auction</button>
  </div>
</jw-modal>
