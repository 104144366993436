// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://market-clientapi-dev.gpkit.link",
  elseVerseApiUrl: "https://api-elseverse.gpkit.link",
  marketplaceContract: "0x44fa2c2A0932FD1B968300Cb1ef25B22A56d7CcF",
  lotteryContract: "0xc76eeE91B990A54e3F26Fe8642Aade00dD774ca3",
  lotteryEthereumContract: "0xf15F335df1a21d530872b435baA8bc22665da1A5",
  lotteryPolygonContract: "0xF7d430B4E676269739D204Db0407Ab75c4184D7e",
  rpcUrlEthereum: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  rpcUrlPolygon: "https://rpc-mumbai.maticvigil.com",
  moralisApiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImVkNjZjNjUzLTdjYWItNDE0Mi1hMGQyLWY5N2VjMjVlOWFkZiIsIm9yZ0lkIjoiMTY4MzUzIiwidXNlcklkIjoiMTY4MDI0IiwidHlwZUlkIjoiN2M2NjkxNjEtNTUxNy00MTU2LTg4NDMtNjZkMWE0YzZjY2ViIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODI5NDE0OTUsImV4cCI6NDgzODcwMTQ5NX0.h7OeT6dSmOkdL1cKthjVnl47dkV8ywq-un3GatDrTH4",
  erc20Contract: "0x6FfEeCd4c38e593437056cbe1E63c33e6A74459f",
  gmpd: "0xf4949d4f888641399adb83b2b25f44b276c8e94b",
  blp: "0xE652AF898ECB3a4666EedBd2415B2c2De300BF52",
  erc721Contract: "0x16e56434508fE539512a612a0eB1531018F36800",
  lottery_owner: "0xfC952795D902d750B10Ec417E5d363285d709E5e",
  wert: {
    origin: "https://sandbox.wert.io",
    partnerId: "01GQFJQYPA6PM8VWK7TC6RYPEY",
    USDCPolygon: "0x879bad9DcD7e7f79B598a632103984FC090DA00D",
    signer: "0x57466afb5491ee372b3b30d82ef7e7a0583c9e36aef0f02435bd164fe172b1d3",
    commoditySign: "TT",
    commodity: "TT:PolygonMumbai",
    receiver: "0x358A43158429688D3953d515C7046cb68A99184d",
    landsCommoditySign: "MATIC",
    landsCommodity: "MATIC:polygon",
    landsReceiver: "0x13cAc0d90958C945dd58aac18ADbb1c7170e1471"
  },
  defaultChainId: 97,
  signalRConfig: {
    hubUrl: '/hub',
    frequentReconnectTimeout: 10 * 1000, // seconds
    lazyReconnectTimeout: 10 * 60 * 1000 // seconds
  },
  auction:{
    minLengthInDays: 0.01,
    extensionSpanMinutes: 5,
    maxLengthInDays: 30
  },
  chainInfo: [97/*, 5, 80001*/],
  chains: [
    /*{
      id: 4,
      enabled: false,
      marketplaceAddress: "0x519d15d1867cc6033aaaCae8fc7bD05884CA63b9",
      currencyAddress: "0xB0Dfaaa92e4F3667758F2A864D50F94E8aC7a56B",
      auctionAddress: "0x4A884CD814F50016FDB7847aD575e7F27153F7c9",
      currencyDecimals: 18,
      gamespadOwner: "0xaceaecb820be3f147ff40d1c221b25f10ee8dd92",
      botsAddress: ""
    },
    {
      id: 5,
      enabled: true,
      marketplaceAddress: "0xf2f9dDa12283B0D91F0B7438b8cFEFc7C9cc3995",
      currencyAddress: "0xC29Ade861c5c66B66EA579E8716F43F24d1A882F",
      auctionAddress: "0x099198868576869d555968494829d6a1bB43b096",
      currencyDecimals: 6,
      gamespadOwner: "0xaceaecb820be3f147ff40d1c221b25f10ee8dd92",
      botsAddress: "0x3a17a676FE4f2464e84E57962956C99290B42f0d"
    },*/
    {
      id: 97,
      enabled: true,
      marketplaceAddress: "0x44fa2c2A0932FD1B968300Cb1ef25B22A56d7CcF",
      currencyAddress: "0xDaaa52B192743f57f8dC4A26F9287ADCdaAA7ba3",
      auctionAddress: "0x43FD0c94A417Eba814406bdf8Fce7fE700A27773",
      currencyDecimals: 18,
      gamespadOwner: "0xaceaecb820be3f147ff40d1c221b25f10ee8dd92",
      botsAddress: "0xe31431b4e3d0876046f11e2fd36822f35357ab43"
    },
    /*{
      id: 80001,
      enabled: true,
      marketplaceAddress: "0x099198868576869d555968494829d6a1bB43b096",
      currencyAddress: "0xB9eab6346989F6987ae5B1a7a3d83758710a3c89",
      auctionAddress: "0x59B1573cEB352a6E36b6c39e5C1bDaCe4a429915",
      landsAddress: "0x1a37f7b4d457809165af5abac87eda6951fe0544",
      currencyDecimals: 18,
      gamespadOwner: "0xaceaecb820be3f147ff40d1c221b25f10ee8dd92",
      botsAddress: ""
    },
    {
      id: -9999,
      enabled: true,
      marketplaceAddress: "",
      currencyAddress: "",
      auctionAddress: "49xC8esa8h2UMEGA6vckqc5N95pASGQFBAwm35GkHnWD",
      currencyDecimals: 9,
      gamespadOwner: "",
      botsAddress: ""
    }*/
  ],
  adminWallets: [
    '0xaceaEcB820Be3f147FF40d1C221B25F10ee8dd92'
  ],
  elseVerse: {
    initZoomLevel: 10,
    imagesMinZoomLevel: 12,
    minZoomLevel: 8.4,
    maxZoomLevel: 14,
    etalonHexagonRadius: 0.01175409316,
    polygonsMinZoomLevel: 8,
    boundsFromQGis: [
      [-3600000, 1900000], // Nothern east from QGIS
      [-2700000, 2500000] // south west from QGIS
    ],
    initCenter: [-28.26, 18.25],
    polygonLayerName: 'polygons',
    soldConfig: {
      defaultOpacity: 0,
      hoverOpacity: 0.16,
      focusOpacity: 0.48
    },
    onSellConfig: {
      defaultOpacity: 0.32,
      hoverOpacity: 0.44,
      focusOpacity: 0.63
    },
    iconsResizeLevel: 0.27,
    rarityColors: {
      'common': '#FFFFFF',
      'uncommon': '#00FF47',
      'rare': '#00F0FF',
      'epic': '#E961FF',
      'legendary': '#FFA800'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
