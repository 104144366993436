import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from 'rxjs';
import {TokenHistoryDTO} from '../../dto/tokenHistory.dto';
import {Web3Service} from "../web3.service";
import {BaseApiService} from "./base-api.service";
import {environment} from 'src/environments/environment';
import {LotteryModel} from "../../models/lottery.model";
import {BetModel, LotteryDto} from "../../dto/lottery.dto";
import {map} from "rxjs/operators";

// import collectionABI from '@/abi/collection.json';

@Injectable({providedIn: 'root'})
export class LotteryApiService extends BaseApiService {
  private get chainId(): number {
    return this.web3Service.chainIdNumber;
  }

  constructor(protected readonly httpClient: HttpClient, private readonly web3Service: Web3Service) {
    super(httpClient);
  }

  public getLotteries(): Observable<LotteryModel[]> {
    return this.getRequest<LotteryModel[]>(`api/lottery?chainId=${this.chainId}`)
      .pipe(
        map((t: any[]) => {
          return t.map<LotteryModel>((item: any) => {
            return Object.assign(new LotteryModel(), {
              ...item,
              startDate: Math.floor(Date.parse(item.startDate) / 1000),
              endDate: Math.floor(Date.parse(item.endDate) / 1000)
            });
          });
        })
      );
  }

  public getLottery(lotteryId: number): Observable<LotteryModel> {
    return this.getRequest<LotteryModel>(`api/lottery/${lotteryId}?chainId=${this.chainId}`)
      .pipe(
        map((t: any) => {
          return Object.assign(new LotteryModel(), {
            ...t,
            startDate: Math.floor(Date.parse(t.startDate) / 1000),
            endDate: Math.floor(Date.parse(t.endDate) / 1000)
          });
        })
      );
  }

  public bet(model: BetModel): Promise<any> {
    return this.postRequest<any>(`api/lottery/bet`, {...model, chainId: this.chainId}).toPromise();
  }

  public createLottery(model: LotteryDto): Promise<any> {
    return this.postRequest<any>(`api/lottery`, {...model, chainId: this.chainId}).toPromise();
  }
}
