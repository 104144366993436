import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import networks from "../components/shared/networks.data";
import marketplaceAbi from '../../assets/abi/marketplaceABI.json';
import auctionContract from '../../assets/abi/auctionContract.json';
import lotteryABI from '../../assets/abi/lotteryABI.json';
import lotteryEPABI from '../../assets/abi/lotteryEPABI.json';
import gmpdABI from '../../assets/abi/gmpdABI.json';
import blpABI from '../../assets/abi/blpABI.json';
import erc20ABI from '../../assets/abi/erc20ABI.json';
import erc721ABI from '../../assets/abi/erc721ABI.json';
import landsABI from '../../assets/abi/landsABI.json'
import BigNumber from 'bignumber.js';
import { AuthService } from "./auth.service";
import { ChainModel } from "../models/chain.model";
import { ConfirmationDialogService } from "./confirmation-dialog.service";
import { AlertService } from "./alert.service";
import {Router} from "@angular/router";

declare let window: any;

@Injectable({ providedIn: 'root' })
export class Web3Service {

    private connecting = new Subject<boolean>();
    public connecting$ = this.connecting.asObservable();

    public isConnected: boolean = false;

    public tokenInfo: any = {
        'gmpd': {
            tokenAddress: environment.gmpd,
            tokenABI: gmpdABI,
            decimals: 18
        },
        'blp': {
            tokenAddress: environment.blp,
            tokenABI: blpABI,
            decimals: 18
        }
    }

    private _modalOpened: boolean = false;

    public get currentAccountValue(): string {
        return this.currentAccount.value;
    };

    public get chainIdNumber(): number {
        return this.userSessionProvider.getChainId();
    };

    public get currentNetworkValue(): string {
        return this.currentNetwork.value != '' ? this.currentNetwork.value : `0x${environment.defaultChainId.toString(16)}`;
    }

    public get chain(): ChainModel {
        return environment.chains.find(x => x.id == this.chainIdNumber);
    }

    private _instance: Web3;
    private get web3Instance(): Web3 {
        if (!this._instance) {
            const httpProvider = new Web3.providers.HttpProvider('https://rpc.ankr.com/eth', { timeout: 10000 })
            this._instance = new Web3(window.ethereum || httpProvider);
        }
        return this._instance;
    }

    private readonly currentAccount = new BehaviorSubject<string>('');
    public readonly currentAccount$ = this.currentAccount.asObservable();

    private readonly currentNetwork = new BehaviorSubject<string>('');
    public readonly currentNetwork$ = this.currentNetwork.asObservable();

    public readonly sensetiveDataChanged$: Observable<[string, string]> = combineLatest([this.currentAccount, this.currentNetwork]);

    constructor(private readonly ngZone: NgZone, private userSessionProvider: AuthService, public confirmationDialogService: ConfirmationDialogService, private readonly alertService: AlertService, private readonly router: Router) {
        if (typeof window.ethereum == 'undefined') {
          this.handleMetamaskAbsence();
          return;
        }
        console.log('init web3');
        const account = localStorage.getItem('accountAddress');
        const network = localStorage.getItem('networkId');
        if (account && network) {
            this.initWallet(account, network);
        }

        if (window.ethereum) {
            window.ethereum.on('chainChanged', (chainId: string) => {
                console.log(`chainChanged: ${chainId}`);
                let chainIdNumber = parseInt(chainId, 16);
                console.log('chainIdNumber: ' + chainIdNumber);
                if (chainIdNumber != this.chainIdNumber) {
                    let supportedChains = environment.chains.filter(x => x.enabled).map(x => x.id);
                    if (supportedChains.indexOf(chainIdNumber) >= 0) {
                        this.userSessionProvider.setChainId(chainIdNumber);
                    }
                    else {
                        console.log('finishSession unsupported chain');
                        this.disconnect();
                        return;
                    }
                }
                location.reload();
            });
        }
    }

    public params(data: any): any {
      return { ...data, maxPriorityFeePerGas: null, maxFeePerGas: null };
    }  

    public handleMetamaskAbsence(): void {
      if (this._modalOpened) {
        return;
      }
      this._modalOpened = true;
      this.openConfirmationDialog();
    }

    public openConfirmationDialog() {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      this.confirmationDialogService.confirm(
        'Metamask needed',
        "To use this site Metamask should be installed. Go to " +
        (isMobile ? 'application' : 'extension') + "?" + (isMobile ? ' (!) Metamask App may open in range of 20-120 seconds, please be more patient' : ''),
        'Go',
        'Stay here')
        .then((confirmed) => {
          this._modalOpened = false;
          if (confirmed) {
            if (this.router.url == '/elseverse') {
              window.open("https://metamask.app.link/dapp/marketplace.gamespad.io/elseverse");
            }else {
              window.open("https://metamask.app.link/dapp/marketplace.gamespad.io");
            }
          } else {
            if (isMobile) {
              this.alertService.showAlert('Please install MetaMask application to proceed', true);
            } else {
              this.alertService.showAlert('Please install MetaMask extension to proceed', true);
            }
          }

        })
        .catch(() => {
          this._modalOpened = false;
          console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
        });
    }

    public async getLandPrice(centerX: number, centerY: number, rarity: number, user: string, whitelistProof: any, referrer: string): Promise<any> {
      return new Promise((resolve, reject) => {
        let contract = new this.web3Instance.eth.Contract(landsABI as any, this.chain.landsAddress);
        contract.methods.getPrice(new BigNumber(centerX), new BigNumber(centerY), rarity, user, whitelistProof, referrer)
            .call({ from: this.currentAccountValue }, (error: any, resp: any) => {
              resolve(resp);
            });
      }) as Promise<any>;
    }

    public async getLandBasePrice(rarity: number): Promise<any> {
      return new Promise((resolve, reject) => {
        let contract = new this.web3Instance.eth.Contract(landsABI as any, this.chain.landsAddress);
        contract.methods.prices(rarity)
          .call({ from: this.currentAccountValue }, (error: any, resp: any) => {
            resolve(resp);
          });
      }) as Promise<any>;
    }

    public async mintLand(to: string, centerX: number, centerY: number, rarity: number, landProof: any): Promise<any> {
      let contract = new this.web3Instance.eth.Contract(landsABI as any, this.chain.landsAddress);
      return contract.methods.safeMint(to, centerX, centerY, rarity, landProof).send(this.params({ from: this.currentAccountValue }));
    }

    public async buyLand(centerX: number, centerY: number, rarity: number, landProof: any, whitelistProof: any, referrer: string): Promise<any> {
      let contract = new this.web3Instance.eth.Contract(landsABI as any, this.chain.landsAddress);
      return contract.methods.safeBuy(centerX, centerY, rarity, landProof, whitelistProof, referrer).send(this.params({ from: this.currentAccountValue }));
    }

    public async ensureAllowance(address: string, currency: string, amount: string): Promise<void>{
      const contract = new this.web3Instance.eth.Contract(erc20ABI.abi as any, currency);
      const allowedAmount = await contract.methods.allowance(this.currentAccountValue, address).call();

      if (new BigNumber(allowedAmount).lt(new BigNumber(amount))) {
        await contract.methods.approve(address, amount).send(this.params({ from: this.currentAccountValue }));
      }
    }

    public async GetTokenBalance(account: string, tokenAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
          let contract = new this.web3Instance.eth.Contract(erc20ABI.abi as any, tokenAddress);
          contract.methods.balanceOf(account).call({}, (error: any, resp: any) => {
            resolve(resp);
          });
        }) as Promise<any>;
      }

    public getPosition(positionId: number): Promise<any> {
      console.log('positionId', positionId);
        return new Promise((resolve, reject) => {
            let contract = new this.web3Instance.eth.Contract(marketplaceAbi.abi as any, this.chain.marketplaceAddress);
            contract.methods.positions(positionId).call({}, (error: any, resp: any) => {
                console.log("positionId resp", resp);
                resolve(resp);
            });
        }) as Promise<any>;
    }

    public async putNFTOnSale(tokenId: number, tokenType: any, amount: any, collection: string, currency: string, price: any): Promise<any> {
        const marketplaceContract = new this.web3Instance.eth.Contract(
            marketplaceAbi.abi as any,
            this.chain.marketplaceAddress
        );

        const collectionContract = new this.web3Instance.eth.Contract(erc721ABI.abi as any, collection);

        const isApproved = await collectionContract.methods
            .isApprovedForAll(this.currentAccountValue, this.chain.marketplaceAddress)
            .call();

        if (!isApproved) {
            await collectionContract.methods.setApprovalForAll(this.chain.marketplaceAddress, true).send(this.params({ from: this.currentAccountValue }));
        }

        return marketplaceContract.methods
            .putOnSale(
                collection,
                tokenType,
                tokenId,
                amount.toString(10),
                new BigNumber(price).shiftedBy(this.chain.currencyDecimals).toString(10),
                currency
            )
            .send(this.params({ from: this.currentAccountValue }));
    }

    public async bulkList(data: any): Promise<any> {
        const marketplaceContract = new this.web3Instance.eth.Contract(
            marketplaceAbi.abi as any,
            this.chain.marketplaceAddress
        );

        const collectionContract = new this.web3Instance.eth.Contract(erc721ABI.abi as any, data[0].collection);

        const isApproved = await collectionContract.methods
            .isApprovedForAll(this.currentAccountValue, this.chain.marketplaceAddress)
            .call();

        if (!isApproved) {
            await collectionContract.methods.setApprovalForAll(this.chain.marketplaceAddress, true).send(this.params({ from: this.currentAccountValue }));
        }

        return marketplaceContract.methods
            .putBunchOnSale(data)
            .send(this.params({ from: this.currentAccountValue }));
    }

    public async stopAuction(id:number):Promise<any>{
        const auction = new this.web3Instance.eth.Contract(
            auctionContract.abi as any,
            this.chain.auctionAddress
          );
        return auction.methods.stopAuction(id).send(this.params({from : this.currentAccountValue}));
    }

    public isAddress(wallet: string): boolean {
        return this.web3Instance.utils.isAddress(wallet);
    }

    public async createAuction(tokenId: number, collection: string, currency: string, startPrice: any, durationInDays: number): Promise<any> {
      const auction = new this.web3Instance.eth.Contract(
        auctionContract.abi as any,
        this.chain.auctionAddress
      );

      const collectionContract = new this.web3Instance.eth.Contract(erc721ABI.abi as any, collection);

      const isApproved = await collectionContract.methods
        .isApprovedForAll(this.currentAccountValue, this.chain.auctionAddress)
        .call();

      if (!isApproved) {
        await collectionContract.methods.setApprovalForAll(this.chain.auctionAddress, true).send(this.params({ from: this.currentAccountValue }));
      }
      console.log(auction, 'decimal places: ', this.chain.currencyDecimals);

      return auction.methods
        .startAuction(
          collection,
          tokenId,
          new BigNumber(startPrice).shiftedBy(this.chain.currencyDecimals).toString(10),
          currency,
          // Duration in seconds
          Math.round(durationInDays*24*60*60)
        )
        .send(this.params({ from: this.currentAccountValue }));
    }


    public async isAllowed(contractAddress:string, currency:string):Promise<number>{
        const contract = new this.web3Instance.eth.Contract(erc20ABI.abi as any, currency);
        const isAllowed:number = await contract.methods.allowance(this.currentAccountValue, contractAddress).call();
        console.log('is allowed', isAllowed);
        return isAllowed;
    }

    public async cancelSale(positionId: number): Promise<any> {
        const marketplaceContract = new this.web3Instance.eth.Contract(
            marketplaceAbi.abi as any,
            this.chain.marketplaceAddress
        );

        return marketplaceContract.methods.cancel(positionId.toString(10)).send(this.params({ from: this.currentAccountValue }));
    }

    public async approveToken(tokenType:string, amount: number): Promise<any> {
        const tokenContract = new this.web3Instance.eth.Contract(this.tokenInfo[tokenType].tokenABI as any, this.tokenInfo[tokenType].tokenAddress);
        let decimal:number = await tokenContract.methods.decimals().call();
        await tokenContract.methods.approve(environment.lotteryContract, new BigNumber(amount).shiftedBy(Number(decimal)).toString(10)).send(this.params({ from: this.currentAccountValue }));
    }

    public async bet(positionId: number, amount: number, currencyId: number): Promise<any> {
        const lotteryContract = new this.web3Instance.eth.Contract(lotteryABI as any, environment.lotteryContract);
        await lotteryContract.methods.bet(positionId, amount, currencyId).send(this.params({ from: this.currentAccountValue }));
    }

    public isLotteryAdmin(): boolean {
        return this.currentAccountValue.toLowerCase() == environment.lottery_owner.toLowerCase();
    }

    public async createLottery(networkId: number, collection: string, id: number, start: number, end: number, minPrice: number, maxPrice: number, blpPrice: number, gmpdPrice: number): Promise<number> {
        const lotteryContract = new this.web3Instance.eth.Contract(lotteryABI as any, environment.lotteryContract);
        let result = await lotteryContract.methods.startLottery(networkId, collection, id, start, end, minPrice, maxPrice, blpPrice, gmpdPrice).send(this.params({ from: this.currentAccountValue }));
        return result.events.NewPosition.returnValues.positionID;
    }

    public async checkNftExist(networkId: number, collection: string, id: number): Promise<boolean> {
        let result
        try {
            let contractAddress = networkId == 1 ? environment.lotteryEthereumContract : environment.lotteryPolygonContract;
            const web3 = await this.getWeb3(networkId);
            const lotteryEPContract = new web3.eth.Contract(lotteryEPABI as any, contractAddress);
            result = await lotteryEPContract.methods.checkIfNftExist(collection, id).call();
        } catch (e: any) {
            console.log('check nft exist on lotteryEP >>>', e.message);
            result = false;
        }

        return result;
    }

    public async getWeb3(networkId: number) {
        if (typeof window !== "undefined") {
            const httpProvider = new Web3.providers.HttpProvider(networkId == 1 ? environment.rpcUrlEthereum : environment.rpcUrlPolygon, { timeout: 10000 });
            const web3 = new Web3(httpProvider);
            return web3;
        } else {
            return null
        }
    }

    public async buyToken(positionId: number, currenty:string, amount: number = 1): Promise<any> {
        let contract = new this.web3Instance.eth.Contract(erc20ABI.abi as any, currenty);
        const isAllowed = await this.isAllowed(this.chain.marketplaceAddress, currenty);

        if (isAllowed == 0) {
            await contract.methods
            .approve(this.chain.marketplaceAddress, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
            .send(this.params({ from: this.currentAccountValue }));
        } else {
            console.log('allowed');
        }

        const marketplaceContract = new this.web3Instance.eth.Contract(
            marketplaceAbi.abi as any,
            this.chain.marketplaceAddress
        );
        return marketplaceContract.methods
            .buy(positionId.toString(10), amount.toString(10), this.currentAccountValue, 0)
            .send(this.params({ from: this.currentAccountValue }));

    }

    public async makeBid(positionId: number, currency:string, bidAmount: number): Promise<any> {
      let contract = new this.web3Instance.eth.Contract(erc20ABI.abi as any, currency);
      const isAllowed = await this.isAllowed(this.chain.auctionAddress, currency);

      if (isAllowed == 0) {
        await contract.methods
          .approve(this.chain.auctionAddress, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
          .send(this.params({ from: this.currentAccountValue }));
      } else {
        console.log('allowed');
      }

      const auction = new this.web3Instance.eth.Contract(
        auctionContract.abi as any,
        this.chain.auctionAddress
      );
      return auction.methods
        .placeBid(positionId.toString(10), new BigNumber(bidAmount).shiftedBy(this.chain.currencyDecimals).toString(10))
        .send(this.params({ from: this.currentAccountValue }));
    }

    public async connectWallet(): Promise<void> {
        if (typeof window.ethereum == 'undefined') {
            console.log("MetaMask must be installed");
            return;
        }

        this.connecting.next(true);

        const accountAddressPromise = window.ethereum.request({ method: 'eth_requestAccounts' });
        const networkPromise = window.ethereum.request({ method: 'eth_chainId' });

        await Promise.all([accountAddressPromise, networkPromise]).then(([account, network]) => {
            this.initWallet(account[0], network)
            this.connecting.next(false);
        });
    }

    public disconnect():void{
        this.isConnected = false;
        this.currentAccount.next(null);
        localStorage.removeItem('accountAddress');
        localStorage.removeItem('networkId');

    }

    private initWallet(address: string, network: string): void {
        this.setAccount(address);
        this.setNetwork(network);
        this.listenChangeEvents();
        this.isConnected = true;
    }

    private listenChangeEvents(): void {
        this.watchAccountChange();
        this.watchNetworkChange();
    }

    // public async getUsersNFTs(account: string, tokenAddresses: [string], chain: any, ): Promise<any> {
    //     return await moralis.Web3API.account.getNFTs({ chain, address: account, token_addresses: tokenAddresses });
    // }

    // public async getNftInfo(nftAddress: string, nftTokenIds: string, chain: any): Promise<any> {
    //     return await moralis.Web3API.token.getTokenIdMetadata({ chain, address: nftAddress, token_id: nftTokenIds });
    // }

    // public async getNftType(nftAddress:string, tokenId:number):Promise<any>{
    //     let contract = new this.web3Instance.eth.Contract(gmpdNftCollectionABI.abi as any, nftAddress);
    //     return await contract.methods.nftTypes(tokenId).call();
    // }

    private watchAccountChange(): void {
        this.web3Instance.eth.givenProvider.on("accountsChanged", (accounts: any) => {
            this.setAccount(accounts[0]);
        })
    }

    private watchNetworkChange(): void {
        this.web3Instance.eth.givenProvider.on("chainChanged", (chainId: string) => {
            this.setNetwork(chainId);
        })
    }

    public personalSign(dataToSign: string, address: string): Promise<string> {
        return this.web3Instance.eth.personal.sign(dataToSign, address, '');
    }

    public encodeReceiverPositionInput(input: any): any {
        return this.web3Instance.eth.abi.encodeFunctionCall({
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_position",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "_currency",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_amount",
                    "type": "uint256"
                }
            ],
            "name": "send",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }, input);
    }

    public encodeLandFiatBuyInput(input: any): any {
        return this.web3Instance.eth.abi.encodeFunctionCall({
          "inputs": [
            {
              "internalType": "address",
              "name": "_buyer",
              "type": "address"
            },
            {
              "internalType": "int256",
              "name": "_centerX",
              "type": "int256"
            },
            {
              "internalType": "int256",
              "name": "_centerY",
              "type": "int256"
            },
            {
              "internalType": "uint256",
              "name": "_rarity",
              "type": "uint256"
            },
            {
              "internalType": "bytes32[]",
              "name": "_landProof",
              "type": "bytes32[]"
            },
            {
              "internalType": "address",
              "name": "_referrer",
              "type": "address"
            }
          ],
          "name": "fiatBuy",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        }, input);
    }

    private setAccount(account: string): void {
        this.ngZone.run(() => {
            this.currentAccount.next(account);
            localStorage.setItem('accountAddress', account);
        })

    }

    private setNetwork(networkId: string): void {
        this.ngZone.run(() => {
            this.currentNetwork.next(networkId);
            localStorage.setItem('networkId', networkId);
        })

    }

  async switchChain(toChainId: number): Promise<boolean> {
    let chainId = await window.ethereum.request({ method: 'eth_chainId' });
    let chainIdNumber = parseInt(chainId, 16);
    console.log(toChainId, chainId, chainIdNumber);
    if (toChainId === chainIdNumber) {
      return true;
    }
    var toNetwork = networks.find(n => n.chainId == toChainId);
    if (toNetwork.networkParams) {
      try {
        // @ts-ignore
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{chainId: toNetwork.chainIdHex}],
        });
        return true;
      } catch (switchError: any) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [toNetwork.networkParams],
            });
            return true;
          } catch (addError) {
            console.error(addError);
          }
        }
      }
    }
    return false;
  }
}

// export function WalletAccountRequired(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
//     console.log('UnlockedWallet');
//     const originalMethod = descriptor.value;
//     descriptor.value = async function (...args: any) {
//         // const service = ExtraModuleInjector.get<UsersServiceProxy>(UsersServiceProxy);
//         const unlockWalletResult = await window.ethereum.request({ method: 'eth_requestAccounts' });
//         console.log("unlockWalletResult", unlockWalletResult);
//         if (unlockWalletResult.length > 0) {
//             console.log('originalMethod', originalMethod, [unlockWalletResult[0], ...args])
//             return originalMethod.apply(this, [unlockWalletResult[0], ...args]);
//         } else {
//             console.log("not provided");
//             // swal.fire({
//             //     text: 'You must complete KYC form first.',
//             //     icon: 'warning',
//             // });
//         }
//     };
//     return descriptor;
// }
