import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { BehaviorSubject, combineLatest, forkJoin, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import BigNumber from "bignumber.js";
import { CancelPositionModel } from "src/app/models/cancel-position.model";
import { OpenPositionModel } from "src/app/models/open-position.model";
import { Web3Service } from "src/app/services/web3.service";
import { environment } from "src/environments/environment";
import { PositionsApiService } from "../../services/api/positions-api.service";
import { TokensApiService } from "../../services/api/tokens-api.service";
import { parseJson } from "@angular/cli/utilities/json-file";
import { ProjectDTO } from "../../dto/project.dto";
import { ModalService } from "../modal/modal.service";
import { AlertService } from "../../services/alert.service";
import { CreateAuctionModel } from "../../models/create-auction.model";
import { PositionModel } from "src/app/models/position.model";
import { ActivatedRoute, Router } from "@angular/router";
import networksData from "../shared/networks.data";
import INetworkData from "../../models/networks.data";
import { ClosedAuctionPipe } from "src/app/pipes/closed-auction.filter.pipe";

@Component({
    selector: 'app-user-nfts',
    templateUrl: './user-nfts.component.html',
    styleUrls: ['./user-nfts.component.scss'],
    providers: [ClosedAuctionPipe]
})
export class UserNFTsComponent {
    public items: PositionModel[] = [];
    public itemsForBatchList: PositionModel[] = [];
    public itemsCount: number = 0;
    public itemsTotal: number = 0;
    public itemsCursor: string = '';
    public itemsAccount: string = '';
    public myPositions: any;
    public projects: ProjectDTO[] = [];
    public selectedProject: ProjectDTO = null;
    public selectedAddress$ = new BehaviorSubject<string>('');
    public pageNumber$ = new BehaviorSubject<number>(1);
    public showOnSellItems$ = new BehaviorSubject<boolean>(false);
    public pageSize: number = 100;
    public isLoading: boolean = true;
    public dealApproved: boolean;
    public isAuction: boolean;
    public currentItem: any;
    public bidAmount: number;
    public auctionDaysAmount: number;
    public nftDescription: string;
    public eventDescription: string;
    public eventTitle: string;
    public showSellOptions: boolean;
    public isSpecialEvent: boolean;
    public showOnSellChanged: boolean = false;
    public isSetBatchList: boolean = false;
    public checkedBulkListCount: number = 0;
    public bulkListAvailAmount: number;
    public isBatchListVisible: boolean = false;
    public isActiveOnePriceForAll: boolean = false;
    public priceForAll: string;

    public readonly minAuctionLength = environment.auction.minLengthInDays;
    public readonly maxAuctionLength = environment.auction.maxLengthInDays;

    public get isAdmin(): boolean {
      return environment.adminWallets.some(w=>w?.toLowerCase() == this.web3.currentAccountValue?.toLowerCase());
    }

    public userNFT$ = combineLatest([this.web3.currentAccount$, this.web3.currentNetwork$, this.pageNumber$, this.selectedAddress$, this.showOnSellItems$]).pipe(
        switchMap(([account, network, pageNumber, address, showOnSell]) => {
          if (showOnSell) {
            const values = Object.values(this.myPositions);
            return of(<any>{
              total: values.length,
              result: values
            });
          }
          this.isLoading = true;

          if (this.itemsAccount && this.itemsAccount.toLowerCase() != account.toLowerCase()) {
            this.resetItems();
          }

          this.itemsAccount = account;

          if (address === 'all') {
              const requests = this.projects.map((project) => {
                return project.name !== 'All' && this.tokensApi.getUsersNFTs(account, project.address, network, this.pageSize, this.itemsCursor).toPromise();
              });
              const filteredRequests = requests.filter((res) => res)
              return forkJoin(filteredRequests).pipe(
                map((responses) => {
                  return responses.reduce((accumulator, currentResponse) => {
                    return {
                      ...accumulator,
                      result: [
                        ...accumulator.result,
                        ...currentResponse.result
                      ]
                    };
                  }, { total: 0, result: [] });
                })
              ).toPromise();
          } else {
            return address ? this.tokensApi.getUsersNFTs(account, address, network, this.pageSize, this.itemsCursor) : [];
          }
        }),
        map((i: any) => {
            this.itemsTotal = i.total;
            this.itemsCursor = i.cursor;
            const nfts = new Array<PositionModel>();
            i.result.forEach((item: any) => {
                const metadata = item.metadata ? parseJson(item.metadata) : null;
                const matchingProject = this.projects.find(project => project.address.toLowerCase() === item.token_address.toLowerCase());
                nfts.push(Object.assign(new PositionModel(), {
                  metaData: {...item, metadata: metadata},
                  project: matchingProject,
                  tokenAddress: item.token_address,
                  ownerAddress: this.web3.currentAccountValue,
                  tokenId: item.token_id,
                  closed: false
                }));
            });
            if (this.showOnSellChanged){
              this.items = [];
              this.showOnSellChanged = false;
            }
            this.items = this.showOnSellItems$.value ? i.result : this.items.concat(nfts);
            this.items = this.closedAuctionPipe.transform(this.items);
            this.itemsCount = this.items.length;
            this.isLoading = false;
            this.getBulkListAvailAmount();
            this.getCountCheckedForBunchList();
            return this.items;
        }));

    constructor(private readonly web3: Web3Service,
                private readonly positionsApi: PositionsApiService,
                private readonly tokensApi: TokensApiService,
                public readonly modalService: ModalService,
                private readonly alertService: AlertService,
                private readonly router: Router,
                private readonly closedAuctionPipe: ClosedAuctionPipe,
                private readonly route: ActivatedRoute,
                @Inject(DOCUMENT) private document: any) {
        this.getMyNFT();
        this.userNFT$.subscribe();
    }

    async ngOnInit() {
        this.projects = await this.positionsApi.getProjects().toPromise();
        //this.projects.unshift({ address: "all", creator: "all", icon: "", id: null, name: "All" })
        const address = this.route.snapshot.queryParamMap.get('collection') || this.projects[0]?.address;
        this.changeAddress(address);
    }

    public get nftChain(): INetworkData {
      return networksData.filter(n=>n.chainId == this.web3.chain.id)[0];
    }

    public get isGmpd(): boolean {
        return this.selectedProject?.address.toLowerCase() == this.web3.chain.botsAddress.toLowerCase();
    }

    public setBatchList(): void {
      this.isSetBatchList = true;
    }

    public bulkList(): void {
      this.itemsForBatchList = this.items.filter(item => item.isCheckedForBunchList).map(item => {
        item.price = this.isActiveOnePriceForAll ? this.priceForAll || '1' : '1';
        return item;
      });
      if (this.itemsForBatchList.length == 0) return;
      this.isBatchListVisible = true;
    }

    public async listBunchNft(): Promise<void> {

      if (this.itemsForBatchList.filter(item => new BigNumber(item.price).gt(new BigNumber(0))).length != this.itemsForBatchList.length) {
        this.alertService.show('Price should be more than 0!');
        return;
      }

      let data = this.itemsForBatchList.map(item => {
        return {
          collection: item.collectionAddress,
          tokenType: 1,
          id: item.tokenId,
          amount: '1',
          price: new BigNumber(item.price).shiftedBy(this.web3.chain.currencyDecimals).toString(10),
          currency: this.web3.chain.currencyAddress
        }
      });
      let models = [];

      try {
          this.startLoading();
          this.document.body.classList.add('wait_openid');
          const openPositions = await this.web3.bulkList(data);
          models = this.itemsForBatchList.map(item => {
            let model = {
                transactionHash: openPositions.transactionHash,
                tokenAddress: this.selectedProject.address,
                tokenId: item.tokenId,
                tokenTypeId: 1,
                tokenItemTypeId: item.tokenTypeId,
                tokenImage: item.tokenImage,
                tokenName: item.tokenType,
                amount: 1,
                price: item.price.toString(),
                ownerAddress: this.web3.currentAccountValue,
                currencyAddress: this.web3.chain.currencyAddress,
                description: this.nftDescription,
                isSpecialEvent: this.isSpecialEvent,
                eventDescription: this.eventDescription,
                eventTitle: this.eventTitle,
                chainId: this.web3.chainIdNumber
            }
            return model;
          });
          console.log('models >>>>', models);
          await this.positionsApi.openBulkPositions(models).toPromise();
          await this.getMyNFT();
          if (models.length > 1)
            this.alertService.show('Your NFTs have been placed at marketplace!');
          else
            this.alertService.show('Your NFT has been placed at marketplace!');

          this.isSetBatchList = false;
          this.selectAllForBulkList(false);
          this.getBulkListAvailAmount();
          this.isBatchListVisible = false;
      }
      catch (e) {
          console.log('bulk list error', e);
          this.alertService.show('Unexpected error happened');
      }
      finally {
          this.stopLoading();
      }
    }

    getBulkListAvailAmount(): void {
      this.bulkListAvailAmount = 0;
      this.items.map((item: PositionModel) => {
        if (!this.isOnSale(item)) {
          this.bulkListAvailAmount += 1;
        }
      });
    }

    public setBulkListItem(data: any): void {

      this.items = this.items.map((item: PositionModel) => {
        if (item.tokenId == data[0]) {
          item.isCheckedForBunchList = data[1];
          item.forceCheckedForBunchList = data[1];
        }
        return item;
      });

      this.getCountCheckedForBunchList();
    }

    public selectAllForBulkList(state: boolean): void {
      this.items = this.items.map((item: PositionModel) => {
        if (!this.isOnSale(item)) {
          if (item.forceCheckedForBunchList == state) {
            item.forceCheckedForBunchList = !state;
          }
          item.forceCheckedForBunchList = state;
          item.isCheckedForBunchList = state;
        }
        return item;
      });
      if (state)
        this.getCountCheckedForBunchList();
      else
        this.checkedBulkListCount = 0;

    }

    public getCountCheckedForBunchList(): void {
      this.checkedBulkListCount = this.items.filter((item: PositionModel) => item.isCheckedForBunchList).length;
    }

    public setAllPriceSame(data: any): void {
      this.itemsForBatchList = this.itemsForBatchList.map(item => {
        item.price = data.toString();
        return item;
      });
    }

    public nftTypeById(data: PositionModel): any {
      return data.tokenName?.replace(' ', '_').toLowerCase();
    }

    public isOnSale(item: PositionModel): boolean {
      if (!this.myPositions)
        return false;
      if (this.showOnSellItems$.value)
        return true;
      const myPos = this.myPositions[item.tokenId];
      return myPos &&
        (myPos?.status == 'open' || myPos.isAuction) &&
        myPos.tokenAddress?.toLowerCase() == item.collectionAddress?.toLowerCase();
    }

    public async getMyNFT(): Promise<void> {
        this.positionsApi.getOwnPositions(this.web3.currentAccountValue).pipe(
            map(data => data.items)
        ).subscribe(result => {
            const convertArrayToObject = (array: any[], key: any) => {
                const initialValue = {};
                return array.reduce((obj, item) => {
                    return {
                        ...obj,
                        [item[key]]: Object.assign(new PositionModel(), item),
                    };
                }, initialValue);
            };
            this.myPositions = convertArrayToObject(result, "tokenId");
            this.items = this.items.map(item => {
              if (this.myPositions[item.tokenId])
                return this.myPositions[item.tokenId] || item;
              else
                return item;
            });
            this.getBulkListAvailAmount();
        });
    }

    public async sellNFT(item: any): Promise<void> {
        try {
            item.sellAmount = this.bidAmount;
            this.startLoading();
            this.document.body.classList.add('wait_openid');
            const openPosition = await this.web3.putNFTOnSale(item.tokenId, 1, 1, this.selectedProject.address, this.web3.chain.currencyAddress, item.sellAmount);
            const model: OpenPositionModel = {
                transactionHash: openPosition.transactionHash,
                tokenAddress: this.selectedProject.address,
                tokenId: item.tokenId,
                tokenTypeId: 1,
                tokenItemTypeId: item.typeId,
                tokenImage: this.isGmpd ? null : item.image,
                tokenName: item.type,
                amount: 1,
                price: item.sellAmount.toString(),
                ownerAddress: this.web3.currentAccountValue,
                currencyAddress: this.web3.chain.currencyAddress,
                description: this.nftDescription,
                isSpecialEvent: this.isSpecialEvent,
                eventDescription: this.eventDescription,
                eventTitle: this.eventTitle
            }
            await this.positionsApi.openPosition(model).toPromise();
            await this.getMyNFT();
            this.modalService.close('sell-nft');
            this.alertService.show('Your NFT has been placed at marketplace!');
        }
        catch (e) {
            console.log('sellNFT error', e);
            this.alertService.show('Unexpected error happened');
        }
        finally {
            this.stopLoading();
        }
    }

    public async cancelNFT(item: PositionModel): Promise<void> {
        try {
            this.startLoading();
            const cancelPosition = await this.web3.cancelSale(item.id);
            const model: CancelPositionModel = {
                id: item.id,
                transactionHash: cancelPosition.transactionHash
            }
            await this.positionsApi.cancelPosition(model).toPromise();
            delete this.myPositions.item;
            this.modalService.close('cancel-nft');
            await this.getMyNFT();
        }
        catch (e) {
            console.log('cancel error', e);
        }
        finally {
            this.stopLoading();
        }
    }

    public changeAddress(address: string): void {
        if (address?.toLowerCase() != this.selectedAddress$.value) {
            this.isLoading = true;
            this.resetItems();
            this.selectedProject = this.projects.find(x => x.address.toLowerCase() == address.toLowerCase());
            this.selectedAddress$.next(address);
        }
    }

    public loadMore(): void {
        this.pageNumber$.next(this.pageNumber$.value + 1);
    }

    private resetItems(): void {
        this.itemsCount = this.itemsTotal = 0;
        this.items = [];
        this.itemsCursor = '';
    }

    private startLoading() {
        this.isLoading = true;
        this.document.body.classList.add('wait_openid');
    }

    private stopLoading() {
        this.isLoading = false;
        this.document.body.classList.remove('wait_openid');
    }

  async createAuction(item: any): Promise<void> {
    try {
      item.sellAmount = this.bidAmount;
      this.startLoading();

      if (!this.web3.chain.auctionAddress){
        this.alertService.show('Sorry, auction is not supported for selected chain.');
        return;
      }
      const auctionResult = await this.web3.createAuction(item.tokenId,  this.selectedProject.address, this.web3.chain.currencyAddress, item.sellAmount, this.auctionDaysAmount);
      const auctionId = auctionResult.events.NewAuction.returnValues.id;
      const endDate = new Date();
      endDate.setSeconds(endDate.getSeconds()+this.auctionDaysAmount*24*60*60);
      const model: CreateAuctionModel = {
        auctionId: auctionId,
        transactionHash: auctionResult.transactionHash,
        tokenAddress: this.selectedProject.address,
        tokenId: item.tokenId,
        tokenTypeId: 1,
        tokenItemTypeId: item.typeId,
        tokenImage: this.isGmpd ? null : item.image,
        tokenName: item.type,
        amount: 1,
        price: item.sellAmount.toString(),
        ownerAddress: this.web3.currentAccountValue,
        currencyAddress: this.web3.chain.currencyAddress,
        endDate: endDate,
        description: this.nftDescription,
        isSpecialEvent: this.isSpecialEvent,
        eventDescription: this.eventDescription,
        eventTitle: this.eventTitle
      }
      await this.positionsApi.createAuction(model).toPromise();
      await this.getMyNFT();
      this.modalService.close('sell-nft');
      this.alertService.show('An auction has been created!');
    }
    catch (e) {
      console.log('createAuction error', e);
      this.alertService.show('Unexpected error happened');
    }
    finally {
      this.stopLoading();
    }
  }

  getEndDate(auctionDaysAmount: number): string {
    const now = new Date();
    now.setSeconds(now.getSeconds()+auctionDaysAmount*24*60*60);
    return now.toLocaleString();
  }

  openModal(item: any, isAuction: boolean) {
      this.isAuction = isAuction;
      this.showSellOptions = false;
      this.currentItem = item;
      if (!item.metadata && item.metaData){
        item.metadata = item.metaData;
      }
      this.nftDescription = item.metadata?.description == item.metadata?.name ? '' : item.metadata?.description;
      this.dealApproved = false;
      this.modalService.open('sell-nft');
  }

  approveDeal() {
    if (this.canChangeDescription(this.currentItem)) {
      const tokenName = this.currentItem.metadata?.name || this.currentItem.metaData?.name;
      if (!this.nftDescription || this.nftDescription?.toLowerCase() == tokenName?.toLowerCase()){
        this.alertService.show('Description will be placed on NFT page and visible to users, please add meaningful info about the NFT.');
        return;
      }
    }
    if (!this.bidAmount || this.bidAmount < 1) {
      this.alertService.show('Price must be greater than 1');
      return;
    }
    if (this.isAuction && (!this.auctionDaysAmount || this.auctionDaysAmount > this.maxAuctionLength || this.auctionDaysAmount < this.minAuctionLength)) {
      this.alertService.show(`Auction should run between ${this.minAuctionLength} and ${this.maxAuctionLength} days.`);
      return;
    }
    this.dealApproved = true;
  }

  canChangeDescription(currentItem: any) :boolean {
    return !this.isGmpd;
  }

  openConfirmationModal(item: any) {
    this.currentItem = item;
    this.modalService.open('cancel-nft');
  }


  finishAuctionHandler(item: any){
    this.currentItem = item;
    this.modalService.open('finish-auction');
  }

  stopAuctionHandler(item: any){
    this.currentItem = item;
    this.modalService.open('cancel-auction');
  }

  async finishAuctionCall(){
    this.modalService.close('finish-auction');
    this.startLoading();
    await this.web3.stopAuction(this.currentItem.id);
    await this.positionsApi.closeAuction({auctionId:this.currentItem.id, chainId: this.currentItem.chainId, endDate: new Date(), winnerAddress:'--', winnerBid: this.currentItem.currentBid}).toPromise();
    this.resetSelectedAuction();
    this.stopLoading();
  }

  async stopAuctionCall(){
    this.modalService.close('cancel-auction');
    this.startLoading();
    await this.web3.stopAuction(this.currentItem.id);
    await this.positionsApi.closeAuction({auctionId:this.currentItem.id, chainId: this.currentItem.chainId, endDate: new Date(), winnerAddress:"--", winnerBid: "0"}).toPromise();
    this.resetSelectedAuction();
    this.stopLoading();
  }

  private resetSelectedAuction(){
    this.currentItem.closed = true;
    this.currentItem.isAuction = false;
    this.currentItem.price = undefined;
    this.currentItem.chainId = undefined;
    this.itemsCount--;
    this.itemsTotal--;
  }

  openNft(position: PositionModel) {
    if (this.myPositions && this.isOnSale(position)) {
      const myPos: PositionModel = this.myPositions[position.tokenId];
      this.router.navigate([myPos.isAuction ? 'auction' : 'nft', myPos.chainId, myPos.id]);
    }
  }

  showOnSale(showOnSale: boolean) {
      this.showOnSellChanged = true;
      this.showOnSellItems$.next(showOnSale);
  }
}

