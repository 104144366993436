import { Component, Inject, Input, NgZone, OnInit } from "@angular/core";
import { LandInfo } from "./land-info";
import { LandService } from "./land-service.service";
import { ElseverseApiService } from "../../../services/api/elseverse-api.service";
import { environment } from "../../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { ElseverseConfig } from "../../../../environments/elseverse.config";
import mapboxgl, { GeoJSONSource, LngLatBounds, LngLatBoundsLike, LngLatLike } from "mapbox-gl";
import { Web3Service } from "../../../services/web3.service";
import { TokensApiService } from "../../../services/api/tokens-api.service";
import { PositionsApiService } from "../../../services/api/positions-api.service";
import { UsersApiService } from "../../../services/api/users-api.service";
import BigNumber from "bignumber.js";
import { DOCUMENT } from "@angular/common";
import { AlertService } from "../../../services/alert.service";
import { ModalService } from "../../modal/modal.service";
import WertWidget from '@wert-io/widget-initializer';
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import { v4 as uuidv4 } from "uuid";
import { AuthService } from "../../../services/auth.service";
import { Subscription } from "rxjs";
import { RealtimeService } from "../../../services/realtime.service";
import { LandMintEvent } from "../../../dto/events/land-mint.event";
import { PositionModel } from "../../../models/position.model";

declare let window: any;

@Component({
  selector: 'landsale-map',
  templateUrl: './landsale-map.component.html',
  styleUrls: ['./landsale-map.component.scss']
})
export class LandsaleMapComponent implements OnInit {
  @Input() selectedSellType: any;
  @Input() selectedBiomeType: any;
  @Input() selectedRarityType: any;
  // @ts-ignore
  public config: ElseverseConfig = environment.elseVerse;
  public selectedLand: LandInfo;
  private lands: LandInfo[];
  private allLands: LandInfo[];
  private map: mapboxgl.Map;
  public test: any;
  public maxBounds: [number, number, number, number];
  public maxBoundsFloat: LngLatBoundsLike;
  public zoomable: boolean = false;
  private hexagonRadius: number = 0;

  public groups: any = [];
  public basePrices: number[] = [];

  private isLoading: boolean = false;
  private subscriptions: Subscription[] = [];
  private userNftIds: number[];
  private positions: PositionModel[];
  private referrer: string = '0x0000000000000000000000000000000000000000'; 

  public wertWidget: any;

  constructor(private readonly landService: LandService,
              private readonly router: Router,
              private readonly route: ActivatedRoute,
              private readonly elseverseApi: ElseverseApiService,
              public readonly web3: Web3Service,
              private readonly auth: AuthService,
              private readonly tokenApi: TokensApiService,
              private readonly positionsApi: PositionsApiService,
              private readonly usersApi: UsersApiService,
              private readonly alertService: AlertService,
              public readonly modalService: ModalService,
              private readonly realtimeService: RealtimeService,
              private ngZone: NgZone,
              @Inject(DOCUMENT) private document: any) {
  }

  public get isConnected(): boolean { return this.web3.isConnected && this.auth.isAuthorized; }
  public get isInitialized(): boolean { return this.lands && !!this.map; }
  public get userAddress(): string { return this.web3.currentAccountValue };
  public get landsAddress(): string { return this.web3.chain.landsAddress };

  public fromWei(price: string): number {
    return new BigNumber(price).shiftedBy(-this.web3.chain.currencyDecimals).toNumber();
  }

  ngOnInit(): void {
    this.getBasePrices();

    this.elseverseApi.getGroups().subscribe(s=>{
      console.log(s);
      this.groups = s;
    });

    this.route.queryParams.subscribe(async (params) => {
      const hexagonCount = +params['hexagonCount'] || 10000;

      this.allLands = await this.elseverseApi.getLands(hexagonCount).toPromise();
      await this.applyUser();
      await this.applyLands();

      if (this.map) {
        this.mapLoaded(this.map);
      }
    });

    this.maxBounds = this.maxBoundsFloat = this.convertToLngLatBounds();

    this.subscriptions.push(
      this.realtimeService.landMintEvent$.subscribe(
        (event: LandMintEvent) => {
          if (event.userWallet.toLowerCase() === this.userAddress.toLowerCase()) {
            this.completeLandBuy(event.landId, event.tokenId);
          }
        }
      )
    );

    this.web3.currentAccount$.subscribe(async account => {
      console.log('currentAccount$', account);

      this.usersApi.getReferrerAddress(this.userAddress).subscribe(r => {
        this.referrer = r?.address || '0x0000000000000000000000000000000000000000';
          console.log('this.referrer', this.referrer);
      });

      if (this.isInitialized) {
        await this.applyUser();
        await this.applyLands();
        this.updateMap();
      }
    });
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub?.unsubscribe();
    }
  }

  async ngOnChanges() {
    if (this.isInitialized) {
      await this.applyLands();
      this.updateMap();
    }
  }

  async getBasePrices() {
    const prices = await Promise.all([
      this.web3.getLandBasePrice(0),
      this.web3.getLandBasePrice(1),
      this.web3.getLandBasePrice(2),
      this.web3.getLandBasePrice(3),
      this.web3.getLandBasePrice(4)
    ]);

    this.basePrices = prices.map(price => this.fromWei(price));
  }

  async applyUser() {
    this.positions = (await this.positionsApi.getAllPositions().toPromise())?.items;

    if (this.userAddress) {
      const chainHex = '0x' + this.web3.chain.id.toString(16);
      const nfts = await  this.tokenApi.getUsersNFTs(this.userAddress, this.landsAddress, chainHex, 100).toPromise();
      this.userNftIds = nfts?.result?.map((x: { token_id: string; }) => parseInt(x.token_id)) || [];
    }
    else {
      this.userNftIds = [];
    }

    this.allLands = this.allLands?.map(land => {
      if (land.tokenId !== null) {
        land.isOwned = this.userNftIds?.includes((land.tokenId));

        const position = this.positions?.find(x =>
          x.tokenId == land.tokenId &&
          x.tokenAddress.toLowerCase() == this.landsAddress?.toLowerCase());

        land.isOnSale = !!position;
        land.positionId = position?.id || null;
        land.isAuction = position?.isAuction || false;
        if (position) {
          land.isSold = false;
          land.isOwned = position?.ownerAddress?.toLowerCase() == this.userAddress
        }
      }

      return land;
    });
  }

  async applyLands() {
    this.lands = this.allLands?.filter((land: any) => {
      const sellType = land.isAuction ? "auction": "fixedPrice";
      if (this.selectedSellType.value !== "all" && sellType !== this.selectedSellType.value) {
        return false;
      }
      if (this.selectedBiomeType.value !== "all" && land.landType.biome !== this.selectedBiomeType.value) {
        return false;
      }
      if (this.selectedRarityType.value !== "all" && land.landType.rarity !== this.selectedRarityType.value) {
        return false;
      }
      return true;
    });
  }

  isLandActionAvailable(land: LandInfo) {
    return this.isConnected && (land.isOnSale || land.isOwned || !land.tokenId);
  }

  getLandButtonText(land: LandInfo): string {
    if (land.isOwned) {
      if (land.isOnSale) {
        return 'Stop Selling';
      }
      else {
        return 'Sale at Marketplace';
      }
    }
    else {
      if (land.isOnSale) {
        return 'Buy at Marketplace';
      }
      else if (!land.tokenId) {
        const basePrice = this.basePrices[land.rarityId].toString();
        const price = land.price ? this.fromWei(land.price).toString() : basePrice;
        const priceText = basePrice == price ? price : `<s>${basePrice}</s> ${price}`;
        return `Buy now for ${priceText} USDT`;
      }
      else {
        return null;
      }
    }
  }

  async landButtonClick(land: LandInfo) {
    if (!this.web3.chain.landsAddress) {
      await this.alertService.show('Land sale is not available on this chain');
      return;
    }

    if (!this.isConnected) {
      this.modalService.connectWallet();
      return;
    }

    if (land.isOnSale) {
      await this.router.navigate(
        [land.isAuction ? 'auction' : 'nft', this.web3.chainIdNumber, land.positionId],
        { queryParams: { landId: land.id }}
      );
    }
    else {
      if (land.isOwned) {
        await this.router.navigate(['user-page'], { queryParams: { collection: this.web3.chain.landsAddress } });
      }
      else if (!land.tokenId) {
        this.modalService.open('choose-method-modal');
      }
    }
  }

  async buyWithCrypto(land: LandInfo) {
    this.modalService.close('choose-method-modal');

    this.startLoading();

    try {
      const wallet = this.web3.currentAccountValue;

      const [landProof, whitelistProof] = await Promise.all([
        this.elseverseApi.getLandProof(land.id).toPromise(),
        this.elseverseApi.getWhitelistProof(wallet).toPromise()
      ]);

      const price = await this.web3.getLandPrice(land.centerX, land.centerY, land.rarityId, wallet, whitelistProof, this.referrer);
      await this.web3.ensureAllowance(this.web3.chain.landsAddress, this.web3.chain.currencyAddress, price);

      const result = await this.web3.buyLand(land.centerX, land.centerY, land.rarityId, landProof, whitelistProof, this.referrer);

      if (result) {
        const mints = await this.elseverseApi.mint(result.transactionHash).toPromise();
        const mint = mints.find((x: { transactionHash: any; }) =>
          x.transactionHash.toLowerCase() == result.transactionHash.toLowerCase());

        if (mint) {
          const index = this.lands.findIndex(x => x.id === land.id);
          this.lands[index].isOwned = true;
          this.lands[index].isSold = true;
          this.lands[index].tokenId = mint.tokenId;

          this.updateMap();
          await this.alertService.show('Land successfully purchased');
        }
      }
    } catch (e: any) {
      const message = e.code === 4001 ? 'Operation cancelled' : `Error: ${e.message}`;
      await this.alertService.show(message);
    } finally {
      this.stopLoading();
      this.deselect();
    }
  }

  async buyWithFiat(land: LandInfo): Promise<void> {
    this.modalService.close('choose-method-modal');

    this.startLoading();

    const wallet = this.web3.currentAccountValue;
    const chainId = this.web3.chainIdNumber;

    const [landProof, whitelistProof] = await Promise.all([
      this.elseverseApi.getLandProof(land.id).toPromise(),
      this.elseverseApi.getWhitelistProof(this.web3.currentAccountValue).toPromise()
    ]);

    const price = await this.web3.getLandPrice(land.centerX, land.centerY, land.rarityId, wallet, whitelistProof, this.referrer);
    const landPrice = this.fromWei(price);
    const convert = await this.elseverseApi.convert('USD', environment.wert.landsCommoditySign, landPrice).toPromise();
    const nativePrice = convert.body.commodity_amount;
    let now = new Date().getTime().toString();

    const sc_input_data = this.web3.encodeLandFiatBuyInput([
      wallet,
      land.centerX.toString(),
      land.centerY.toString(),
      land.rarityId.toString(),
      landProof,
      this.referrer
    ]);

    const privateKey = environment.wert.signer;

    const signedData = signSmartContractData({
      address: wallet,
      commodity: environment.wert.landsCommoditySign,
      commodity_amount: nativePrice,
      pk_id: 'key1',
      sc_address: this.web3.chain.landsAddress,
      sc_id: uuidv4(),
      sc_input_data,
    }, privateKey);
    let otherWidgetOptions: any = {
      origin: environment.wert.origin,
      partner_id:  environment.wert.partnerId,
      commodity: environment.wert.landsCommodity,
      container_id: 'widget-elseverse-wert',
      click_id: `1-${land.id}-${chainId}-${wallet.substring(2)}-${now.substring(now.length - 8)}`,
      width: 400,
      height: 600,
    };

    const options = { ...signedData, ...otherWidgetOptions }
    console.log('Payment Options', options);

    this.stopLoading();

    await this.modalService.open('elseverse-wert-modal');

    this.wertWidget = new WertWidget({
      ...options,
      listeners: {
        "error": (e: any) => console.log('Wert Error:', e),
        "payment-status": async (result: any) => {
          console.log('payment-status', result)

          if (result.status === 'pending') {
            //await this.alertService.show('Order is being processed. Do not close this page until the purchase completes.');
          }
          else if (result.status === 'success') {
            const mints = await this.elseverseApi.mint(result.tx_id).toPromise();
            const mint = mints.find((x: { transactionHash: any; }) =>
              x.transactionHash.toLowerCase() == result.tx_id.toLowerCase());

            if (mint) {
              this.completeLandBuy(land.id, mint.tokenId);
            }
            else {
              await this.alertService.show(`Mint completion error for transaction ${result.transactionHash}`);
            }

            this.deselect();
            this.updateMap();
          }
        }
      }
    });

    this.wertWidget.mount();
  }

  completeLandBuy(landId: number, tokenId: number) {
    const index = this.lands.findIndex(x => x.id === landId);

    if (index != -1 && !this.lands[index].tokenId)
    {
      this.lands[index].isOwned = true;
      this.lands[index].isSold = true;
      this.lands[index].tokenId = tokenId;

      this.alertService.show('Land successfully purchased');

      this.updateMap();
    }
  }

  mapLoaded(map: mapboxgl.Map) {
    this.map = map;
    if (!this.lands) {
      return;
    }
    map.on('mousemove', (e) => {
      document.getElementById('info').innerHTML =
// `e.point` is the x, y coordinates of the `mousemove` event
// relative to the top-left corner of the map.
        JSON.stringify(e.point) +
        '<br />' +
        // `e.lngLat` is the longitude, latitude geographical position of the event.
        JSON.stringify(e.lngLat.wrap());
    });
    this.hexagonRadius = this.lands[0].landType.hexagonRadius;

    const coordinates = this.landService.generateGeoJson(this.lands, this.hexagonRadius, false);
    this.test = coordinates;
    map.addSource(this.config.polygonLayerName, coordinates);
    const ownedLands = this.landService.generateGeoJson(this.lands.filter(l=>l.isOwned), this.hexagonRadius * 0.8, true);
    map.addSource(this.config.polygonLayerName + 'Owned', ownedLands);

    map.loadImage('assets/images/elseverse/jungle.png', (err, img) => {
      map.addImage('jungle', img, {sdf: true});
    });
    map.loadImage('assets/images/elseverse/mountain.png', (err, img) => {
      map.addImage('mountains', img, {sdf: true});
    });
    map.loadImage('assets/images/elseverse/shoreline.png', (err, img) => {
      map.addImage('shoreline', img, {sdf: true});
    });

    map.on("render", () => {
        let div = document.getElementById('land-info');
        if (div) {
          map.getContainer().appendChild(div);
        }
    })

    this.addPolygons();
    this.addIcons();
    this.addBorders();
    this.handleOnClick();

    this.handleMaxBounds();
    //this.tryAddMapImg();

    // To add hover effect
    //this.landService.addHoverEffect(map);
  }

  navigateToSelectedLand() {
    const denominator = Math.pow(10, 10);
    const coords = [this.selectedLand.centerX / denominator, this.selectedLand.centerY / denominator] as LngLatLike;
    this.map.panTo(coords);
  }

  updateMap() {
    const source = this.map.getSource(this.config.polygonLayerName) as GeoJSONSource;
    const coords = this.landService.generateGeoJson(this.lands, this.hexagonRadius, false);
    source.setData(coords.data);

    const ownedSource = this.map.getSource(this.config.polygonLayerName + 'Owned') as GeoJSONSource;
    const ownedCoords = this.landService.generateGeoJson(this.lands.filter(l=>l.isOwned), this.hexagonRadius * 0.8, true);
    ownedSource.setData(ownedCoords.data);
  }

  deselect() {
    this.map.setFeatureState(
      {source: this.config.polygonLayerName, id: this.selectedLand.id},
      {clicked: false}
    );
    this.selectedLand = null;
  }

  convertToLngLatBounds(): [number, number, number, number] {
    const bounds = environment.elseVerse.boundsFromQGis;
    return [...this.convertToGeoCoordinates(bounds[0][0], bounds[0][1]),
      ...this.convertToGeoCoordinates(bounds[1][0], bounds[1][1])];
  }

  convertToGeoCoordinates(lon: number, lat: number): [number, number] {
    const x = lon / 20037508.34 * 180;
    let y = lat / 20037508.34 * Math.PI;
    y = 360 / Math.PI * Math.atan(Math.pow(Math.exp(1), y)) - 90;
    return [x, y];
  }

  reverseConvertFromGeoCoordinates(lon: number, lat: number) {
    const x = (lon * 20037508.34) / 180;
    let y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
    y = (y * 20037508.34) / 180;
    return [x, y];
  }

  private addPolygons() {
    // Add a new layer to visualize the polygon.
    this.map.addLayer({
      'id': this.config.polygonLayerName,
      'type': 'fill',
      'minzoom': this.config.polygonsMinZoomLevel,
      'source': this.config.polygonLayerName, // reference the data source
      'layout': {},
      'paint': {
        'fill-color': ['get', 'backColor'], // blue color fill
        'fill-opacity': [
          'case',
          /*['boolean', ['feature-state', 'hover'], false],
          0.7,
          0.05,*/
          ['boolean', ['feature-state', 'clicked'], false],
          ['get', 'opacityFocus'],
          ['get', 'opacityNormal']
        ]
      }
    });
  }

  private addIcons() {
    this.map.addLayer({
      'id': 'imgs',
      'minzoom': this.config.imagesMinZoomLevel - 0.75,
      'type': 'symbol',
      'source': this.config.polygonLayerName, // reference the data source
      'layout': {
        "icon-image": ['get', 'icon'],
        'icon-size': this.config.iconsResizeLevel * this.hexagonRadius / this.config.etalonHexagonRadius,
      },
      paint: {
        'icon-opacity': [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          ['get', 'iconOpacityClicked'],
          ['get', 'iconOpacity']
        ],
        'icon-color': [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          ['get', 'iconColorClicked'],
          ['get', 'iconColor']
        ]
      }
    });
  }

  private addBorders() {
    this.map.addLayer({
      'id': 'outline',
      'type': 'line',
      'source': this.config.polygonLayerName,
      'layout': {},
      'paint': {
        'line-color': ['get', 'backColor'],
        'line-width': 3,
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          1,
          0,
        ]
      }
    });

    this.map.addLayer({
      'id': 'outlineOwned',
      'type': 'line',
      'minzoom': this.config.polygonsMinZoomLevel + 1,
      'source': this.config.polygonLayerName + 'Owned',
      'layout': {},
      'paint': {
        'line-color': ['get', 'backColor'],
        'line-width': 3,
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          1,
          0.8,
        ]
      }
    });
  }

  private handleOnClick() {
    this.map.on('click', this.config.polygonLayerName, (e) => {
      this.selectLand(<number>e.features[0].id);
    });

    /*this.map.on('click', (e) => {
      if (!this.lands)
        return;
      let closestLand = this.lands[0];
      //let
      for (let land of this.lands) {
        if (Math.abs(e.lngLat.lng - land.centerX) / 2 < this.config.hexagonRaduis
          && Math.abs(e.lngLat.lat - land.centerY) / 2 < this.config.hexagonRaduis) {
          console.log(land, e.lngLat.distanceTo(LngLat.convert([land.centerX, land.centerY])));
          console.log(Math.abs(e.lngLat.lng - land.centerX) / 2,
            Math.abs(e.lngLat.lat - land.centerY) / 2);
          //this.selectLand(land.id);
          return;
        }
      }
    });*/
  }

  public selectLand(landId: number, navigateToSelected: boolean = false) {
    this.ngZone.run(() => {
      if (this.selectedLand && this.selectedLand.id !== landId) {
        this.map.setFeatureState(
          {source: this.config.polygonLayerName, id: this.selectedLand.id},
          {clicked: false}
        );
      }
      this.selectedLand = this.lands.filter(l => l.id == landId)[0];

      console.log('this.selectedLand', this.selectedLand);

      this.map.setFeatureState(
        {source: this.config.polygonLayerName, id: this.selectedLand.id},
        {clicked: true}
      );

      if (this.isConnected) {
        this.elseverseApi.getWhitelistProof(this.userAddress).subscribe(whitelistProof => {
          this.web3.getLandPrice(this.selectedLand.centerX, this.selectedLand.centerY, this.selectedLand.rarityId, this.userAddress, whitelistProof, this.referrer)
            .then((value) => {
              if (this.selectedLand) {
                this.selectedLand.price = value;
              }
            });
        });
      }

      if (navigateToSelected) {
        this.navigateToSelectedLand();
      }
    })
  }

  private handleMaxBounds() {
    // X; Y
    const zoom8DiffPerPixel = [0.0027506, 0.0025772];
    const zoom14DiffPerPixel = [0.000042917, 0.00004];

    let oldZoomLevel = 0;
    this.map.on('zoom', () => {
      const currentZoom = this.map.getZoom();
      if (oldZoomLevel == currentZoom){
        return;
      }
      oldZoomLevel = currentZoom;

      const currDiffX = zoom14DiffPerPixel[0] - (14 - currentZoom) / 6 * (zoom14DiffPerPixel[0] - zoom8DiffPerPixel[0]);
      const currDiffY = zoom14DiffPerPixel[1] - (14 - currentZoom) / 6 * (zoom14DiffPerPixel[1] - zoom8DiffPerPixel[1]);

      const targetWidth = window.innerWidth / 2;
      const targetHeight = window.innerHeight * 0.9 / 2;

      const multiplier = 1 + 0.075 * (currentZoom - 8) * (currentZoom - 14); // quadratic function to reflect different zoom levels on map

      const finalOffsetX = targetWidth * currDiffX * multiplier;
      const finalOffsetY = targetHeight * currDiffY * multiplier;

      this.maxBoundsFloat = [
        this.maxBounds[0] + finalOffsetX,
        this.maxBounds[1] + finalOffsetY,
        this.maxBounds[2] - finalOffsetX,
        this.maxBounds[3] - finalOffsetY
      ];

      /*console.log('currentZoom', currentZoom , 'init bounds', this.maxBounds, 'calc bounds',  this.maxBoundsFloat,
        'finalOffsetX', finalOffsetX, 'finalOffsetY', finalOffsetY);*/
      this.map.setMaxBounds(new LngLatBounds(this.maxBoundsFloat));
    });

     // [-3600000, 1900000], // Nothern east
   //   [-2700000, 2500000] // south west
  }

  focusChange(focusIn: boolean) {
    this.zoomable = focusIn;
  }

  private startLoading() {
    if (!this.isLoading) {
      this.document.body.classList.add('wait_openid');
    }
    this.isLoading = true;
  }

  private stopLoading() {
    if (this.isLoading) {
      this.document.body.classList.remove('wait_openid');
    }
    this.isLoading = false;
  }

  changeGroup(groupId: number) {
    this.elseverseApi.getLands(10000, groupId).subscribe(r => {
      this.lands = r;
      console.log(this.lands);
      if (this.map) {
        this.map.removeLayer(this.config.polygonLayerName);
        this.map.removeLayer('imgs');
        this.map.removeLayer('imgs-far');
        this.map.removeLayer('outline');
        this.map.removeLayer('outlineOwned');
        this.map.removeSource(this.config.polygonLayerName + 'Owned');
        this.map.removeSource(this.config.polygonLayerName);
        this.mapLoaded(this.map);
      }
    });
  }
}
