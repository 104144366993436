import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'networkImg'
})
export class NetworkImgPipe implements PipeTransform {
  transform(chainId: number, isBlack: boolean = false): string {
    if (chainId == 56 || chainId == 97) {
      return 'assets/images/network/bsc.svg';
    }
    if (chainId == 1 || chainId == 42 || chainId == 4 || chainId == 5) {
      return 'assets/images/network/eth.svg';
    }
    if (chainId == 137 || chainId == 80001) {
      return 'assets/images/network/polygon.svg';
    }
    if (chainId == -100 || chainId == -9999) {
      return 'assets/images/network/solana.svg';
    }
    if (chainId == -200) {
      return 'assets/images/network/tezos.svg';
    }
    if (chainId == 256 || chainId == 128) {
      return 'assets/svg/heco.svg';
    }
    return 'assets/images/no-image.svg';
  }
}
