import { Pipe, PipeTransform } from "@angular/core";
import { BigNumber } from "bignumber.js";
@Pipe({
    name: "shiftDecimals"
})
export class ShiftDecimals implements PipeTransform {
    transform(value: string, shiftedBy: number): number {
        if (value) {
            return (new BigNumber(value).shiftedBy(shiftedBy)).toNumber();
        }
        return 0;
    }
}
