import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AuthModel } from '../models/auth.model';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { AuthApiService } from './api/auth-api.service';
import { environment } from "../../environments/environment";
import { ChainType } from "../enums/chain-type";

@Injectable({ providedIn: 'root' })
export class AuthService {
    public token: string;
    public activeUserAddress: string;

    private readonly chainIdKey: string = "chainId";
    private readonly walletNameKey: string = "walletName";
    private readonly linkedWalletKey: string = "linkedWallet";
    private jwt: JwtHelperService;
    private storage: Storage = localStorage;

    constructor(private readonly api: AuthApiService, private readonly router: Router) {
        this.jwt = new JwtHelperService();
        let token = localStorage.getItem('token');
        if (token && !this.isTokenExpired(token)) {
            this.token = token;
        }
        else this.logout();
    }

    public authenticate(model: AuthModel): Promise<any> {
        return this.api.authenticate(model).pipe(
            tap((result: any) => {
                localStorage.setItem('token', result.accessToken);
                this.token = result.accessToken;
            }),
        ).toPromise();
    }

    public get isAuthorized(): boolean {
        return this.token && !this.jwt.isTokenExpired(this.token) || false;
    }

    public isTokenExpired(token:string): boolean {
        return this.jwt.isTokenExpired(token);
    }

    public getChainId(): number {
      const chainValue = this.storage.getItem(this.chainIdKey);
      if (chainValue)
        return parseInt(chainValue);
      return environment.defaultChainId;
    }

    public setChainId(chainId: number): void {
      this.storage.setItem(this.chainIdKey, chainId.toString());
    }

    public getChainType(chainId: number = null): ChainType {
      const id = chainId ? chainId : this.getChainId();
      return id > 0 ? ChainType.EVM : ChainType.Solana;
    }

    public logout(): void {
        console.log('logout');
        localStorage.removeItem('token');
    }
}
