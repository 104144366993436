import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Web3Service } from 'src/app/services/web3.service';
import {PositionsApiService} from "../../services/api/positions-api.service";

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent {
    public currentAccount: Observable<string> = this.web3.currentAccount$;

    constructor(private readonly web3: Web3Service, private readonly api: PositionsApiService){
      this.api.getOwnPositions(this.web3.currentAccountValue).subscribe(i=>{
        console.log('getCollection', i);
      });
    }

    public getMyNFT():void{
        //this.web3.getUsersNFTs("0xfb6fd2bec388d522a70b144e440b1856b2999671", ["0x6c9ef421b37392b8fdf19e877588181aa551e87d"]);
    }

    public cancelSale(): void{
      this.web3.cancelSale(5);
    }

}
