<jw-modal id="connect-wallet" [permanent]="true">
  <div class="wallet-labels">
    <img src="assets/images/Savings.png" />
    <h3>Connect your wallet</h3>
    <button class="btn-close" (click)="modalService.close('connect-wallet')"></button>
  </div>
  <div class="wallet-btn">
    <button (click)="connectWallet()">
      <img src="assets/images/wallet-black.png" />
      Connect Wallet</button>
  </div>
</jw-modal>
