import {Component, Input, OnInit} from '@angular/core';
import {interval, Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: '[timeLeft]',
  templateUrl: './time-left.component.html',
  styleUrls: ['./time-left.component.scss']
})
export class TimeLeftComponent {
  public isTimer: boolean = true;
  public ended: boolean = false;
  public isStarted: boolean = true;

  @Input()
  endDate: number;

  @Input()
  startDate?: number;

  @Input()
  textIfEnded: string;

  @Input()
  bottomMargin?: number;
  @Input()
  topMargin?: number;
  @Input()
  leftMargin?: number;

  public get date$(): Observable<number> {
    return interval(500).pipe(
      startWith(this.endDate),
      map(() => {
        this.startDate = this.fixFormat(this.startDate);
        this.endDate = this.fixFormat(this.endDate);

        const now = new Date().getTime();
        if (this.startDate && this.startDate * 1000 > now) {
          this.isStarted = false;
          return this.startDate;
        }
        this.isStarted = true;
        if (this.endDate && now > this.endDate * 1000)
          this.ended = true;
        return this.endDate;
      }));
  }

  private fixFormat(date: any): number {
    if (date && typeof date == 'string') {
      return new Date(date).getTime() / 1000;
    }
    return date;
  }

  switchMode($event: any) {
    this.isTimer = !this.isTimer;
    $event.stopPropagation();
    if (!this.isTimer) {
      const now = new Date().getTime();
      if (this.startDate && !this.isStarted) {
        setTimeout(() => {
          this.isStarted = true;
        }, this.startDate * 1000 - now);
      }
      if (this.endDate && !this.ended) {
        setTimeout(() => {
          this.ended = true;
        }, this.endDate * 1000 - now);
      }
    }
  }

  getStyle() {
    let styles: any = {};
    if (this.bottomMargin) {
      styles.bottom = this.bottomMargin + 'px';
    } else if (this.topMargin) {
      styles.top = this.topMargin + 'px';
    } else {
      styles.bottom = '30px';
    }

    styles.left = this.topMargin ? this.leftMargin + 'px' : '30px';

    return styles;
  }
}
