import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from 'rxjs';
import {BaseApiService} from "./base-api.service";
import {LandInfo, LandType} from "../../components/elseverse/landsale-map/land-info";
import {map, share, switchMap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ElseverseApiService extends BaseApiService {

  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient);
  }

  // count will not affect request once real implementation will be done
  public getLands(totalNeeded: number = 100000, groupId: number = null): Observable<LandInfo[]> {
    const gr = groupId === 0 ? 0 : (groupId || '');
    return this.getElseVerseRequest<LandType[]>(`lands/types?groupId=${gr}`)
      .pipe(
        share(),
        switchMap(types => {
          return this.getElseVerseRequest<LandInfo[]>(`lands?totalNeeded=${totalNeeded}&groupId=${gr}`)
            .pipe(
              map((l:LandInfo[]) => {
                const set: any = {};
                for (let type of types) {
                  set[type.id] = type;
                }

                for (let land of l) {
                  land.landType = set[land.landTypeId];
                  land.isSold = land.tokenId != null;
                }

                return l;
              })
            )
        })
      );
  }

  public getLand(id: number): Observable<LandInfo> {
    return this.getElseVerseRequest<LandInfo>(`lands/${id}`);
  }

  public getWhitelistProof(address: string): Observable<any> {
    return this.getElseVerseRequest<LandInfo>(`merkle/proof/discount/${address}`);
  }

  public getLandProof(id: number): Observable<any> {
    return this.getElseVerseRequest<LandInfo>(`merkle/proof/land/${id}`);
  }

  public getGroups(): Observable<any[]> {
    return this.getElseVerseRequest<any[]>(`lands/get_groups`);
  }

  public getWhitelisted(address: string): Observable<boolean> {
    return this.getElseVerseRequest<boolean>(`nft/whitelisted/${address}`);
  }

  public mint(transactionHash: string): Observable<any> {
    return this.postElseVerseRequest<any>(`lands/mint`, { transactionHash: transactionHash });
  }

  public convert(from: string, to: string, amount: number): Observable<any> {
    return this.postWertRequest('api/v3/partners/convert', { from, to, amount });
  }
}
