import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INftInfo } from '../../../models/nft-info.interface';


@Component({
  selector: 'land-card',
  templateUrl: './land-card.component.html',
  styleUrls: ['./land-card.component.scss'],
})
export class LandCardComponent {

  @Input()
  public nftInfo: INftInfo | any;

  @Output()
  public clickOnCard: EventEmitter<void> = new EventEmitter<void>();


  constructor() {}


  public openNft(): void {
    this.clickOnCard.emit();
  }

}
