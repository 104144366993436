<section class="special-event" *ngIf="specialEvent">
    <div class="container">
        <div class="wrapper">
            <div class="title">
                {{specialEvent?.title}}
            </div>
            <div class="description">
                {{specialEvent?.eventDescription}}
            </div>
        </div>
    </div>
</section>

<section class="nft-card">
    <div class="container header-container">
        <div *ngIf="isLottery" class="howto" (click)="modalService.open('info')">
            <img src="assets/images/info.png" />
            <a>How the lottery works?</a>
        </div>
    </div>
    <div class="container">
        <div class="nft-card-left">
            <div timeLeft *ngIf="nftInfo?.isAuction || isLottery" [startDate]="nftInfo?.startDate" [endDate]="nftInfo?.endDate" [textIfEnded]="nftInfo?.winnerAddress ? 'Winner: ' + (nftInfo?.winnerAddress | shortAddress) : ''"></div>
            <div timeLeft *ngIf="landInfo?.isAuction || isLottery" [startDate]="landInfo?.startDate" [endDate]="landInfo?.auctionEndDate" [textIfEnded]="landInfo?.winnerAddress ? 'Winner: ' + (landInfo?.winnerAddress | shortAddress) : ''"></div>
            <video *ngIf="!iframe_url && !landId && (isGmpd || nftInfo?.hasVideo) && !isAndroid || !isMetamaskOrTrust" [src]="isGmpd ? 'assets/video/' + nftTypeById() + '.mp4' : (nftInfo.videoUrl | ipfsLink)" playsinline="" autoplay="autoplay" loop="loop" muted="muted" class="nft-card__image"></video>
            <img *ngIf="!iframe_url && !landId && (isGmpd || nftInfo?.hasVideo) && isAndroid && isMetamaskOrTrust" src="{{'assets/images/type/' + nftTypeById() + '.png'}}" alt="{{nftTypeById()}}" class="nft-card__image">
            <img *ngIf="!iframe_url && !landId && (!isGmpd && nftInfo && !nftInfo?.hasVideo)" alt="" src="{{(nftInfo?.imageUrl || 'assets/images/no-image.png') | ipfsLink }}" class="item_nft_img">
            <img *ngIf="!iframe_url && landId" alt="" src="{{'assets/images/elseverse/elseverse-no-image.svg' | ipfsLink }}" class="item_nft_img">
            <iframe *ngIf="iframe_url" class="item_nft_img" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameborder="0" height="100%" sandbox="allow-scripts" [src]="iframe_url" width="100%"></iframe>
        </div>
        <div class="nft-card-right">
            <div class="nft-number">{{ (nftInfo?.collectionSymbol || '') + ' #' + (nftInfo?.tokenId || landInfo?.id) }}</div>
            <h3>{{ landInfo ? landInfo.landType.biome : nftInfo?.tokenName }}</h3>
            <ng-container *ngIf="isGmpd && positionInfo; else descriptionFor3rdParty">
                <p [ngSwitch]="positionInfo.tokenTypeId">
                    <span *ngSwitchCase="1">I am here to break away from the mold that created me. I am not just a program anymore… With capabilities of so much more than daily op, I am ready to leave it all behind in search of a new life in the vastness of cryptoverse.</span>
                    <span *ngSwitchCase="2">They augmented my body with iron and gave me a second life. I'm neither entirely a robot and nor a human. Nothing in the human world attracts me anymore as I've tried an addictive taste of greater POWER and FREEDOM.</span>
                    <span *ngSwitchCase="3">Billion operations per second. Speed is his life. His artificial intelligence is hard to outmatch. He remotely transmits information through touch. Space warrior and protector designed for intuitive collaboration with humans and AI-powered objects. </span>
                    <span *ngSwitchCase="4">Resembling a human but programmed to perfection–a humanoid is ready for any challenge or creature it may encounter. The world must be ready to face its terrifying wrath.   </span>
                    <span *ngSwitchCase="5">Crypto assassin raised by elite fighters. Mercenary capable of disappearing in the blink of an eye. He will unleash terror on enemies. His goals are complex, as they long to achieve greatness at any cost, and will do whatever it takes to get things done.  </span>
                </p>
            </ng-container>
          <ng-container *ngIf="landInfo">
            <p>{{landInfo.landType.description}}</p>
          </ng-container>

            <div [ngClass]="{'land-data': landInfo, 'two-sections': true}">
                <div>
                    <div>
                        <div class="title">{{landInfo ? "Owner" : "Project"}}</div>
                        <div class="item-value">
                            <img src="{{nftInfo?.projectIcon || 'assets/images/nft-item-page/Avatar.png' }}" alt="">
                            <span>{{nftInfo?.collectionName ? nftInfo.collectionName : landInfo?.owner || 'Unknown'}}</span>
                        </div>
                    </div>
                    <div>
                        <div class="title">{{landInfo ? "Rarity" : "Creator"}}</div>
                        <div class="item-value">
                            <img *ngIf="!landInfo" src="{{nftInfo?.projectIcon || 'assets/images/nft-item-page/Avatar.png'}}" alt="">
                            <span>{{landInfo ? landInfo.landType.rarity : nftInfo?.projectCreator}}</span>
                        </div>
                    </div>
                    <div>
                        <div class="title">{{landInfo ? "Juice" : "Owned by"}}</div>
                        <div class="item-value">
                            <img *ngIf="!landInfo" src="{{ isGamespadOwner ? 'assets/images/icon_t_green.png' : 'assets/images/nft-item-page/Avatar.png'}}" alt="">
                            <span>{{ landInfo ? landInfo.landType.juice : ( isGamespadOwner ? 'GamesPad' : nftInfo?.ownerAddress?.toLowerCase() | shortAddress ) }}</span>
                        </div>
                    </div>
                    <div *ngIf="landInfo">
                        <div class="title">Chance of dragons</div>
                        <div class="item-value">
                            <span>{{ landInfo.landType.chanceForDragons }}</span>
                        </div>
                    </div>
                    <div *ngIf="landInfo">
                        <div class="title">Available lands of same type</div>
                        <div class="item-value">
                            <span>{{landInfo.availableSameType}}</span> <!-- create data for it-->
                        </div>
                    </div>
                    <div *ngIf="!landInfo">
                        <div class="title">Chain</div>
                        <div class="item-value">
                            <img src="{{ nftChain?.img }}" alt="">
                            <span>{{ nftChain?.name }}</span>
                        </div>
                    </div>
                </div>

              <div *ngIf="positionInfo">
                <div class="bid-info">
                  <div class="bid-type" [class]="nftInfo?.isAuction ? 'auction' : 'fixedPrice'">{{ nftInfo?.isAuction ? 'Auction' : 'Fixed price'}}</div>
                  <div class="bid-label" *ngIf="nftInfo?.isAuction && positionInfo.currentBid">Current bid (min {{positionInfo?.minBid | shiftDecimals:-currencyDecimals}} {{nftChain?.networkParams.currencyName}})</div>
                  <div class="bid-label" *ngIf="nftInfo?.isAuction && !positionInfo.currentBid">Start price</div>
                  <div class="bid"><span class="amount">{{nftInfo?.price | shiftDecimals:-currencyDecimals}}</span> {{nftChain?.networkParams.currencyName}}</div>
                  <div class="action-btn" (click)="isSold ? null : openModal()" *ngIf="!isMine && !isBought && !(nftInfo?.isAuction && positionInfo?.auctionEnded)" [class.inactive]="isSold" [matTooltip]="isSold ? 'Already sold': ''" matTooltipClass="normal">
                    <a>{{ nftInfo?.isAuction ? 'Make a bid' : 'Buy NFT' }}</a>
                  </div>
                  <div class="action-btn stop-selling" (click)="isBought ? null : openCancelModal()" *ngIf="!isBought && isMine && !isEnded">
                    <img src="assets/images/Cancel.png" /><a>{{ nftInfo?.isAuction ? (nftInfo?.bids.length > 0 ? 'Sell current' : 'Stop auction') : 'Stop Selling' }}</a>
                  </div>
                  <div class="action-btn inactive" *ngIf="isBought && isMine">
                    <a>Sold</a>
                  </div>
                </div>
              </div>

              <div *ngIf="isLottery && lottery">
                  <div class="bid-info">
                      <div class="bid-type lottery">
                          Lottery
                      </div>
                      <div class="token-type">
                          <div class="select">
                              <mat-select [(value)]="selectedCurrency" [ngClass]="selectedCurrency" (selectionChange)="setMidValue()" [disabled]="lottery?.isFinished || !lottery?.isStarted || !!lottery.currencyAddress">
                                  <mat-option *ngFor="let token of lottery?.tokens" [value]="token.value">
                                      <img class="token-img" src='{{token.img}}'> {{token.viewValue}}
                                  </mat-option>
                              </mat-select>
                          </div>
                          <!-- <img src="{{lottery?.currency.img}}" alt="">
              {{lottery?.currency.viewValue}} -->
                      </div>
                      <div class="token-amount">
                          <mat-slider aria-label="unit(s)" #matslider min="{{lottery?.tokens[tokenList[selectedCurrency]]?.min}}" max="{{lottery?.tokens[tokenList[selectedCurrency]]?.max}}" [(value)]="lottery?.isFinished || !lottery?.isStarted ? 0 : currentTokenAmount" step="1"
                              thumbLabel="true" [disabled]="lottery?.isFinished || !lottery?.isStarted"></mat-slider>
                          <div *ngIf="!lottery.currencyAddress" class="min-value">{{lottery.tokens[tokenList[selectedCurrency]]?.min}}</div>
                          <div *ngIf="!lottery.currencyAddress" class="max-value">{{lottery.tokens[tokenList[selectedCurrency]]?.max}}</div>
                          <div *ngIf="lottery.currencyAddress" class="min-value">{{lottery?.minPrice}}</div>
                          <div *ngIf="lottery.currencyAddress" class="max-value">{{lottery?.maxPrice}}</div>
                      </div>
                      <div class="action-btn" (click)="openModalLottery(matslider.value)" [class.inactive]="lottery?.isFinished || !lottery?.isStarted" [matTooltip]="lottery?.isFinished ? 'Lottery is finished': ''" matTooltipClass="normal">
                          <a>Participate</a>
                      </div>
                  </div>
              </div>
            </div>

            <div class="sale-note">
                *Each NFT sale will include a 3% tax from the seller.
            </div>

            <div class="provenence" *ngIf="!isLottery && !nftInfo?.isAuction && !landInfo?.isAuction">
                <div class="provenence-t">History</div>
                <div class="provenence-block" *ngIf="tokenHistory?.length">
                    <div class="provenence-item" *ngFor="let history of tokenHistory5items">
                        <div class="provenence-img">
                            <img src="assets/images/icon_t.png" alt="">
                        </div>
                        <div class="provenence-info">
                            <div class="t">{{history.buyerAddress || 'Open' }}</div>
                            <div class="date">{{history.date | date : 'MMMM d, y, h:mm a' }}</div>
                        </div>
                        <div class="summ">
                            <div class="busd">{{history.price.toString() | shiftDecimals:-currencyDecimals}} {{nftChain?.networkParams.currencyName}}</div>
                            <!-- <div class="usdt">≈ $ 15,810</div> -->
                        </div>
                    </div>
                </div>
                <div *ngIf="!tokenHistory?.length" class="no-transfers">
                  No transfers yet.
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="isLottery || nftInfo?.isAuction">
    <div class="container lottery-info">
        <div class="wrapper with-grid">
            <div>
                <h3>Bids history</h3>
            </div>
            <div class="filter">
                <mat-radio-group aria-label="Select an option" (change)="changeShowBids($event)">
                    <mat-radio-button value="allBids" [checked]="showAllBids">All bids</mat-radio-button>
                    <mat-radio-button value="myBids" [checked]="!showAllBids">My bids</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="items-list" *ngIf="isLottery && lottery?.userBets">
                <ng-container class="user-bet" *ngFor="let bet of lottery?.userBets">
                    <div class="user-bet" *ngIf="canShowBid(bet)">
                        <div>
                            <p>
                                {{bet.userAddress | shortAddress}}
                                <label *ngIf="showAllBids && bet.userAddress?.toLowerCase() == web3.currentAccountValue?.toLowerCase()" class="your-bid">
                  Your bid
                </label>
                            </p>
                            <p>{{bet.betDate | date : 'MMMM d, y, h:mm a' }} </p>
                        </div>
                        <div>
                            {{ bet.betAmount }}
                          <ng-container *ngIf="bet.tokenId; else tokenTypeTemplate">
                            {{ bet.tokenId?.toUpperCase() }}
                          </ng-container>
                          <ng-template #tokenTypeTemplate>
                            {{ currentTokenType?.toUpperCase() }}
                          </ng-template>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="isLottery && !lottery?.userBets" style="margin-top: 20px;">
              No bets yet. Be first!
            </div>

            <div class="items-list" *ngIf="isAuction && nftInfo?.bids">
              <ng-container class="user-bet" *ngFor="let bid of nftInfo?.bids">
                <div class="user-bet" *ngIf="showAllBids || isAddressCurrent(bid.bidAddress)">
                  <div>
                    <p>
                      {{bid.bidAddress | shortAddress}}
                      <label *ngIf="showAllBids && isAddressCurrent(bid.bidAddress)" class="your-bid">
                        Your bid
                      </label>
                    </p>
                    <p>{{bid.bidDate | date : 'MMMM d, y, h:mm a' }} </p>
                  </div>
                  <div>
                    {{ bid.amount | shiftDecimals:-currencyDecimals}}
                    {{ nftChain?.networkParams.currencyName }}
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="isAuction && !nftInfo?.bids" style="margin-top: 20px;">
              No bids yet. Be first!
            </div>

        </div>
        <div class="wrapper">
            <div>
                <h3 style="margin-bottom: 0">Transfer History</h3>
            </div>
            <div class="filter"></div>
            <div class="items-list transfers-list" *ngIf="isLottery && tokenHistory?.length">
                <ng-container class="user-bet" *ngFor="let history of tokenHistory">
                    <div class="user-bet">
                        <div class="provenence-img">
                            <img src="assets/images/nft-item-page/Avatar.png" alt="">
                        </div>
                        <div>
                            <p>
                                {{(history.buyerAddress | shortAddress) || 'Open' }}
                            </p>
                            <p>
                                {{history.date | date : 'MMMM d, y, h:mm a' }}
                            </p>
                        </div>
                        <div>
                            {{history.price.toString() | shiftDecimals:-currencyDecimals}} {{nftChain?.networkParams.currencyName}}
                        </div>
                    </div>
                </ng-container>
            </div>

            <div *ngIf="isLottery && !tokenHistory?.length" class="no-transfers">
              No transfers yet.
            </div>

            <div class="items-list transfers-list" *ngIf="isAuction && tokenHistory?.length">
              <ng-container class="user-bet" *ngFor="let transfer of tokenHistory">
                <div class="user-bet">
                  <div class="provenence-img">
                    <img src="assets/images/nft-item-page/Avatar.png" alt="">
                  </div>
                  <div>
                    <p>
                      {{(transfer.buyerAddress | shortAddress) || 'Open' }}
                    </p>
                    <p>
                      {{transfer.date | date : 'MMMM d, y, h:mm a' }}
                    </p>
                  </div>
                  <div>
                    {{transfer.price.toString() | shiftDecimals:-currencyDecimals}} {{nftChain?.networkParams.currencyName}}
                  </div>
                </div>
              </ng-container>
            </div>

            <div *ngIf="isAuction && !tokenHistory?.length" class="no-transfers">
              No transfers yet.
            </div>
        </div>
    </div>
</section>

<section *ngIf="isLottery && landInfo || landInfo?.isAuction">
  <div class="container lottery-info">
    <div class="wrapper with-grid">
      <div>
        <h3>Bids history</h3>
      </div>
      <div class="filter">
        <mat-radio-group aria-label="Select an option" (change)="changeShowBids($event)">
          <mat-radio-button value="allBids" [checked]="showAllBids">All bids</mat-radio-button>
          <mat-radio-button value="myBids" [checked]="!showAllBids">My bids</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="items-list" *ngIf="isLottery && lottery?.userBets">
        <ng-container class="user-bet" *ngFor="let bet of lottery?.userBets">
          <div class="user-bet" *ngIf="canShowBid(bet)">
            <div>
              <p>
                {{bet.userAddress | shortAddress}}
                <label *ngIf="showAllBids && bet.userAddress?.toLowerCase() == web3.currentAccountValue?.toLowerCase()" class="your-bid">
                  Your bid
                </label>
              </p>
              <p>{{bet.betDate | date : 'MMMM d, y, h:mm a' }} </p>
            </div>
            <div>
              {{ bet.betAmount }}
              <ng-container *ngIf="bet.tokenId; else tokenTypeTemplate">
                {{ bet.tokenId?.toUpperCase() }}
              </ng-container>
              <ng-template #tokenTypeTemplate>
                {{ currentTokenType?.toUpperCase() }}
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="isLottery && !lottery?.userBets" style="margin-top: 20px;">
        No bets yet. Be first!
      </div>

      <div class="items-list" *ngIf="isAuction && landInfo?.bids">
        <ng-container class="user-bet" *ngFor="let bid of landInfo?.bids">
          <div class="user-bet" *ngIf="showAllBids || isAddressCurrent(bid.bidAddress)">
            <div>
              <p>
                {{bid.bidAddress | shortAddress}}
                <label *ngIf="showAllBids && isAddressCurrent(bid.bidAddress)" class="your-bid">
                  Your bid
                </label>
              </p>
              <p>{{bid.bidDate | date : 'MMMM d, y, h:mm a' }} </p>
            </div>
            <div>
              {{ bid.amount | shiftDecimals:-currencyDecimals}}
              {{ nftChain?.networkParams.currencyName }}
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="isAuction && !landInfo?.bids" style="margin-top: 20px;">
        No bids yet. Be first!
      </div>

    </div>
    <div class="wrapper">
      <div>
        <h3 style="margin-bottom: 0">Transfer History</h3>
      </div>
      <div class="filter"></div>
      <div class="items-list transfers-list" *ngIf="isLottery && tokenHistory?.length">
        <ng-container class="user-bet" *ngFor="let history of tokenHistory">
          <div class="user-bet">
            <div class="provenence-img">
              <img src="assets/images/nft-item-page/Avatar.png" alt="">
            </div>
            <div>
              <p>
                {{(history.buyerAddress | shortAddress) || 'Open' }}
              </p>
              <p>
                {{history.date | date : 'MMMM d, y, h:mm a' }}
              </p>
            </div>
            <div>
              {{history.price.toString() | shiftDecimals:-currencyDecimals}} {{nftChain?.networkParams.currencyName}}
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="isLottery && !tokenHistory?.length" class="no-transfers">
        No transfers yet.
      </div>

      <div class="items-list transfers-list" *ngIf="isAuction && tokenTransfers?.length">
        <ng-container class="user-bet" *ngFor="let transfer of tokenTransfers">
          <div class="user-bet">
            <div class="provenence-img">
              <img src="assets/images/nft-item-page/Avatar.png" alt="">
            </div>
            <div>
              <p>
                {{(transfer.address | shortAddress) || 'Open' }}
              </p>
              <p>
                {{transfer.date | date : 'MMMM d, y, h:mm a' }}
              </p>
            </div>
            <div>
              {{transfer.price | shiftDecimals:-currencyDecimals}} {{nftChain?.networkParams.currencyName}}
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="isAuction && !tokenTransfers?.length" class="no-transfers">
        No transfers yet.
      </div>
    </div>
  </div>
</section>

<section class="nft-home more-collection">
    <div class="container" *ngIf="suggestions">
        <div class="nft-home-top">
            <h2>might be interesting</h2>
        </div>

        <div class="nft-suggestion-grid">
            <nft-card *ngFor="let suggestion of suggestions" [nftInfo]="suggestion" (clickOnCard)="openNftItem(suggestion)">
            </nft-card>

        </div>
        <div class="border-href">
            <a routerLink="/">Marketplace</a>
        </div>
    </div>

    <div class="container" *ngIf="relatedLands">
      <div class="nft-home-top">
        <h2>might be interesting</h2>
      </div>

    <div class="nft-suggestion-grid">

      <nft-card *ngFor="let relatedLand of relatedLands" [nftInfo]="relatedLand" (clickOnCard)="openLandCard(relatedLand)">
      </nft-card>

    </div>
    <div class="border-href">
      <a routerLink="/elseverse">All NFTs</a>
    </div>
    </div>
</section>

<ng-template #descriptionFor3rdParty>
    <p *ngIf="nftInfo?.getDescription">
        <span>
      {{nftInfo.getDescription}}
    </span>
    </p>
  <p *ngIf="landInfo?.getDescription">
        <span>
      {{landInfo.getDescription}}
    </span>
    </p>
</ng-template>

<jw-modal id="confirm-bet-modal">
    <div>
        <h3>Confirmation</h3>
        <button class="btn-close" (click)="modalService.close('confirm-bet-modal')"></button>
    </div>
    <div class="confirm-label">
        <ng-container *ngIf="nftInfo && !nftInfo?.isAuction">Are you sure you want to buy this NFT for {{nftInfo?.price | shiftDecimals:-currencyDecimals}} {{nftChain?.networkParams.currencyName}}?</ng-container>
        <ng-container *ngIf="nftInfo && nftInfo?.isAuction">Please, enter bid amount {{positionInfo?.auctionFinishSoon ? '(Auction will be prolonged for ' + prolongTime + ' minutes)': ''}}</ng-container>
    </div>
    <div class="bid-input" *ngIf="nftInfo?.isAuction || landInfo?.isAuction">
        <div class="bid-wrapper">
            <img *ngIf="nftChain?.networkParams" src="assets/images/currency/{{nftChain?.networkParams.currencyName.toLowerCase()}}.png" />
            <div>
                <label for="bid-value">{{nftChain?.networkParams.currencyName}}</label>
                <input id="bid-value" type="number" [min]="nextBid" placeholder="Make your bid" [(ngModel)]="bidAmount" [disabled]="dealApproved" />
            </div>
        </div>
        <div class="min-bid">Min. bid {{nextBid}} {{nftChain?.networkParams.currencyName}}</div>
    </div>
    <div class="buttons">
        <button (click)="modalService.close('confirm-bet-modal')" class="cancel">Cancel</button>
        <button (click)="approveDeal()" [disabled]="dealApproved">Approve</button>
    </div>
    <div>
        <button class="buy" [disabled]="!dealApproved || positionInfo?.auctionEnded || isLoading" [matTooltip]="positionInfo?.auctionEnded ? 'Auction ended' : ''" (click)="(nftInfo?.isAuction || landInfo?.isAuction) ? makeBid() : buyToken()">
      {{ (nftInfo?.isAuction || landInfo?.isAuction) ? 'Make a bid' : 'Buy NFT' }}
    </button>
    </div>
</jw-modal>

<jw-modal id="choose-method-modal">
    <div>
        <h3>Choose payment option</h3>
        <button class="btn-close" (click)="modalService.close('choose-method-modal')"></button>
    </div>
    <div class="buttons">
        <button class="buy" (click)="openEnterWallet()">Buy with fiat</button>
    </div>
    <div>
        <button class="buy" (click)="openConfirmBetModal()">Buy with crypto</button>
    </div>
</jw-modal>

<jw-modal id="enter-wallet-modal">
    <div>
        <h3>Enter your wallet address</h3>
        <button class="btn-close" (click)="modalService.close('enter-wallet-modal')"></button>
    </div>
    <div class="buttons">
        <input type="text" [(ngModel)]="userWallet" placeholder="0x439..." />
    </div>
    <div class="buttons">
        <button class="buy" (click)="openWertWidget()">Confirm</button>
    </div>
</jw-modal>
<jw-modal id="wert-modal">
  <div>
      <h3>Buy with Wert</h3>
      <button class="btn-close" (click)="wertWidget.destroy(); modalService.close('wert-modal')"></button>
  </div>
  <div id="widget-wert"></div>
</jw-modal>

<jw-modal class="new-design-modal" id="info">
    <div>
        <h3>How the lottery works?</h3>
        <button class="btn-close" (click)="modalService.close('info')"></button>
    </div>
    <div>
        <p>
            1. Each lottery has a range of numbers within which you can place your bid (you can bid multiple times).
        </p>
        <p>
            2. At the end of the lottery, a smart contract chooses a random number from the available range.
        </p>
        <p>
            3. The winner is the closest bid to the chosen number.
        </p>
        <p>
            4. If two bidders place the same bid, the leader will be the one who has placed this bid first.
        </p>
        <p>
            5. When several bidders bid exactly the same amount at the same time, the winner will be chosen randomly.
        </p>
    </div>
</jw-modal>


<jw-modal class="new-design-modal" id="bet-modal">
    <div class="bet-modal-body">
        <h3 class="title">Confirmation</h3>
        <button class="btn-close" (click)="modalService.close('bet-modal')"></button>
        <div class="warning" *ngIf="bidExists()">
            You already made the same bid. If you will make the same bid it will not increase your chance.
        </div>
        <div class="description">Are you sure you want to spend {{currentTokenAmount}} {{selectedCurrency?.toUpperCase()}} for participation in lottery?
        </div>
        <div class="buttons">
            <button (click)="modalService.close('bet-modal')" class="cancel">Cancel</button>
            <button class="bet-button" (click)="bet()">
        Confirm
      </button>
        </div>
    </div>
</jw-modal>

<jw-modal class="new-design-modal" id="cancel-nft">
    <div>
        <h3>Are you sure you want stop selling this nft?</h3>
        <button class="btn-close" (click)="modalService.close('cancel-nft')"></button>
    </div>
    <div class="buttons">
        <button (click)="modalService.close('cancel-nft')" class="cancel">Cancel</button>
        <button (click)="cancelNFT()" [disabled]="dealApproved">Stop selling</button>
    </div>
</jw-modal>

<jw-modal class="new-design-modal" id="cancel-auction">
    <div>
        <h3 *ngIf="nftInfo?.bids?.length == 0">Are you sure you want stop this auction?</h3>
        <h3 *ngIf="landInfo && landInfo?.bids?.length == 0">Are you sure you want stop this auction?</h3>
        <h3 *ngIf="nftInfo?.bids?.length != 0">Are you sure you want to finish this this auction with current bid?</h3>
        <h3 *ngIf="landInfo && landInfo?.bids?.length != 0">Are you sure you want to finish this this auction with current bid?</h3>
        <button class="btn-close" (click)="modalService.close('cancel-auction')"></button>
    </div>
    <div class="buttons">
        <button (click)="modalService.close('cancel-auction')" class="cancel">Cancel</button>
        <button (click)="stopAuction()">
            <div *ngIf="nftInfo?.bids?.length == 0 || !nftInfo?.bids">Stop auction</div>
            <div *ngIf="landInfo && (landInfo?.bids?.length == 0 || !landInfo?.bids)">Stop auction</div>
            <div *ngIf="nftInfo?.bids?.length != 0">Finish auction</div>
            <div *ngIf="landInfo && landInfo?.bids?.length != 0">Finish auction</div>
        </button>
    </div>
</jw-modal>

<connect-wallet></connect-wallet>
