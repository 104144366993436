<div [ngClass]="{'legendary': nftInfo.landType.rarity === 'legendary'}" class="land-card" (click)="openNft()">
  <img src="assets/images/elseverse/land-card/dragon-{{nftInfo.landType.rarity}}.png" alt="dragon-{{nftInfo.landType.rarity}}" class="dragon">
  <img src="assets/images/elseverse/land-card/{{nftInfo.landType.biome}}.png" alt="{{nftInfo.landType.biome}}" class="biome-type">

  <img src="assets/images/elseverse/land-card/bg-{{nftInfo.landType.rarity}}.png" alt="bg-{{nftInfo.landType.rarity}}" class="card-bg">

  <img src="assets/images/elseverse/land-card/land-{{nftInfo.landType.rarity}}.png" alt="land-{{nftInfo.landType.rarity}}" class="land">

  <div class="content-wrapper">
    <div class="content-text-wrapper">
      <p [ngClass]="{'common': nftInfo.landType.rarity === 'common',
         'uncommon': nftInfo.landType.rarity === 'uncommon',
         'rare': nftInfo.landType.rarity === 'rare',
         'legendary': nftInfo.landType.rarity === 'legendary',
         'epic': nftInfo.landType.rarity === 'epic',
         'biome-type-text': true}">{{nftInfo.landType.biome === 'mountains' ? 'Mountain' : nftInfo.landType.biome === 'jungle' ? 'Jungle' : 'Shoreline'}} Land</p>
      <p class="card-info-text">{{nftInfo.landType.description}}</p>
    </div>

    <div class="land-items-wrapper">
      <div class="land-item">
        <img src="assets/images/elseverse/land-card/gem.png" alt="gem" class="item">
        <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
      </div>
      <div class="land-item">
        <img src="assets/images/elseverse/land-card/stone.png" alt="stone" class="item">
        <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
      </div>
      <div class="land-item">
        <img src="assets/images/elseverse/land-card/wood.png" alt="wood" class="item">
        <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
      </div>
      <div class="land-item">
        <img src="assets/images/elseverse/land-card/juice.png" alt="juice" class="item">
        <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
      </div>
      <div class="land-item">
        <img src="assets/images/elseverse/land-card/fruit.png" alt="fruit" class="item">
        <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
      </div>
    </div>
  </div>

  <p [ngClass]="{'common': nftInfo.landType.rarity === 'common',
         'uncommon': nftInfo.landType.rarity === 'uncommon',
         'rare': nftInfo.landType.rarity === 'rare',
         'legendary': nftInfo.landType.rarity === 'legendary',
         'epic': nftInfo.landType.rarity === 'epic',
         'land-type-text': true}">{{nftInfo.landType.rarity}}</p>

</div>
