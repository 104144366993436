import { NgModule } from '@angular/core';
import { KeysPipe } from './objectkeys.pipe';
import { ShiftDecimals } from './shiftDecimals.pipe';
import { ShortAddressPipe } from './shortAddress.pipe';
import { NetworkImgPipe } from './network-img.pipe';
import { IpfsLinkPipe } from "./ipfsLink.pipe";
import {TimeLeftPipe} from "./time-left.pipe";
import {LandOtherResourcesPipe} from "./other-resources-pipe.pipe";
import {LandJuicePipe} from "./juice-pipe.pipe";


@NgModule({
  exports: [
    ShortAddressPipe,
    KeysPipe,
    ShiftDecimals,
    NetworkImgPipe,
    IpfsLinkPipe,
    TimeLeftPipe,
    LandJuicePipe,
    LandOtherResourcesPipe
  ],
  declarations: [
    ShortAddressPipe,
    KeysPipe,
    ShiftDecimals,
    NetworkImgPipe,
    IpfsLinkPipe,
    TimeLeftPipe,
    LandJuicePipe,
    LandOtherResourcesPipe
  ],
    providers: [],
})
export class PipesModule {}
