<section class="lottery">
    <div class="admin" *ngIf="isAdmin">
        <div class="title">
            create lottery
        </div>
        <form class="example-form">
            <div class="select">
                <mat-select [(value)]="selectedNetwork" [ngClass]="selectedNetwork">
                    <mat-option *ngFor="let network of networks" [value]="network.value">
                        <img class="token-img" src='{{network.img}}'> {{network.viewValue}}
                    </mat-option>
                </mat-select>
            </div>
            <mat-form-field appearance="fill">
                <mat-label>Collection Address</mat-label>
                <input matInput [formControl]="collectionFormControl" placeholder="0x430a..." [(ngModel)]="createLotteryData.collection">
                <mat-error *ngIf="collectionFormControl.hasError('required')">
                    Collection is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>NFT ID</mat-label>
                <input matInput type="number" min="0" [formControl]="nftIDFormControl" placeholder="1" [(ngModel)]="createLotteryData.nftID">
                <mat-error *ngIf="nftIDFormControl.hasError('required')">
                    NFT ID is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Start Date</mat-label>
                <input matInput type="number" min="1" [formControl]="startFormControl" placeholder="1656233314" [(ngModel)]="createLotteryData.start">
                <mat-error *ngIf="startFormControl.hasError('required')">
                    Start Date is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>End Date</mat-label>
                <input matInput type="number" min="1" [formControl]="endFormControl" placeholder="1656233314" [(ngModel)]="createLotteryData.end">
                <mat-error *ngIf="endFormControl.hasError('required')">
                    End Date is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Min Price</mat-label>
                <input matInput type="number" min="0" [formControl]="minFormControl" placeholder="1000" [(ngModel)]="createLotteryData.min">
                <mat-error *ngIf="minFormControl.hasError('required')">
                    Min Price is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Max Price</mat-label>
                <input matInput type="number" min="0" [formControl]="maxFormControl" placeholder="1000" [(ngModel)]="createLotteryData.max">
                <mat-error *ngIf="maxFormControl.hasError('required')">
                    Max Price is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <!-- <div class="select">
        <mat-select [(value)]="selectedCurrency" [ngClass]="selectedCurrency">
          <mat-option *ngFor="let token of tokens" [value]="token.value">
            <img class="token-img" src='{{token.img}}'> {{token.viewValue}}
          </mat-option>
        </mat-select>
      </div> -->
            <mat-error class="error-message" *ngIf="validateCreateDataError!=''">
                {{validateCreateDataError}}
            </mat-error>
            <div class="create-lottery-button" (click)="createLottery()">
                Create
            </div>
        </form>
    </div>
    <div class="container header-container">
        <h2>Lottery</h2>
        <div class="howto" (click)="modalService.open('info')">
            <img src="assets/images/info.png" />
            <a>How the lottery works?</a>
        </div>
    </div>
    <div class="container" *ngIf="lotteries?.length > 0; else noLotteries">
        <div class="nft-home-card nft-lottery-page">
            <nft-card *ngFor="let lottery of lotteries; let i = index;" [nftInfo]="lottery" [showLotTypeLabel]="false" (clickOnCard)="openLottery(lottery)"></nft-card>

        </div>
    </div>
</section>

<ng-template #noLotteries>
    <div class="no-lotteries">
        There are no lotteries now. Stay tuned.
    </div>
</ng-template>


<jw-modal class="new-design-modal" id="info">
    <div>
        <h3>How the lottery works?</h3>
        <button class="btn-close" (click)="modalService.close('info')"></button>
    </div>
    <div>
        <p>
            1. Each lottery has a range of numbers within which you can place your bid (you can bid multiple times).
        </p>
        <p>
            2. At the end of the lottery, a smart contract chooses a random number from the available range.
        </p>
        <p>
            3. The winner is the closest bid to the chosen number.
        </p>
        <p>
            4. If two bidders place the same bid, the leader will be the one who has placed this bid first.
        </p>
        <p>
            5. When several bidders bid exactly the same amount at the same time, the winner will be chosen randomly.
        </p>
    </div>
</jw-modal>