<div class="map-container">
  <div>
    <div *ngFor="let gr of groups">
      <button (click)="changeGroup(gr.groupId)">Select group {{gr.groupId}}</button>
    </div>
  </div>
  <mgl-map
    [style]="'mapbox://styles/pavsid/cldii3j23000601oqc7aszv76'"
    [zoom]="[config.initZoomLevel]"
    [maxBounds]="maxBoundsFloat"
    [center]="config.initCenter"
    [minZoom]="config.minZoomLevel"
    [maxZoom]="config.maxZoomLevel"
    (load)="mapLoaded($event)"
    [scrollZoom]="zoomable"
    (focusin)="focusChange(true)"
    (focusout)="focusChange( false)"
  >
    <mgl-control mglScale position="top-left" unit="metric"></mgl-control>
    <mgl-control mglFullscreen position="top-left"></mgl-control>
    <mgl-control mglNavigation position="top-left" [showCompass]="false"></mgl-control>
  </mgl-map>
  <div id="info" style="color: white"></div>


  <!--<div [ngClass]="{'legendary': selectedLand.landType.rarity === 'legendary'}" class="land-card" id="land-info" *ngIf="selectedLand">
    <img src="assets/images/elseverse/land-card/dragon-{{selectedLand.landType.rarity}}.png" alt="dragon-{{selectedLand.landType.rarity}}" class="dragon">
    <img src="assets/images/elseverse/land-card/{{selectedLand.landType.biome}}.png" alt="{{selectedLand.landType.biome}}" class="biome-type">

    <img src="assets/images/elseverse/land-card/bg-{{selectedLand.landType.rarity}}.png" alt="bg-{{selectedLand.landType.rarity}}" class="card-bg">

    <img src="assets/images/elseverse/land-card/land-{{selectedLand.landType.rarity}}.png" alt="land-{{selectedLand.landType.rarity}}" class="land">

    <div class="content-wrapper">
      <div class="content-text-wrapper">
        <p [ngClass]="{'common': selectedLand.landType.rarity === 'common',
         'uncommon': selectedLand.landType.rarity === 'uncommon',
         'rare': selectedLand.landType.rarity === 'rare',
         'legendary': selectedLand.landType.rarity === 'legendary',
         'epic': selectedLand.landType.rarity === 'epic',
         'biome-type-text': true}">{{selectedLand.landType.biome === 'mountains' ? 'Mountain' : selectedLand.landType.biome === 'jungle' ? 'Jungle' : 'Shoreline'}} Land</p>
        <p class="card-info-text">{{selectedLand.landType.description}}</p>
      </div>

      <div class="land-items-wrapper">
        <div class="land-item">
          <img src="assets/images/elseverse/land-card/gem.png" alt="gem" class="item">
          <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
        </div>
        <div class="land-item">
          <img src="assets/images/elseverse/land-card/stone.png" alt="stone" class="item">
          <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
        </div>
        <div class="land-item">
          <img src="assets/images/elseverse/land-card/wood.png" alt="wood" class="item">
          <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
        </div>
        <div class="land-item">
          <img src="assets/images/elseverse/land-card/juice.png" alt="juice" class="item">
          <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
        </div>
        <div class="land-item">
          <img src="assets/images/elseverse/land-card/fruit.png" alt="fruit" class="item">
          <img src="assets/images/elseverse/land-card/bar-yellow-1.png" alt="bar" class="bar">
        </div>
      </div>
    </div>

    <p [ngClass]="{'common': selectedLand.landType.rarity === 'common',
         'uncommon': selectedLand.landType.rarity === 'uncommon',
         'rare': selectedLand.landType.rarity === 'rare',
         'legendary': selectedLand.landType.rarity === 'legendary',
         'epic': selectedLand.landType.rarity === 'epic',
         'land-type-text': true}">{{selectedLand.landType.rarity}}</p>

  </div>-->

  <div *ngIf="selectedLand" class="land-info" id="land-info">
    <div class="land-info-header">
      <div class="land-info-images-wrapper">
        <img class="dragon-img" src="assets/images/elseverse/dragon{{selectedLand.landType.chanceForDragons}}.png" alt="Chance for dragons">
        <img [ngClass]="{'common': selectedLand.landType.rarity === 'common',
         'uncommon': selectedLand.landType.rarity === 'uncommon',
         'rare': selectedLand.landType.rarity === 'rare',
         'legendary': selectedLand.landType.rarity === 'legendary',
         'epic': selectedLand.landType.rarity === 'epic',
         'rarity-img': true}" src="assets/images/elseverse/{{selectedLand.landType.rarity}}.svg" alt="Rarity">
      </div>
      <img class="dismiss-icon" (click)="deselect()" src="assets/images/cross.png"/>
    </div>

    <div class="land-img">
      <img src="assets/images/elseverse/nft-land-image.svg" alt="">
    </div>

    <div class="land-data">
      <div class="land-context">
        <p class="label">Biome</p>
        <p class="value">{{selectedLand.landType.biome | titlecase }}</p>
      </div>
      <div class="land-context">
        <p class="label">Juice</p>
        <p class="value">{{selectedLand.landType.juice | landJuice}}</p>
      </div>
      <div class="land-context">
        <p class="label">Other resources</p>
        <p class="value">{{selectedLand.landType | landOtherResourcesPipe }}</p>
      </div>
      <div class="land-context">
        <p class="label">Available same type</p>
        <p class="value">{{selectedLand.availableSameType}} lands</p>
      </div>
    </div>
    <div class="land-info_btnBlock" *ngIf="isLandActionAvailable(selectedLand)">
      <button [innerHTML]="getLandButtonText(selectedLand)" class="buyBtn" (click)="landButtonClick(selectedLand)"></button>
      <!--<button class="cubBtn"><img src="assets/images/cubBtn.png" alt=""></button>-->
    </div>
  </div>
</div>

<jw-modal id="choose-method-modal">
  <div>
    <h3>Choose payment option</h3>
    <button class="btn-close" (click)="modalService.close('choose-method-modal')"></button>
  </div>
  <div class="buttons">
    <button class="buyLand" (click)="buyWithFiat(selectedLand)">Buy with fiat</button>
  </div>
  <div>
    <button class="buyLand" (click)="buyWithCrypto(selectedLand)">Buy with crypto</button>
  </div>
</jw-modal>

<jw-modal id="elseverse-wert-modal">
  <div>
    <h3>Buy with Wert</h3>
    <button class="btn-close" (click)="wertWidget.destroy(); modalService.close('elseverse-wert-modal')"></button>
  </div>
  <div id="widget-elseverse-wert"></div>
</jw-modal>
