import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  public type: 'terms-of-condition' | 'privacy-policy' | 'cookie-policy' = 'terms-of-condition';

  constructor(
    private readonly route: ActivatedRoute) {
  }

  public ngOnInit() {
    this.route.params.subscribe(params => {
      this.type = params.type;
    });
  }
}
