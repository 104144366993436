import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {filter, finalize, map, switchMap, tap} from "rxjs/operators";
import { GMPD_NFT_TYPES } from 'src/app/enums/gmpd_nft_enum';
import { Web3Service } from 'src/app/services/web3.service';
import { ModalService } from '../modal/modal.service';
import { PositionsApiService } from "../../services/api/positions-api.service";
import { ProjectDTO } from "../../dto/project.dto";
import {PositionModel} from "../../models/position.model";
import {DOCUMENT} from "@angular/common";
import {AuctionNewBidEvent} from "../../dto/events/auction-new-bid.event";
import {AuctionExtendedEvent} from "../../dto/events/auction-extended.event";
import {RealtimeService} from "../../services/realtime.service";
import {SpecialEventModel} from "../../models/special-event.model";
import {AuthService} from "../../services/auth.service";
import {AlertService} from "../../services/alert.service";


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  public positions: any = [];
  public projects: ProjectDTO[] = [];
  public specialEvents: SpecialEventModel[] = [];
  public canLoadMore: boolean = true;
  private isLoading: boolean = false;

  public readonly sortOptions: any[] = [
    { name: 'Increasing Price', field: 'price', direction: 'asc' },
    { name: 'Decreasing Price', field: 'price', direction: 'desc' },
    { name: 'NFT Name: A to Z', field: 'name', direction: 'asc' },
    { name: 'NFT Name: Z to A', field: 'name', direction: 'desc' },
    { name: 'Project Name: A to Z', field: 'project', direction: 'asc' },
    { name: 'Project Name: Z to A', field: 'project', direction: 'desc' },
    { name: 'Creator Name: A to Z', field: 'creator', direction: 'asc' },
    { name: 'Creator Name: Z to A', field: 'creator', direction: 'desc' }
  ];

  public readonly sellTypeOptions: any[] = [
    { name: 'All', value: '' },
    { name: 'Fixed Price', value: 'fixedPrice' },
    { name: 'Auction', value: 'auction' }
  ];

  public readonly searchByOptions: any[] = [
    { name: 'All', value: 'all' },
    { name: 'Project Name', value: 'project' },
    { name: 'NFT Name', value: 'name' },
    { name: 'Creator Name', value: 'creator' }
  ];

  public selectedSort: any = this.sortOptions[0];
  public selectedSellType: any = this.sellTypeOptions[0];
  public selectedSearchBy: any = this.searchByOptions[0];
  public searchValue: any = '';
  private totalItemsOnServer: number = 0;

  public selectedSortField$ = new BehaviorSubject<string>(this.selectedSort.field);
  public selectedSortDirection$ = new BehaviorSubject<string>(this.selectedSort.direction);
  public selectedSellType$ = new BehaviorSubject<string>(this.selectedSellType.value);
  public selectedSearchBy$ = new BehaviorSubject<string>(this.selectedSearchBy.value);
  public searchValue$ = new BehaviorSubject<string>(this.searchValue);
  private pageValue$ = new BehaviorSubject<number>(1);
  private subscriptions: Subscription[] = [];

  public filterChanged: Observable<any> = combineLatest(
    [this.selectedSortField$, this.selectedSortDirection$, this.selectedSellType$, this.selectedSearchBy$, this.searchValue$]
  ).pipe(
    switchMap(([sortBy, sortDir, sellType, searchBy, search]) => {
      this.pageValue$.next(1);
      return of();
    }));

  public openPositionsLazy: Observable<PositionModel[]> = this.pageValue$.pipe(
    switchMap((page) => {
      this.startLoading();
      return this.api.getPositions(this.selectedSortField$.value,this.selectedSortDirection$.value ,
        this.selectedSellType$.value, this.selectedSearchBy$.value, this.searchValue$.value, page, 8)
        .pipe(finalize(()=>{
          this.stopLoading();
        }));
    }),
    map(i => {
      this.totalItemsOnServer = i.totalItems;
      return i.items;
    }));

  constructor(private readonly api : PositionsApiService,
              private readonly web3: Web3Service,
              private readonly router:Router,
              private readonly modalService: ModalService,
              private readonly userSessionProvider: AuthService,
              private readonly alertService: AlertService,
              private readonly realtimeService: RealtimeService,
              @Inject(DOCUMENT) private document: any) {
    this.filterChanged.subscribe();
    this.openPositionsLazy.subscribe(i => {
      if (this.pageValue$.value > 1){
        this.positions.push(...i);
      } else {
        this.positions = i;
      }
      this.canLoadMore = this.totalItemsOnServer != this.positions.length;
    });
  }

  async ngOnInit() {
    const wasShown = localStorage.getItem("showWarningNew");

    if (!wasShown) {
      setTimeout(()=>{
        this.modalService.open("custom-modal-1");
        localStorage.setItem("showWarningNew", "1");
      }, 0)
    }

    setTimeout(()=>{
      window.scrollTo(0, 0);
    }, 1);

    this.projects = await this.api.getProjects().toPromise();
    this.specialEvents = await this.api.getSpecialEvents().toPromise();
    console.log(this.specialEvents);

    this.subscriptions.push(this.realtimeService.auctionBidEvent$.subscribe((newBid: AuctionNewBidEvent)=>{
      const auction = this.positions.filter((p: PositionModel) => p.isAuction && p.id == newBid.auctionId && p.chainId == newBid.chainId)[0];
      if (auction){
        auction.currentBid = newBid.amount.toString();
        auction.price = newBid.amount.toString();
      }
    }));

    this.subscriptions.push(this.realtimeService.auctionExtendedEvent$.subscribe((exntendEvent: AuctionExtendedEvent)=>{
      const auction = this.positions.filter((p: PositionModel) => p.isAuction && p.id == exntendEvent.auctionId && p.chainId == exntendEvent.chainId)[0];
      if (auction){
        auction.auctionEndDate = new Date(exntendEvent.endDate).getTime() / 1000;
      }
    }));
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions){
      sub?.unsubscribe();
    }
  }

  public get chain(): any {
    return this.web3.chain;
  }

  public isGmpd(address: string): boolean {
    return this.getCollection(address)?.address.toLowerCase() == this.web3.chain.botsAddress.toLowerCase();
  }

  public getCollection(address: string): any {
    return this.projects.find((x: any) => x.address.toLowerCase() == address.toLowerCase());
  }

  public getTokenName(item: PositionModel): string {
    return this.isGmpd(item.tokenAddress) ?
      this.nftTypeById(item.tokenItemTypeId).replace('_', ' ') :
      this.getCollection(item.tokenAddress)?.name;
  }

  public nftTypeById(value:number): string{
    return GMPD_NFT_TYPES[value];
  }

  public closeModal(): void{
    this.modalService.close("custom-modal-1");
  }

  public async buyClick(token: PositionModel | SpecialEventModel, passChainId: boolean = false): Promise<void> {
    this.router.navigate([token.isAuction ? 'auction' : 'nft', token.chainId, token.id]);
  }

  public setSort(sortOption: any) {
    this.selectedSort = sortOption;
    this.selectedSortField$.next(sortOption.field);
    this.selectedSortDirection$.next(sortOption.direction);
  }

  public setSellType(sellTypeOption: any) {
    this.selectedSellType = sellTypeOption;
    this.selectedSellType$.next(sellTypeOption.value);
  }

  public setSearchBy(searchByOption: any) {
    this.selectedSearchBy = searchByOption;
    this.selectedSearchBy$.next(searchByOption.value);
  }

  public setSearch(value: any): void {
    this.searchValue = value;
    this.searchValue$.next(value);
  }

  public resetFilter(): void {
    this.setSort(this.sortOptions[0]);
    this.setSellType(this.sellTypeOptions[0]);
    this.setSearchBy(this.searchByOptions[0]);
    this.setSearch('');
  }

  loadNextPage() {
    this.pageValue$.next(this.pageValue$.value + 1);
  }

  private startLoading() {
    if (!this.isLoading){
      this.document.body.classList.add('wait_openid');
    }
    this.isLoading = true;
  }

  private stopLoading() {
    if (this.isLoading){
      this.document.body.classList.remove('wait_openid');
    }
    this.isLoading = false;
  }
}
