import {Injectable, OnDestroy} from "@angular/core";
import * as signalR from '@microsoft/signalr';
import  {HttpTransportType, HubConnection} from "@microsoft/signalr";
import {Observable, Subject} from "rxjs";
import {UserBetModel} from "../models/user-bet.model";
import {LotteryModel} from "../models/lottery.model";
import {environment} from "../../environments/environment";
import {PositionSoldEvent} from "../dto/events/position-sold.event";
import {LotteryWinnerEvent} from "../dto/events/lottery-winner.event";
import {AuctionExtendedEvent} from "../dto/events/auction-extended.event";
import {AuctionNewBidEvent} from "../dto/events/auction-new-bid.event";
import {LandMintEvent} from "../dto/events/land-mint.event";
import {AlertService} from "./alert.service";
import {Web3Service} from "./web3.service";

@Injectable({
  providedIn: 'root'
})
export class RealtimeService implements OnDestroy {
  private connection: HubConnection;
  private positionSoldEvent: Subject<PositionSoldEvent> = new Subject<PositionSoldEvent>();
  private lotteryBidEvent: Subject<UserBetModel> = new Subject<UserBetModel>();
  private lotteryCreatedEvent: Subject<LotteryModel> = new Subject<LotteryModel>();
  private auctionBidEvent: Subject<AuctionNewBidEvent> = new Subject<AuctionNewBidEvent>();
  private auctionExtendedEvent: Subject<AuctionExtendedEvent> = new Subject<AuctionExtendedEvent>();
  private lotteryWinnerEvent: Subject<LotteryWinnerEvent> = new Subject<LotteryWinnerEvent>();
  private landMintEvent: Subject<LandMintEvent> = new Subject<LandMintEvent>();

  public positionSoldEvent$: Observable<PositionSoldEvent> = this.positionSoldEvent.asObservable();
  public lotteryBidEvent$: Observable<UserBetModel> = this.lotteryBidEvent.asObservable();
  public lotteryCreatedEvent$: Observable<LotteryModel> = this.lotteryCreatedEvent.asObservable();
  public auctionBidEvent$: Observable<AuctionNewBidEvent> = this.auctionBidEvent.asObservable();
  public auctionExtendedEvent$: Observable<AuctionExtendedEvent> = this.auctionExtendedEvent.asObservable();
  public lotteryWinnerEvent$: Observable<LotteryWinnerEvent> = this.lotteryWinnerEvent.asObservable();
  public landMintEvent$: Observable<LandMintEvent> = this.landMintEvent.asObservable();

  constructor(private readonly alertService: AlertService,
              private readonly web3Service: Web3Service) {
    this.initialize();
    this.subscribeOnEvents();
    this.connection.start();
    console.log('started signalR!!');
  }

  ngOnDestroy(): void {
    this.positionSoldEvent?.complete();
    this.lotteryBidEvent?.complete();
    this.lotteryCreatedEvent?.complete();
    this.auctionBidEvent?.complete();
    this.auctionExtendedEvent?.complete();
    this.lotteryWinnerEvent?.complete();
  }

  private initialize(): void {
    this.connection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (
            retryContext.elapsedMilliseconds <
            environment.signalRConfig.frequentReconnectTimeout
          ) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait 500 ms before the next reconnect attempt.
            return 500;
          } else if (
            retryContext.elapsedMilliseconds < environment.signalRConfig.lazyReconnectTimeout
          ) {
            // try reconnect every 5-15 seconds
            return 5 * 1000 + Math.random() * 10000;
          } else {
            // once a minute
            return 60 * 1000;
          }
        }
      })
      .withUrl(environment.apiUrl + environment.signalRConfig.hubUrl, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true
      })
      .build();
  }

  private subscribeOnEvents(): void {
    this.connection.on('LotteryWinnerEvent', (lotteryId: number, winner: string) => {
      this.lotteryWinnerEvent.next({
        lotteryId,
        winner
      });
    });

    this.connection.on('AuctionExtendedEvent', (chainId: number, auctionId: number, endDate: Date) => {
      this.auctionExtendedEvent.next({
        chainId,
        auctionId,
        endDate
      });
    });

    this.connection.on('AuctionNewBidEvent', (chainId: number, auctionId: number, amount: number) => {
      this.auctionBidEvent.next({
        chainId,
        auctionId,
        amount
      });
    });

    this.connection.on('LotteryCreatedEvent', (lottery: LotteryModel) => {
      this.lotteryCreatedEvent.next(lottery);
    });

    this.connection.on('LotteryNewBidEvent', (newBid: UserBetModel) => {
      this.lotteryBidEvent.next(newBid);
    });

    this.connection.on('FiatPaymentEvent', (userWallet: string, success: boolean) => {
      if (localStorage.getItem("walletForFiat").toLowerCase() !== userWallet?.toLowerCase()){
        return;
      }
      if (success)
        this.alertService.show('Thank you. NFT has been sent to your wallet!');
      else
        this.alertService.show('Sorry, NFT transfer was not successful.');
      localStorage.removeItem("walletForFiat");
    });

    this.connection.on('PositionSoldEvent', (positionId: number, chainId: number) => {
      this.positionSoldEvent.next({
        chainId,
        positionId
      });
    });

    this.connection.on('LandMintEvent', (userWallet: string, landId: number, tokenId: number) => {
      this.landMintEvent.next({
        userWallet,
        landId,
        tokenId
      });
    });
  }

}
