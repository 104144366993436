import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { BaseApiService } from "./base-api.service";
import { ReferrerBaseModel, ReferrerBuyModel, ReferrerDetailsModel, ReferrerModel } from 'src/app/models/referrer.model';

@Injectable({ providedIn: 'root' })
export class UsersApiService extends BaseApiService {
  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient);
  }

  public getReferrerAddress(address: string): Observable<ReferrerBaseModel> {
    return this.getRequest<ReferrerBaseModel>(`api/users/referrer/${address}`);
  }

  public getReferrers(): Observable<ReferrerModel[]> {
    return this.getRequest<ReferrerModel[]>(`api/users/referrers`);
  }

  public getReferrerDetails(address: string): Observable<ReferrerDetailsModel> {
    return this.getRequest<ReferrerDetailsModel>(`api/users/referrer-details/${address}`);
  }

  public getReferrerBuys(address: string): Observable<ReferrerBuyModel[]> {
    return this.getRequest<ReferrerBuyModel[]>(`api/users/referrer-buys/${address}`);
  }
}
