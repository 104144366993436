<div class="item_nft" (click)="openNft()" [class.special-background]="specialBackground">
    <div class="preview">
        <div *ngIf="nftInfo?.hasTimeFrames" timeLeft [startDate]="nftInfo.startDate" [endDate]="nftInfo?.endDate" [topMargin]="showLotTypeLabel ? 75 : null"  [leftMargin]="showLotTypeLabel ? 24 : null" [textIfEnded]="nftInfo?.winnerAddress ? 'Winner: ' + (nftInfo.winnerAddress | shortAddress) : ''"></div>
        <video *ngIf="nftInfo?.hasVideo && (!isAndroid || !isMetamaskOrTrust)" src="{{ isGmpd ? 'assets/video/' + nftTypeById() + '.mp4' : nftInfo.videoUrl | ipfsLink }}" playsinline="" autoplay="autoplay" loop="loop" muted="muted" class="nft-card__image"></video>
        <img *ngIf="nftInfo?.hasVideo && isAndroid && isMetamaskOrTrust" src="{{'assets/images/type/' + nftTypeById() + '.png'}}" alt="{{nftTypeById()}}">
        <img *ngIf="!nftInfo?.hasVideo && (nftInfo.landTypeId === null || nftInfo.landTypeId === undefined)" alt="" src="{{(nftInfo?.imageUrl || 'assets/images/no-image.png') | ipfsLink }}" class="item_nft_img">
        <div *ngIf="!nftInfo?.hasVideo && (nftInfo.landTypeId !== null && nftInfo.landTypeId !== undefined)" class="elseverse-nft-card-wrapper">
          <img alt="" src="{{(nftInfo?.imageUrl || 'assets/images/elseverse/elseverse-no-image.svg') | ipfsLink }}" class="item_nft_img">
        </div>
        <label *ngIf="showLotTypeLabel" [class]="nftInfo?.isAuction ? 'auction' : 'fixed-price' ">
      <img src="{{'assets/images/nft-item-page/' + (nftInfo?.isAuction ? 'Auction.svg' : 'CheckMark.svg')}}"/>
      {{nftInfo?.isAuction ? 'Auction' : 'Fixed Price'}}
    </label>
        <div class="showActionButtons" *ngIf="showActionButtons">
            <button *ngIf="!nftInfo.isAuction" (click)="cancelSell($event)"><img src="assets/images/nft-item-page/stopSelling.png" alt=""> Stop selling</button>
            <button *ngIf="nftInfo.isAuction && !nftInfo?.currentBid" (click)="stopAuction($event)"><img src="assets/images/nft-item-page/stopSelling.png" alt=""> Stop auction</button>
            <button *ngIf="nftInfo.isAuction && nftInfo?.currentBid" (click)="finishAuction($event)"><img src="assets/images/nft-item-page/stopSelling.png" alt=""> Sell current bid</button>
        </div>
    </div>

    <div class="info">
        <mat-checkbox *ngIf="showCheckBulkList && !showActionButtons" class="check-box" (change)="setCheckedForBulkList()" [(ngModel)]="isCheckedForBunchList"></mat-checkbox>
        <div class="nftItemTop" (click)="toggleStatusBunchList()">
            <div class="nft-number">{{ (nftInfo?.collectionSymbol || '') + ' #' + (nftInfo?.tokenId || ((nftInfo.landTypeId !== null || nftInfo.landTypeId !== undefined) && nftInfo?.id)) }}</div>
            <h3>{{ nftInfo.landTypeId === null || nftInfo.landTypeId === undefined ? nftInfo?.tokenName : nftInfo.landType.biome }}</h3>

            <div [ngClass]="{'land-data': nftInfo.landTypeId !== null && nftInfo.landTypeId !== undefined, 'owner-data': nftInfo.landTypeId === null || nftInfo.landTypeId === undefined}">
                <div>
                    <div class="title">{{nftInfo.landTypeId !== null && nftInfo.landTypeId !== undefined ? "Owner" : "Project"}}</div>
                    <div class="item-value">
                        <img src="{{nftInfo?.projectIcon || 'assets/images/nft-item-page/Avatar.png' }}" alt="">
                        <span>{{nftInfo?.collectionName ? nftInfo?.collectionName : nftInfo?.owner || 'Unknown'}}</span>
                    </div>
                </div>
                <div>
                    <div class="title">{{nftInfo.landTypeId !== null && nftInfo.landTypeId !== undefined ? "Rarity" : "Creator"}}</div>
                    <div class="item-value">
                        <img *ngIf="nftInfo.landTypeId === null || nftInfo.landTypeId === undefined" src="{{nftInfo?.projectIcon || 'assets/images/nft-item-page/Avatar.png'}}" alt="">
                        <span>{{nftInfo?.projectCreator ? nftInfo?.projectCreator : nftInfo?.landType.rarity}}</span>
                    </div>
                </div>
                <div>
                    <div class="title">{{nftInfo.landTypeId !== null && nftInfo.landTypeId !== undefined ? "Juice" : "Owner"}}</div>
                    <div class="item-value">
                        <img *ngIf="nftInfo.landTypeId === null || nftInfo.landTypeId === undefined" src="{{ isGamespadOwner ? 'assets/images/icon_t_green.png' : 'assets/images/nft-item-page/Avatar.png'}}" alt="">
                        <span *ngIf="nftInfo.landTypeId === null || nftInfo.landTypeId === undefined">{{ isGamespadOwner ? 'GamesPad' : nftInfo?.ownerAddress?.toLowerCase() | shortAddress }}</span>
                        <span *ngIf="nftInfo.landTypeId !== null && nftInfo.landTypeId !== undefined">{{ nftInfo.landType.juice }}</span>
                    </div>
                </div>
              <div *ngIf="nftInfo.landTypeId !== null && nftInfo.landTypeId !== undefined">
                    <div class="title">Chance of dragons</div>
                    <div class="item-value">
                        <span>{{ nftInfo.landType.chanceForDragons }}</span>
                    </div>
                </div>
                <div *ngIf="nftChain && (nftInfo.landTypeId === null || nftInfo.landTypeId === undefined)">
                    <div class="title">Chain</div>
                    <div class="item-value">
                        <img src="{{ nftChain?.img }}" alt="">
                        <span>{{ nftChain?.name }}</span>
                    </div>
                </div>
                <div class="bottom-range" *ngIf="nftInfo?.minPrice && nftInfo.maxPrice">
                    <div class="title">Range</div>
                    <div class="item-value" *ngIf="nftInfo.currency">
                        <div>
                            <img *ngIf="nftInfo.currency?.img" [src]="nftInfo.currency?.img" />
                            <span>{{nftInfo.minPrice}} - {{nftInfo.maxPrice}} {{nftInfo.currency?.viewValue}}</span>
                        </div>
                    </div>
                    <div class="item-value" *ngIf="!nftInfo.currency">
                        <div>
                            <img [src]="lottery.tokens[0].img" />
                            <span>{{lottery.tokens[0].min}} - {{lottery.tokens[0].max}} {{lottery.tokens[0].viewValue}}</span>
                        </div>
                        <div>
                            <img [src]="lottery.tokens[1].img" />
                            <span>{{lottery.tokens[1].min}} - {{lottery.tokens[1].max}} {{lottery.tokens[1].viewValue}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="showSellButtons" *ngIf="showSellButtons">
            <div class="action-btn dropdown-button" (click)="showSellOptions = !showSellOptions" [matMenuTriggerFor]="sellTypes">
                <a>Sell</a>
            </div>
            <mat-menu class="mat-menu-putToSell" #sellTypes="matMenu">
                <button mat-menu-item (click)="putToSell($event, false)"><img src="assets/images/FixedPriceGray.png" alt="">Fixed Price</button>
                <button mat-menu-item (click)="putToSell($event, true)"><img src="assets/images/AuctionGray.png" alt=""> Auction</button>
            </mat-menu>
        </div>

        <div class="nft-price" *ngIf="nftInfo?.price && currencyDecimals">
            <div>
                <img src="assets/images/currency/{{nftChain?.networkParams.currencyName.toLowerCase()}}.png" alt="" class="chain-icon" /> {{nftInfo?.price | shiftDecimals: -currencyDecimals }} {{nftChain?.networkParams.currencyName}}
            </div>
        </div>
    </div>
</div>
