import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main/main.component';
import { NftItemComponent } from './components/nft-item/nft-item.component';
import { UserNFTsComponent } from './components/user-nfts/user-nfts.component';
import { UserPageComponent } from './components/user-page/user-page.component';
import { PipesModule } from './pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Guard } from './guards/auth.guard';
import { ApplyTokenInterceptor } from './interceptors/applyToken.interceptor';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ModalModule } from './components/modal/modal.module';
import { NetworkSelectorComponent } from './components/shared/network-selector/network-selector.component';
import {DlgAlertComponent} from "./components/shared/dlg-alert.component";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {AlertComponent} from "./components/shared/alert.component";
import {MatIconModule} from "@angular/material/icon";
import {AlertService} from "./services/alert.service";
import {MatDialogModule} from "@angular/material/dialog";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { LotteryPageComponent } from './components/lottery-page/lottery-page.component';
import { TimeLeftComponent } from './components/shared/time-left/time-left.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {DocumentsComponent} from "./components/footer/documents/documents.component";
import {NftCardComponent} from "./components/nft-item/nft-card/nft-card.component";
import {MatRadioModule} from "@angular/material/radio";
import {ConnectWalletDialog} from "./components/shared/connect-wallet.dialog/connect-wallet.dialog.component";
import { ClosedAuctionPipe } from './pipes/closed-auction.filter.pipe';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ElseverseComponent } from './components/elseverse/elseverse.component';
import { LandsaleMapComponent } from './components/elseverse/landsale-map/landsale-map.component';
import {NgxMapboxGLModule} from "ngx-mapbox-gl";
import {CommonModule} from "@angular/common";
import { ConfirmationDialogComponent } from "./components/shared/confirmation-dialog/confirmation-dialog.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { LandCardComponent } from "./components/nft-item/land-card/land-card.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    AboutComponent,
    NftItemComponent,
    UserPageComponent,
    UserNFTsComponent,
    NetworkSelectorComponent,
    DlgAlertComponent,
    AlertComponent,
    UserNFTsComponent,
    LotteryPageComponent,
    NftCardComponent,
    DocumentsComponent,
    TimeLeftComponent,
    ConnectWalletDialog,
    ClosedAuctionPipe,
    ElseverseComponent,
    LandsaleMapComponent,
    ConfirmationDialogComponent,
    LandCardComponent
  ],
  imports: [
    FormsModule,
    PipesModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ClipboardModule,
    ModalModule,
    MatSelectModule,
    MatSliderModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ModalModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatRadioModule,
    CommonModule,
    MatCarouselModule,
    MatCheckboxModule,
    MatSnackBarModule,
    NgxMapboxGLModule.withConfig({
      accessToken:'pk.eyJ1IjoicGF2c2lkIiwiYSI6ImNsYmoxZXduazB5dWEzcXA1djJnbGYxd3YifQ.H_FaZZQ_oZphilDnQo6Z-A'
    })
  ],
  providers: [
    Guard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplyTokenInterceptor,
      multi: true
    },
    AlertService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }
