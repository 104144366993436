import { Pipe, PipeTransform } from '@angular/core';
import {LandType} from "../components/elseverse/landsale-map/land-info";
import {LandRarity} from "../models/elseverse/rarity.enum";

@Pipe({
  name: 'landOtherResourcesPipe'
})
export class LandOtherResourcesPipe implements PipeTransform {

  transform(value: LandType, ...args: unknown[]): string {
    if (value.rarity === LandRarity.uncommon){
      return 'Scarce';
    }
    if (value.rarity === LandRarity.common){
      return 'Some';
    }
    if (value.rarity === LandRarity.rare){
      return 'Good';
    }
    if (value.rarity === LandRarity.epic){
      return 'Rich';
    }
    if (value.rarity === LandRarity.legendary){
      return 'Luxurious';
    }

    return 'Unknown';
  }

}
