import INetworkData from "../../models/networks.data";

const networks: INetworkData[] = [
  {
    name: "ETH",
    chainId: 1,
    chainIdHex: "0x1",
    img: "/assets/images/network/eth.svg",
    imgBlack: "/assets/svg/eth_black.svg",
    imgModal: "/assets/svg/eth_modal.svg",
    networkParams: {
      chainId: "0x1",
      chainName: 'Etherium',
      currencyName: "USDT",
      nativeCurrency: {
        name: 'ETH',
        symbol: 'eth',
        decimals: 18,
      },
      rpcUrls: ["https://speedy-nodes-nyc.moralis.io/104ec839a0ad908009451abc/eth/mainnet"],
      blockExplorerUrls: ['https://etherscan.io']
    }
  },
  {
    name: "ETH Rinkeby",
    chainId: 4,
    chainIdHex: "0x4",
    img: "/assets/images/network/eth.svg",
    imgBlack: "/assets/svg/eth_black.svg",
    imgModal: "/assets/svg/eth_modal.svg",
    networkParams:
      {
        chainId: "0x4",
        chainName: 'ETH test (rinkeby)',
        currencyName: "USDT",
        nativeCurrency: {
          name: 'ETH',
          symbol: 'eth',
          decimals: 18,
        },
        rpcUrls: ["https://speedy-nodes-nyc.moralis.io/104ec839a0ad908009451abc/eth/rinkeby"],
        blockExplorerUrls: ['https://rinkeby.etherscan.io']
      }
  },
  {
    name: "ETH Test",
    chainId: 5,
    chainIdHex: "0x5",
    img: "/assets/images/network/eth.svg",
    imgBlack: "/assets/svg/eth_black.svg",
    imgModal: "/assets/svg/eth_modal.svg",
    networkParams:
      {
        chainId: "0x5",
        chainName: 'ETH test (goerli)',
        currencyName: "USDT",
        nativeCurrency: {
          name: 'ETH',
          symbol: 'eth',
          decimals: 18,
        },
        rpcUrls: ["https://maximum-shy-needle.ethereum-goerli.quiknode.pro/5aa8bdca199ee59f6729345db51f6fa76478962a/"],
        blockExplorerUrls: ['https://goerli.etherscan.io']
      }
  },
  {
    name: "BNB Chain",
    chainId: 56,
    chainIdHex: "0x38",
    img: "/assets/images/network/bsc.svg",
    imgBlack: "/assets/svg/bsc.svg",
    imgModal: "/assets/svg/bsc_modal.svg",
    networkParams:
      {
        chainId: "0x38",
        chainName: 'BNB Chain Mainnet',
        currencyName: "USDT",
        nativeCurrency: {
          name: 'BNB',
          symbol: 'bnb',
          decimals: 18,
        },
        rpcUrls: ["https://bsc-dataseed1.ninicoin.io", "https://bsc-dataseed1.defibit.io", "https://bsc-dataseed.binance.org"],
        blockExplorerUrls: ['https://bscscan.com/']
      }
  },
  {
    name: "BNB Chain Testnet",
    chainId: 97,
    chainIdHex: "0x61",
    img: "/assets/images/network/bsc.svg",
    imgBlack: "/assets/svg/bsc.svg",
    imgModal: "/assets/svg/bsc_modal.svg",
    networkParams:
      {
        chainId: "0x61",
        chainName: 'BNB Chain Testnet',
        currencyName: "USDT",
        nativeCurrency: {
          name: 'BNB',
          symbol: 'bnb',
          decimals: 18,
        },
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545", "https://data-seed-prebsc-2-s1.binance.org:8545", "https://data-seed-prebsc-1-s2.binance.org:8545"],
        blockExplorerUrls: ['https://testnet.bscscan.com/']
      }
  },
  {
    name: "Polygon",
    chainId: 137,
    chainIdHex: "0x89",
    img: '/assets/images/network/polygon.svg',
    imgBlack: '/assets/images/network/polygon.svg',
    imgModal: '/assets/images/network/polygon.svg',
    networkParams:
      {
        chainId: "0x89",
        chainName: 'Polygon',
        currencyName: "USDC",
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: ["https://speedy-nodes-nyc.moralis.io/104ec839a0ad908009451abc/polygon/mainnet"],
        blockExplorerUrls: ['https://polygonscan.com/']
      }
  },
  {
    name: "Polygon Test",
    chainId: 80001,
    chainIdHex: "0x13881",
    img: '/assets/images/network/polygon.svg',
    imgBlack: '/assets/images/network/polygon.svg',
    imgModal: '/assets/images/network/polygon.svg',
    networkParams:
      {
        chainId: "0x13881",
        chainName: 'Polygon Test',
        currencyName: "USDC",
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: ["https://speedy-nodes-nyc.moralis.io/104ec839a0ad908009451abc/polygon/mumbai"],
        blockExplorerUrls: ['https://mumbai.polygonscan.com/']
      }
  },
  {
    name: "Solana Devnet",
    chainId: -9999,
    chainIdHex: "-0x270F",
    img: '/assets/images/network/solana.svg',
    imgBlack: '/assets/images/network/solana.svg',
    imgModal: '/assets/images/network/solana.svg',
    networkParams:
      {
        chainId: "-0x270F",
        chainName: 'Solana Devnet',
        currencyName: "USDC",
        nativeCurrency: {
          name: 'SOL',
          symbol: 'SOL',
          decimals: 9,
        },
        rpcUrls: ["https://broken-serene-liquid.solana-devnet.quiknode.pro/05c014c557f1e217eae44ec16ca6eb76a1260656/"],
        blockExplorerUrls: ['https://explorer.solana.com/?cluster=devnet']
      }
  }
];

export default networks;
