import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'landJuice'
})
export class LandJuicePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    if (value === 0) {
      return 'Low (1 hole)';
    }
    if (value === 1) {
      return 'Moderate (2 hole)';
    }
    if (value === 2) {
      return 'Fair (4 holes)';
    }
    if (value === 3) {
      return 'Abundant (6 holes)';
    }
    if (value === 4) {
      return 'Bursting out (10 holes)';
    }

    return 'Unknown';
  }

}
