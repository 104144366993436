import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { TokenHistoryDTO } from '../../dto/tokenHistory.dto';
import {Web3Service} from "../web3.service";
import {BaseApiService} from "./base-api.service";
import { environment } from 'src/environments/environment';
import {map} from "rxjs/operators";
import {PositionModel} from "../../models/position.model";
import { ITokenTransfer } from "../../models/token-transfer.interface";

// import collectionABI from '@/abi/collection.json';

@Injectable({ providedIn: 'root' })
export class TokensApiService extends BaseApiService {
  private get chainId(): number {
    return this.web3Service.chainIdNumber;
  }

  constructor(protected readonly httpClient: HttpClient, private readonly web3Service: Web3Service) {
    super(httpClient);
  }
  public getTokenSellHistory(model: {tokenAddress:string, tokenId:number}): Observable<TokenHistoryDTO[]>{
    return this.getRequest<TokenHistoryDTO[]>(`api/tokens/history?tokenAddress=${model.tokenAddress}&tokenId=${model.tokenId}&chainId=${this.chainId}`);
  }

  public getTokenTransfers(model: {tokenAddress:string, tokenId:number, isAuction: boolean}): Observable<ITokenTransfer[]>{
    return this.getRequest<ITokenTransfer[]>(`api/tokens/transfers?tokenAddress=${model.tokenAddress}&tokenId=${model.tokenId}&isAuction=${model.isAuction}&chainId=${this.chainId}`);
  }

  public getUsersNFTsOld(account: string, tokenAddresses: [string], pageNumber:number=1, pageSize: number=10): Observable<any> {
    return this.getRequest<any>(`api/tokens/owned?pageNumber=${pageNumber}&pageSize=${pageSize}&ownerAddress=${account}&tokenAddress=${tokenAddresses}&chainId=${this.chainId}`);
  }

  public getUsersNFTs(account: string, tokenAddresses: string, chain: any, limit: number=10, cursor: string = ''): Observable<any> {
    return this.getFromMoralis<any>(`${account}/nft/${tokenAddresses}?chain=${chain.toString(16)}&format=decimal&limit=${limit}&cursor=${cursor}`);
  }

  public getNftInfo(nftAddress: string, nftTokenId: number, chainId?: number): Observable<any> {
    return this.getRequest<any>(`api/tokens/data?tokenAddress=${nftAddress}&tokenId=${nftTokenId}&chainId=${chainId || this.chainId}`);
  }

  public getTokenInfoFromCoingecko(token: string): Observable<any> {
    return this.getTokenInfo(token);
  }

  public getInfo(tokenUri: string): Observable<any> {
    return this.get(tokenUri);
  }
}
