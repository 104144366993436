import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { MainComponent } from './components/main/main.component';
import { NftItemComponent } from './components/nft-item/nft-item.component';
import { UserPageComponent } from './components/user-page/user-page.component';
import { LotteryPageComponent } from './components/lottery-page/lottery-page.component';
import { Guard } from './guards/auth.guard';
import {DocumentsComponent} from "./components/footer/documents/documents.component";
import {ElseverseComponent} from "./components/elseverse/elseverse.component";

const routes: Routes = [
  { path:'nft/:chainId/:positionId', component: NftItemComponent},
  { path:'auction/:chainId/:auctionId', component: NftItemComponent},
  { path:'', component: MainComponent },
  { path:'about', component : AboutComponent},
  { path:'documents/:type', component : DocumentsComponent},
  { path:'lottery', component : LotteryPageComponent},
  { path:'lottery/:lotteryId', component : NftItemComponent},
  { path:'elseverse/:landId', component : NftItemComponent},
  { path:'elseverse', component : ElseverseComponent},
  { path:'user-page', component: UserPageComponent, canActivate: [Guard]},
  { path: '**',   redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
