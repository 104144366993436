import { Component, OnInit } from '@angular/core';
import { Web3Service } from "../../../services/web3.service";
import { AuthService } from "../../../services/auth.service";
import { environment } from "../../../../environments/environment";
import networks from '../networks.data';
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "../../../services/alert.service";
import { ChainType } from "../../../enums/chain-type";
import { SolanaService } from "../../../services/solana.service";

declare let window: any;

@Component({
  selector: 'network-selector',
  templateUrl: './network-selector.component.html',
  styleUrls: ['./network-selector.component.scss']
})
export class NetworkSelectorComponent implements OnInit {
  public networkData: any[];
  public availableNetworks: any[];

  constructor(public web3Service: Web3Service,
              private _alertSrv: AlertService,
              private _dialog: MatDialog,
              private authService: AuthService,
              private solanaService: SolanaService) {
  }

  ngOnInit(): void {
    this.networkData = networks;
    this.availableNetworks = environment.chains.filter(x => x.enabled).map(x => x.id);
  }

  public getNetworkName(networkId: number): string {
    return this.networkData.find(i => i.chainId == networkId).name;
  }

  public async selectNetworkClick(chainId: number): Promise<boolean> {
    console.log("selectNetworkClick", chainId);
    if (this.web3Service.chainIdNumber == chainId) {
      //The same network
      console.log("same network", this.web3Service.chainIdNumber, chainId);
      this._dialog.closeAll();
      return false;
    }

    const toNetwork = networks.find((n) => n.chainId == chainId);
    const chainType = this.authService.getChainType(chainId);

    if (chainType == ChainType.Solana) {
      if (!window.solana) {
        this._alertSrv.show(
          'You need to have Phantom wallet installed',
          'error'
        );
        return false;
      }
      else {
        if (!window.solana.isConnected) {
          if (await this.ConnectSolanaWallet(toNetwork)) {
            this.web3Service.disconnect();
            location.reload();
          }
          else {
            return false;
          }
        }

        return true;
      }
    }
    else {
      if (window.ethereum && this.web3Service.isConnected) {
        if (toNetwork.networkParams) {
          let result = await this.ConnectWallet(toNetwork);
          return result;
        } else {
          this._alertSrv.show(
            `Select ${toNetwork.name} Network in your wallet.`,
            'error'
          );
          return false;
        }
      } else {
        this.authService.setChainId(toNetwork.chainId);
        this.solanaService.disconnect();
        location.reload();
        return false;
      }
    }
  }

  private async ConnectWallet(toNetwork: any): Promise<boolean>{
    const provider = window.ethereum;
    try {
      // @ts-ignore
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{chainId: toNetwork.networkParams.chainId}],
      });
    } catch (switchError: any) {
      if (switchError.code !== 4902) {
        return false;
      }
      try {
        // @ts-ignore
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [toNetwork.networkParams],
        });
      } catch (addError) {
        console.error(addError);
        return false;
      }
    }
    this.authService.setChainId(parseInt(toNetwork.networkParams.chainId));
    return true;
  }

  private async ConnectSolanaWallet(toNetwork: any) : Promise<boolean> {
    this.authService.setChainId(parseInt(toNetwork.networkParams.chainId));
    return true;
  }

}
