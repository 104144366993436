import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Web3Service } from '../../../services/web3.service';
import { INftInfo } from '../../../models/nft-info.interface';
import networksData from '../../shared/networks.data';
import { LotteryModel } from '../../../models/lottery.model';
import { environment } from '../../../../environments/environment';
import INetworkData from '../../../models/networks.data';
import { PositionModel } from 'src/app/models/position.model';

declare let window: any;
@Component({
  selector: 'nft-card',
  templateUrl: './nft-card.component.html',
  styleUrls: ['./nft-card.component.scss'],
})
export class NftCardComponent {
  lottery: LotteryModel;
  public isCheckedForBunchList: boolean = false;

  public isAndroid: boolean = /android/i.test(navigator.userAgent.toLowerCase());
  public isMetamaskOrTrust = (typeof window.ethereum !== 'undefined' && window.ethereum.isTrust) || (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask);

  public get isGmpd() {
    return (
      this.nftInfo?.collectionAddress?.toLowerCase() ==
      this.web3Service.chain.botsAddress.toLowerCase()
    );
  }

  public get isGamespadOwner(): boolean {
    return (
      this.nftInfo.isGamesPadOwner ||
      this.nftInfo?.ownerAddress?.toLowerCase() ==
        this.web3Service.chain?.gamespadOwner.toLowerCase()
    );
  }

  public nftTypeById(): any {
    return this.nftInfo.tokenName?.replace(' ', '_').toLowerCase();
  }

  public get nftChain(): INetworkData {
    const lottery = this.nftInfo as LotteryModel;
    return networksData.filter(
      (n) => n.chainId == (lottery?.nftNetworkId || this.nftInfo?.chainId)
    )[0];
  }

  @Input()
  public showSellOptions: boolean;

  @Input()
  public nftInfo: INftInfo | any;

  @Input()
  public showLotTypeLabel: boolean = true;

  @Input()
  public showActionButtons: boolean = false;

  @Input()
  public specialBackground: boolean = false;

  @Input()
  public showSellButtons: boolean = false;

  @Input()
  public showCheckBulkList: boolean = false;

  @Input()
  public forceSetCheckedForBunchList: boolean;

  @Output()
  public clickOnCard: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public cancelSellEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public putOnSell: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public stopAuctionEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public finishAuctionEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public checkedForBulkList: EventEmitter<any> = new EventEmitter<any>();

  constructor(private readonly web3Service: Web3Service) {}

  public get currencyDecimals(): number {
    return environment.chains.filter((c) => c.id == this.nftInfo?.chainId)[0]
      ?.currencyDecimals;
  }

  public ngOnInit(): void {
    this.lottery = this.nftInfo as LotteryModel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.showCheckBulkList && changes.forceSetCheckedForBunchList) {
      this.isCheckedForBunchList = changes.forceSetCheckedForBunchList.currentValue;
    }
  }

  public openNft(): void {
    this.clickOnCard.emit();
  }

  cancelSell(event: any) {
    event.stopPropagation();
    this.cancelSellEvent.emit();
  }

  putToSell(event: any, isAuction: boolean) {
    this.putOnSell.emit(isAuction);
    event.showSellOptions = false;
  }

  stopAuction(event: any) {
    event.stopPropagation();
    this.stopAuctionEvent.emit();
  }

  finishAuction(event: any){
    event.stopPropagation();
    this.finishAuctionEvent.emit();
  }

  setCheckedForBulkList(): void {
    this.checkedForBulkList.emit([this.nftInfo.tokenId, this.isCheckedForBunchList]);
  }

  toggleStatusBunchList(): void {
    if (this.showCheckBulkList && !this.showActionButtons) {
      this.isCheckedForBunchList = !this.isCheckedForBunchList;
      this.setCheckedForBulkList();
    }
  }

}
