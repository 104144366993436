<div class="dashboard-header__select">
  <button class="btn-h dropdown-button __select-network" [matMenuTriggerFor]="networks">
    <img src="{{ web3Service.chainIdNumber | networkImg }}"/>
    <span>{{getNetworkName(web3Service.chainIdNumber)}}</span>
  </button>
  <mat-menu #networks="matMenu" class="select-network-menu">
    <button mat-menu-item *ngFor="let networkId of availableNetworks" (click)="selectNetworkClick(networkId)">
      <img src="{{ networkId | networkImg }}"/>
      {{getNetworkName(networkId)}}
    </button>
  </mat-menu>
</div>
