<header>
    <div class="container">
        <div class="head-left">
            <div class="logo">
                <a href="{{isElseverse ? '/elseverse' : '#'}}"><img src="{{isElseverse ? 'assets/images/elseverse/elseverse-logo.svg' : 'assets/images/logo.png'}}" alt=""></a>
            </div>
        </div>

        <div class="head-menu head-menu-pc" data-da=".heads,1000,1">
            <ul>
                <li><a routerLink="/" routerLinkActive="activ" [routerLinkActiveOptions]="{exact: true}">Marketplace</a></li>
                <li><a routerLink="/about" routerLinkActive="activ">About</a></li>
                <li><a href="https://app.gamespad.io/" target="_blank">Launchpad</a></li>
                <li><a routerLink="/lottery" routerLinkActive="activ" [routerLinkActiveOptions]="{exact: true}">Lottery</a></li>
                <li *ngIf="isConnected"><a routerLink="/user-page" routerLinkActive="activ">My NFTs</a></li>
                <li *ngIf="elseverseService.canShowPage"><a routerLink="/elseverse" routerLinkActive="activ">Elseverse</a></li>
            </ul>
        </div>

        <div style="display: flex;">
          <div class="head-right head-menu-pc"  data-da=".heads,1000,2" *ngIf="!isConnected">
            <button (click)="connectMetamask()" class="btn-h connect-wallet">connect wallet</button>
          </div>

          <div class="head-right head-menu-pc" data-da=".heads,1000,2" *ngIf="isConnected">
            <a class="btn-cab" style="float: left;" routerLink="user-page">
              <img src="assets/images/user-c.png" alt="">
              {{currentAccount | async | shortAddress}}
            </a>
            <a (click)="logout()" style="float: left; margin-top: 15px; margin-left: 13px;">
              <img src="assets/images/open_au.png" alt="" title="Logout">
            </a>
          </div>

          <network-selector></network-selector>
        </div>

        <div class="menu_btn_mobil" (click)="toggleMenu()">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>

    <div class="heads" (click)="toggleMenu()">
        <div class="heads_off">
            <img src="assets/images/off_head.png" alt="">
        </div>

        <div class="head-right" *ngIf="isConnected">
            <a class="btn-cab" style="float: left;" routerLink="user-page">
                <img src="assets/images/user-c.png" alt="">
                {{currentAccount | async | shortAddress}}
            </a>
            <a (click)="logout()" style="float: left; margin-top: 15px; margin-left: 20px;">
                <img src="assets/images/open_au.png" alt="">
            </a>
        </div>

        <div class="head-menu">
            <ul>
                <li><a routerLink="/" routerLinkActive="activ" [routerLinkActiveOptions]="{exact: true}">Marketplace</a></li>
                <li><a routerLink="/about" routerLinkActive="activ">About</a></li>
                <li><a href="https://app.gamespad.io/" target="_blank">Launchpad</a></li>
                <li><a routerLink="/lottery" routerLinkActive="activ" [routerLinkActiveOptions]="{exact: true}">Lottery</a></li>
                <li *ngIf="isConnected"><a routerLink="/user-page" routerLinkActive="activ">My NFTs</a></li>
                <li *ngIf="elseverseService.canShowPage"><a routerLink="/elseverse" routerLinkActive="activ">Elseverse</a></li>
            </ul>
        </div>

        <div class="head-right" data-da=".heads,1000,2" *ngIf="!isConnected">
            <button (click)="connectMetamask()" class="btn-h connect-wallet">connect wallet</button>
        </div>
    </div>
</header>

<div class="connect_on">
    <div class="connect_on_top">
        Connect to a wallet
    </div>
    <div class="connect_on_list">
        <button><img src="assets/images/metamask.svg" alt="">Metamask</button>
        <button><img src="assets/images/binance.svg" alt="">Binance Wallet</button>
        <button><img src="assets/images/wallet_connect.svg" alt="">WalletConnect</button>
    </div>
    <div class="btn-close_connect"><img src="assets/images/Close.png" alt=""></div>
</div>

<connect-wallet></connect-wallet>
