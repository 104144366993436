<section class="three-block">
    <div class="container" [class.two-cols]="specialEvents?.length > 0">
        <div class="three-block1">
            <h3>gamespad MARKETPLACE</h3>
            <p> Digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell and discover exclusive non-fungible tokens.</p>
            <!-- <div class="border-href"><a href="#">buy nft</a></div> -->
            <img src="assets/images/three-block1.png" alt="">
        </div>
      <div class="special-events" *ngIf="specialEvents.length > 0; else additionalInfo">
          <mat-carousel timings="250ms ease-in"
                        [autoplay]="false"
                        [loop]="false"
                        [hideArrows]="specialEvents.length === 1"
                        [hideIndicators]="specialEvents.length === 1"
                        [maintainAspectRatio]="false">
            <mat-carousel-slide *ngFor="let specialEvent of specialEvents">
              <div class="carousel-cell">
                <div class="title" *ngIf="specialEvent.title">{{specialEvent.title}}</div>
                <nft-card [nftInfo]="specialEvent" matTooltip="Open NFT" matTooltipClass="normal" matTooltipPosition="above"
                          (clickOnCard)="buyClick(specialEvent, true)"
                          [specialBackground]="true"></nft-card>
                <div class="description" *ngIf="specialEvent.eventDescription">{{specialEvent.eventDescription}}</div>
              </div>
            </mat-carousel-slide>
          </mat-carousel>
      </div>
      <ng-template #additionalInfo>
        <div class="three-block2">
          <h3>gamespad launchpad</h3>
          <p>Invest in the top-quality crypto gaming, NFT and metaverse projects on our launchpad.</p>
          <div class="border-href"><a href="https://app.gamespad.io" target="_blank">Learn more</a></div>
          <img src="assets/images/main-page/launchpadbg.png" alt="">
        </div>
        <div class="three-block3">
          <h3>gamespad social media</h3>
          <p>Follow GamesPad on social media to stay up-to-date with our latest news and deals.</p>
          <div class="border-href"><a href="https://t.me/GamesPadAnnouncements" target="_blank">Follow</a></div>
          <img src="assets/images/main-page/Frame 42033.png" alt="">
        </div>
      </ng-template>
    </div>
</section>

<section class="nft-home">
    <div class="container">
      <h3>Marketplace</h3>
        <div class="nft-filter">
            <div class="nft-filter-item">
                <span class="nft-filter-label large">Sort by</span>
                <div class="nft-home-selector-dropdown">
                    <button class="btn-h dropdown-button" [matMenuTriggerFor]="sortBy">
                        <span>{{selectedSort.name}}</span>
                    </button>
                    <mat-menu #sortBy="matMenu" class="select-network-menu">
                        <button mat-menu-item *ngFor="let option of sortOptions" (click)="setSort(option)">
                            {{option.name}}
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="nft-filter-item">
                <span class="nft-filter-label">Type of selling</span>
                <div class="nft-home-selector-dropdown">
                    <button class="btn-h dropdown-button dropdown-button-small" [matMenuTriggerFor]="sellType">
                        <span>{{selectedSellType.name}}</span>
                    </button>
                    <mat-menu #sellType="matMenu" class="select-network-menu">
                        <button class="mat-menu-item-small" mat-menu-item *ngFor="let option of sellTypeOptions" (click)="setSellType(option)">
                            {{option.name}}
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div class="nft-filter-item">
                <span class="nft-filter-label">Search by</span>
                <div class="nft-home-selector-dropdown">
                    <button class="btn-h dropdown-button dropdown-button-small" [matMenuTriggerFor]="searchBy">
                        <span>{{selectedSearchBy.name}}</span>
                    </button>
                    <mat-menu #searchBy="matMenu" class="select-network-menu">
                        <button class="mat-menu-item-small" mat-menu-item *ngFor="let option of searchByOptions" (click)="setSearchBy(option)">
                            {{option.name}}
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div class="nft-filter-item large">
                <div class="search">
                    <input type="text" placeholder="{{selectedSearchBy.name}}" [(ngModel)]="searchValue" (ngModelChange)="setSearch($event)">
                    <button><img src="assets/images/poisk.png" alt=""></button>
                </div>
            </div>

            <div class="nft-filter-item">
                <div class="">
                    <button class="filter_btn filter_btn__reset" (click)="resetFilter()">Reset</button>
                </div>
            </div>
        </div>

        <div class="nft-home-card" *ngIf="positions.length">

            <div class="item_nft" *ngFor="let item of positions"  (click)="buyClick(item)" style="cursor: pointer;">
              <nft-card [nftInfo]="item" matTooltip="Open NFT" matTooltipClass="normal" matTooltipTouchGestures="off" matTooltipPosition="above"></nft-card>
            </div>
        </div>
      <div *ngIf="positions.length && canLoadMore">
          <button class="load-more-btn" (click)="loadNextPage()">Load More</button>
      </div>

        <div class="nft-empty" *ngIf="!positions.length">
            No NFTs with these parameters
        </div>
    </div>
</section>

<jw-modal id="custom-modal-1">
  <p>We've just updated our marketplace.</p>
  <span>Please relist your NFT if you wish to sell them.</span>
  <br /><br />

  <div class="modal border-href" style="cursor: pointer;" ><a style="text-decoration: none;" (click)="closeModal()">Got it</a></div>
  <!-- <div style=" position: relative; display: inline-flex; align-items: center;">
    <a class="modal-btn" (click)="closeModal()">Got it</a>
  </div> -->
</jw-modal>
