import {GMPD_NFT_TYPES} from "../enums/gmpd_nft_enum";
import {PositionMetadataModel} from "./position-metadata.model";
import {ProjectModel} from "./project.model";
import {environment} from "../../environments/environment";
import {INftInfo} from "./nft-info.interface";
import { IBid } from "./bid.interface";

export class PositionModel implements INftInfo {
  readonly id: number;
  readonly amount: number;
  readonly chainId: number;
  readonly createDate: Date;
  readonly updateDate: Date;
  readonly currencyAddress: string;
  readonly ownerAddress: string;
  readonly status: string;
  readonly tokenAddress: string;
  readonly tokenId: number;
  readonly tokenItemTypeId: number;
  readonly tokenTypeId: number;
  readonly tokenImage: string;
  readonly isAuction: boolean;
  readonly minBid?: string;
  readonly description?: string;
  currentBid?: string;
  price: string;
  auctionEndDate?: number;
  isCheckedForBunchList?: boolean;
  forceCheckedForBunchList?: boolean;

  metaData: PositionMetadataModel;
  project: ProjectModel;

  readonly bids? : IBid[];

  private _tokenName: string;

  public get tokenType(): string {
    return GMPD_NFT_TYPES[this.tokenTypeId];
  }

  public get getDescription(): string {
    return this.description || this.metaData?.metadata?.description;
  }

  public get hasVideo(): boolean {
    return !!this.metaData?.metadata?.animation_url;
  }

  public get auctionEnded(): boolean {
    return this.isAuction && this.auctionEndDate < new Date().getTime() / 1000;
  }

  public get auctionFinishSoon(): boolean{
    return this.isAuction && (this.auctionEndDate - new Date().getTime() / 1000) < environment.auction.extensionSpanMinutes * 60;
  }

  public get hasTimeFrames(): boolean{
    return this.isAuction;
  }

  public get startDate(): number {
    return null;
  }

  public get endDate(): number {
    return this.auctionEndDate;
  }

  public get videoUrl(): string {
    return this.metaData?.metadata?.animation_url;
  }

  public get collectionAddress(): string {
    return this.tokenAddress;
  }

  public get collectionName(): string {
    return this.metaData?.name || this.project.name;
  }

  public get collectionSymbol(): string {
    return this.metaData?.symbol;
  }

  public get imageUrl(): string {
    return this.tokenImage || this.metaData?.metadata?.image;
  }

  public get projectIcon(): string {
    return this.project?.icon;
  }

  public get projectCreator(): string {
    return this.project?.creator;
  }

  public get tokenName(): string {
    return this._tokenName || this.metaData?.metadata?.name;
  }

  public set tokenName(value : string) {
    this._tokenName = value;
  }

  public setMetaData(metaData: any): void {
    this.metaData = metaData;
  }
}
