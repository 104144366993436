import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ipfsLink',
})
export class IpfsLinkPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.startsWith('ipfs://')) {
      return `https://ipfs.moralis.io:2053/ipfs/${value.replace("ipfs://", "")}`;
    }
    return value;
  }
}
