import {UserBetModel} from "./user-bet.model";
import {INftInfo} from "./nft-info.interface";
import {ProjectModel} from "./project.model";
import {environment} from "../../environments/environment";

export class LotteryModel implements INftInfo {
  readonly id: number;
  readonly chainId: number;
  readonly startDate: number;
  readonly nftNetworkId: number;
  readonly endDate: number;
  readonly minPrice: number;
  readonly maxPrice: number;
  readonly collectionAddress: string;
  readonly currencyAddress: string;
  winnerAddress: string;
  readonly nftId: number;
  readonly blpPrice: number;
  readonly gmpdPrice: number;
  readonly userBets: UserBetModel[];
  readonly hasTimeFrames: boolean = true;
  readonly isGamesPadOwner: boolean = true;

  metadata: any;

  public get isFinished(): boolean {
    return this.endDate - Math.floor(new Date().getTime() / 1000) <= 0;
  }

  public get isStarted(): boolean {
    return this.startDate - Math.floor(new Date().getTime() / 1000) <= 0;
  }

  // Hook in order for getLotteries to work correctly
  public set isFinished(val: boolean){}

  public get videoUrl(): string {
    return this.metadata?.metadata?.animation_url;
  }

  public get tokenId(): number {
    return this.metadata?.tokenId || this.nftId;
  }

  public get hasVideo(): boolean {
    return !this.imageUrl && !!this.videoUrl;
  }

  public get imageUrl(): string {
    return this.metadata?.metadata?.image || this.metadata?.metadata?.image_url;
  }

  public get collectionName(): string {
    return this.metadata?.name;
  }

  public get collectionSymbol(): string {
    return this.metadata?.symbol;
  }

  public get tokenName(): string {
    return this.metadata?.metadata?.name;
  }

  public get projectIcon(): string {
    return null;
  }

  public get projectCreator(): string {
    return this.collectionName;
  }

  public get getDescription(): string {
    return this.metadata?.metadata?.description;
  }

  public setMetaData(metaData: any): void {
    this.metadata = metaData;
  }

  public get currency(): any{
    return this.tokenInfo.filter(t=>t.address == this.currencyAddress)[0];
  }

  public get tokens(): any {
    if (!this.currencyAddress) {
      this.tokenInfo[0].min = Math.ceil((this.minPrice * LOTTERY_PRICE_DIGIT) / Math.floor(this.blpPrice * LOTTERY_PRICE_DIGIT));
      this.tokenInfo[0].max = Math.floor((this.maxPrice * LOTTERY_PRICE_DIGIT) / Math.floor(this.blpPrice * LOTTERY_PRICE_DIGIT));
      this.tokenInfo[1].min = Math.ceil((this.minPrice * LOTTERY_PRICE_DIGIT) / Math.floor(this.gmpdPrice * LOTTERY_PRICE_DIGIT));
      this.tokenInfo[1].max = Math.floor((this.maxPrice * LOTTERY_PRICE_DIGIT) / Math.floor(this.gmpdPrice * LOTTERY_PRICE_DIGIT));
    } else {
      this.tokenInfo[0].min = this.minPrice;
      this.tokenInfo[0].max = this.maxPrice;
      this.tokenInfo[1].min = this.minPrice;
      this.tokenInfo[1].max = this.maxPrice;
    }
    return this.tokenInfo;
  }

  tokenInfo: any[] = [
    { value: 'blp', viewValue: 'BLP', img: 'assets/images/icon_blp.png', address: environment.blp },
    { value: 'gmpd', viewValue: 'GMPD', img: 'assets/images/icon_t.png', address: environment.gmpd },
  ];
}

export const LOTTERY_PRICE_DIGIT = 100000000;
