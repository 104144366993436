import {Injectable} from '@angular/core';
import {LandInfo} from "./land-info";
import {AuthService} from "../../../services/auth.service";
import {environment} from "../../../../environments/environment";
import {ElseverseConfig} from "../../../../environments/elseverse.config";
import {Web3Service} from "../../../services/web3.service";

@Injectable({
  providedIn: 'root'
})
export class LandService {
  private hoveredStateId: any;
  private selectedId: any;
  // @ts-ignore
  public config: ElseverseConfig = environment.elseVerse;

  constructor(private readonly web3: Web3Service) {
  }

  addHoverEffect(map: mapboxgl.Map) {
    map.on('mouseenter', 'maine', (e) => {
      if (e.features.length > 0) {
        if (this.hoveredStateId !== e.features[0].id) {
          map.setFeatureState(
            {source: 'maine', id: this.hoveredStateId},
            {hover: false}
          );
        }
        this.hoveredStateId = e.features[0].id;
        map.setFeatureState(
          {source: 'maine', id: this.hoveredStateId},
          {hover: true}
        );
      }
    });

    // When the mouse leaves the state-fill layer, update the feature state of the
    // previously hovered feature.
    map.on('mouseleave', 'maine', () => {
      if (this.hoveredStateId !== null) {
        map.setFeatureState(
          {source: 'maine', id: this.hoveredStateId},
          {hover: false}
        );
      }
      this.hoveredStateId = null;
    });
  }

  generateGeoJson(lands: LandInfo[], hexagonRadius: number, forOwned: boolean): any {
    hexagonRadius /=2;
    const denominator = Math.pow(10, 10);

    return {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': lands.map((l:LandInfo) => {
            return {
              'type': 'Feature',
              "id": l.id,
              'properties': this.generateProps(l, forOwned),
              'geometry': {
                'type': 'Polygon',
                'coordinates': [this.generateCoordinatesOnCenter([l.centerX / denominator, l.centerY / denominator], hexagonRadius)]
              }
            }
          })
      }
    };
  }

  private generateProps(landInfo: LandInfo, forOwned: boolean){
    const isOwned = landInfo.isOwned;
    return {
      "backColor": this.config.rarityColors[landInfo.landType.rarity],
      'opacityNormal': landInfo.isSold && !isOwned ? this.config.soldConfig.defaultOpacity : this.config.onSellConfig.defaultOpacity,
      'opacityFocus': landInfo.isSold && !isOwned ? this.config.soldConfig.focusOpacity : this.config.onSellConfig.focusOpacity,
      'icon': landInfo.landType.biome,
      'iconColor': this.config.rarityColors[landInfo.landType.rarity],
      'iconColorClicked': landInfo.isSold || isOwned
        ? '#0F1620'
        : this.config.rarityColors[landInfo.landType.rarity],
      'iconOpacity': landInfo.isSold || isOwned ? 0.24 : 0.8,
      'iconOpacityClicked': landInfo.isSold || isOwned ? 0.3 : 1
    };
  }

  private cosPi12: number = 1/2;
  private sinPi12: number = Math.sqrt(3)/2;

  private generateCoordinatesOnCenter([x,y]: number[], hexagonRadius: number): number [][]{
    return [
      [x + hexagonRadius, y],
      [x + hexagonRadius * this.cosPi12, y + hexagonRadius * this.sinPi12],
      [x - hexagonRadius * this.cosPi12, y + hexagonRadius * this.sinPi12],
      [x - hexagonRadius, y],
      [x - hexagonRadius * this.cosPi12, y - hexagonRadius * this.sinPi12],
      [x + hexagonRadius * this.cosPi12, y - hexagonRadius * this.sinPi12],
      [x + hexagonRadius, y]
    ];
  }
}
