import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { PositionDTO } from '../../dto/position.dto';
import { OpenPositionModel } from '../../models/open-position.model';
import { BuyPositionModel } from '../../models/buy-position.model';
import { CancelPositionModel } from '../../models/cancel-position.model';
import { Web3Service } from "../web3.service";
import { BaseApiService } from "./base-api.service";
import { ProjectDTO } from "../../dto/project.dto";
import {PositionModel} from "../../models/position.model";
import {finalize, map, tap} from "rxjs/operators";
import {LotteryModel} from "../../models/lottery.model";
import {ProjectModel} from "../../models/project.model";
import {MakeBidPositionModel} from "../../models/make-bid-position.model";
import {CreateAuctionModel} from "../../models/create-auction.model";
import {DOCUMENT} from "@angular/common";
import {SpecialEventModel} from "../../models/special-event.model";
import { CloseAuctionModel } from 'src/app/models/close-auction.model';

@Injectable({ providedIn: 'root' })
export class PositionsApiService extends BaseApiService {
  private get chainId(): number {
    return this.web3Service.chainIdNumber;
  }

  constructor(protected readonly httpClient: HttpClient, private readonly web3Service: Web3Service) {
    super(httpClient);
  }

  public getProjects(): Observable<ProjectDTO[]> {
    return this.getRequest<ProjectDTO[]>(`api/positions/projects?chainId=${this.chainId}`);
  }

  public getSpecialEvents(): Observable<SpecialEventModel[]> {
    return this.getRequest<SpecialEventModel[]>(`api/positions/specialEvents`)
      .pipe(map((items:SpecialEventModel[])=>{
        const result = [];
        for (let ev of items){
          result.push(Object.assign(new SpecialEventModel(), ev));
        }
        return result;
      }));
  }

  public getProject(address: string): Observable<ProjectModel> {
    return this.getRequest<ProjectModel>(`api/positions/project/${address}?chainId=${this.chainId}`);
  }

  public getPositions(sortBy: string, sortDir: string, sellType: string, searchBy: string, search: string, page: number, pageSize: number): Observable<PositionDTO> {
    let params: any = {
      chainId: this.chainId,
      sortBy: sortBy,
      sortDir: sortDir,
      sellType: sellType,
      searchBy: searchBy,
      search: search,
      pageNumber: page,
      pageSize: pageSize
    };

    return this.getRequest<PositionDTO>(`api/positions?${new HttpParams({fromObject: params}).toString()}`)
      .pipe(map((t: any) => {
        const res = t;
        for (let i = 0; i < t.items.length; i++){
          const elem = t.items[i];
          if (typeof elem.auctionEndDate == 'string'){
            elem.auctionEndDate = new Date(elem.auctionEndDate).getTime() / 1000;
          }
          res.items[i] = Object.assign(new PositionModel(), t.items[i]);
        }
        return res;
      }));
  }

  public getOwnPositions(ownerAddress: string): Observable<PositionDTO> {
    return this.getRequest<PositionDTO>(`api/positions?chainId=${this.chainId}&ownerAddress=${ownerAddress}`);
  }

  public getAllPositions(): Observable<PositionDTO> {
    return this.getRequest<PositionDTO>(`api/positions?chainId=${this.chainId}`);
  }

  public getPositionInfo(positionId: number, isAuction: boolean, chainId: number): Promise<PositionModel | SpecialEventModel> {
    return this.getRequest<PositionModel>(`api/positions/${positionId}?chainId=${chainId || this.chainId}&isAuction=${isAuction}`).pipe(
      map((t: any) => {
        if (typeof t.auctionEndDate == 'string'){
          t.auctionEndDate = new Date(t.auctionEndDate).getTime() / 1000;
        }
        return t.eventIsFinished !== undefined
          ? Object.assign(new SpecialEventModel(), t)
          : Object.assign(new PositionModel(), t);
      })
    ).toPromise();
  }

  public openPosition(model : OpenPositionModel): Observable<any> {
    return this.postRequest<any>('api/positions/open', {...model, chainId: this.chainId});
  }

  public openBulkPositions(models : any): Observable<any> {
    return this.postRequest<any>('api/positions/openmany', models);
  }

  public createAuction(model : CreateAuctionModel): Observable<any> {
    return this.postRequest<any>('api/positions/createAuction', {...model, chainId: this.chainId});
  }

  public buyPosition(model: BuyPositionModel): Observable<any> {
    return this.putRequest<any>('api/positions/buy', {...model, chainId: this.chainId});
  }

  public makeBidPosition(model: MakeBidPositionModel): Observable<any> {
    return this.putRequest<any>('api/positions/makeBid', {...model, chainId: this.chainId});
  }

  public cancelPosition(model: CancelPositionModel): Observable<any> {
    return this.putRequest<any>('api/positions/cancel', {...model, chainId: this.chainId});
  }

  public closeAuction(model: CloseAuctionModel): Observable<any>{
    return this.postRequest<any>('api/positions/closeauction', model);
  }

  public getRelatedNft(nftAddress: string, tokenId: number, nftChain: number): Observable<PositionModel[]> {
    return this.getRequest<any>(`api/positions/relatedPositions/${tokenId}/?tokenAddress=${nftAddress}&chainId=${nftChain}`).pipe(
      map((t: PositionModel[]) => {
        return t.map((item: any) => Object.assign(new PositionModel(), item));
      })
    );
  }
}
