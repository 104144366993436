<div class="elseverse_fon">
  <div class="elseverse">
    <div class="container">
      <div class="block-1">
        <div class="background">
          <div class="elseverseBgLogo">
            <img src="assets/images/elseverse/welcomBg.png" alt="" />
          </div>
          <div class="elseverseBgElips"></div>
          <div class="elseverseBgLogo2">
            <img src="assets/images/elseverse/elseverseLogoWrite.png" alt="" />
          </div>
          <div class="elseverseBgChara">
            <img src="assets/images/elseverse/Character.png" alt="" />
          </div>
        </div>
        <div class="content">
          <h2>Elseverse</h2>
          <p>
            An interconnected network of parallel universes. Live, dream, play, earn.
          </p>
          <div class="links">
            <a href="https://t.me/ElseVerseAnnouncements" target="_blank"><img src="assets/images/telegram.svg" alt="telegram"></a>
            <a href="https://twitter.com/ElseVerseWorld" target="_blank"><img src="assets/images/twitter.svg" alt="twitter"></a>
            <a href="https://www.instagram.com/elseverse_world/" target="_blank"><img src="assets/images/instagram.svg" alt="instagram"></a>
            <a href="http://www.tiktok.com/@elseverseworld" target="_blank"><img src="assets/images/tiktok.svg" alt="tiktok" /></a>
            <a href="https://discord.gg/elseverseworld" target="_blank"><img src="assets/images/discord.svg" alt="discord"></a>
            <a href="https://www.reddit.com/user/ElseVerseWorld/" target="_blank"><img src="assets/images/reddit.svg" alt="reddit" /></a>
            <a href="https://medium.com/@elseverseworld" target="_blank"><img src="assets/images/medium.svg" alt="medium" /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="landsaleBlock">
      <div class="container">
        <div class="t">Landsale</div>
      </div>
      <div #map>
        <landsale-map
          [selectedSellType]="selectedSellType"
          [selectedBiomeType]="selectedBiomeType"
          [selectedRarityType]="selectedRarityType">
        </landsale-map>
      </div>

    </div>

    <section class="lotteryElseverse">
      <div class="container">
        <div class="t">NFT</div>
        <div class="nft-filter">
          <div class="nft-filter-item">
            <span class="nft-filter-label large">Sort by</span>
            <div class="nft-home-selector-dropdown">
              <button class="btn-h dropdown-button" [matMenuTriggerFor]="sortBy">
                <span>{{selectedSort.name}}</span>
              </button>
              <mat-menu #sortBy="matMenu" class="select-network-menu">
                <button mat-menu-item *ngFor="let option of sortOptions" (click)="setSort(option)">
                  {{option.name}}
                </button>
              </mat-menu>
            </div>
          </div>

          <div class="nft-filter-item">
            <span class="nft-filter-label">Sell type</span>
            <div class="nft-home-selector-dropdown">
              <button class="btn-h dropdown-button dropdown-button-small" [matMenuTriggerFor]="sell">
                <span>{{selectedSellType.name}}</span>
              </button>
              <mat-menu #sell="matMenu" class="select-network-menu">
                <button class="mat-menu-item-small" mat-menu-item *ngFor="let option of sellTypeOptions" (click)="setSellType(option)">
                  {{option.name}}
                </button>
              </mat-menu>
            </div>
          </div>

          <div class="nft-filter-item">
            <span class="nft-filter-label">Biome type</span>
            <div class="nft-home-selector-dropdown">
              <button class="btn-h dropdown-button dropdown-button-small" [matMenuTriggerFor]="biome">
                <span>{{selectedBiomeType.name}}</span>
              </button>
              <mat-menu #biome="matMenu" class="select-network-menu">
                <button class="mat-menu-item-small" mat-menu-item *ngFor="let option of biomeTypeOptions" (click)="setBiomeType(option)">
                  {{option.name}}
                </button>
              </mat-menu>
            </div>
          </div>

          <div class="nft-filter-item">
            <span class="nft-filter-label">Rarity type</span>
            <div class="nft-home-selector-dropdown">
              <button class="btn-h dropdown-button dropdown-button-small" [matMenuTriggerFor]="rarity">
                <span>{{selectedRarityType.name}}</span>
              </button>
              <mat-menu #rarity="matMenu" class="select-network-menu">
                <button class="mat-menu-item-small" mat-menu-item *ngFor="let option of rarityTypeOptions" (click)="setRarityType(option)">
                  {{option.name}}
                </button>
              </mat-menu>
            </div>
          </div>

          <div class="nft-filter-item">
            <div class="">
              <button class="filter_btn filter_btn__reset" (click)="resetFilter()">Reset</button>
            </div>
          </div>
        </div>
        <div
          class="lotteryElseverseList"
          *ngIf="lands?.length > 0; else noLands"
        >
          <div
            class="item_nft"
            *ngFor="let item of data"
            (click)="buyClick(item)"
            style="cursor: pointer"
          >
            <nft-card
              [nftInfo]="item"
              matTooltip="Open Land"
              matTooltipClass="normal"
              matTooltipPosition="above"
            ></nft-card>
            <!--<land-card
              [nftInfo]="item"
              matTooltip="Open Land"
              matTooltipClass="normal"
              matTooltipPosition="above"
            ></land-card>-->
          </div>
        </div>

        <div *ngIf="lands.length && showLoadMore">
          <button class="load-more-btn" (click)="loadMore()">Load More</button>
        </div>

        <ng-template #noLands>
          <div class="no-lands-label">No nfts for now.</div>
        </ng-template>
      </div>
    </section>
  </div>
</div>
