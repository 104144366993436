import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AuthModel } from '../../models/auth.model';
import { AuthDTO } from '../../dto/auth.dto';
import {BaseApiService} from "./base-api.service";

// import collectionABI from '@/abi/collection.json';

@Injectable({ providedIn: 'root' })
export class AuthApiService extends BaseApiService {

    constructor(protected readonly httpClient: HttpClient) {
      super(httpClient);
    }

    public authenticate(model: AuthModel): Observable<AuthDTO> {
        return this.postRequest<AuthDTO>('api/account/authenticate', model)
    }
}
