<section class="widget_one">
    <div class="container">
        <h3>gamespad <br>marketplace</h3>
        <p>GamesPad brings together all aspects of gaming, NFT, and the metaverse. It’s a holistic and chain-agnostic ecosystem
        where impactful crypto gaming concepts come to reality.</p>
        <img src="assets/images/widget_one.png" alt="">
    </div>
</section>

<section class="tier">
    <div class="container">
        <div class="tier-home-top">
            <h2>tier-based system</h2>
            <p>To become a member of our community, you will need to buy the GMPD token. Access transparent and permissionless
            investment opportunities.</p>
        </div>

        <div class="tier-block">
            <div class="tier-item">
                <div class="tier-item-info">
                    <div class="tier-item-top">
                        <img src="assets/images/bot-logo.png" alt="">
                        <p>Bot</p>
                    </div>
                    <div class="tier-item-topr">
                        <img src="assets/images/gmpd-logo.png" alt="">
                        <p>1500 USDT <span>/ 1 NFT</span></p>
                    </div>
                </div>
                <video src="assets/video/bot.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted" class="nft-card__image"></video>
            </div>

            <div class="tier-item">
                <div class="tier-item-info">
                    <div class="tier-item-top">
                        <img src="assets/images/cyborg-logo.png" alt="">
                        <p>Cyborg</p>
                    </div>
                    <div class="tier-item-topr">
                        <img src="assets/images/gmpd-logo.png" alt="">
                        <p>6000 USDT <span>/ 1 NFT</span></p>
                    </div>
                </div>
                <video src="assets/video/cyborg.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted"
                    class="nft-card__image"></video>
            </div>

            <div class="tier-item">
                <div class="tier-item-info">
                    <div class="tier-item-top">
                        <img src="assets/images/adnroid-logo.png" alt="">
                        <p>Android</p>
                    </div>
                    <div class="tier-item-topr">
                        <img src="assets/images/gmpd-logo.png" alt="">
                        <p>15000 USDT <span>/ 1 NFT</span></p>
                    </div>
                </div>
                <video src="assets/video/android.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted"
                    class="nft-card__image"></video>
            </div>

            <div class="tier-item">
                <div class="tier-item-info">
                    <div class="tier-item-top">
                        <img src="assets/images/humanoid-logo.png" alt="">
                        <p>Humanoid</p>
                    </div>
                    <div class="tier-item-topr">
                        <img src="assets/images/gmpd-logo.png" alt="">
                        <p>30000 USDT <span>/ 1 NFT</span></p>
                    </div>
                </div>
                <video src="assets/video/humanoid.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted"
                    class="nft-card__image"></video>
            </div>

            <div class="tier-item">
                <div class="tier-item-info">
                    <div class="tier-item-top">
                        <img src="assets/images/cryptoninja-logo.png" alt="">
                        <p>Crypto Ninja</p>
                    </div>
                    <div class="tier-item-topr">
                        <img src="assets/images/gmpd-logo.png" alt="">
                        <p>60000 USDT <span>/ 1 NFT</span></p>
                    </div>
                </div>
                <video src="assets/video/crypto_ninja.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted"
                    class="nft-card__image"></video>
            </div>

            <div class="tier-item">
                <div class="tier-item-btn">
                    <a href="https://pancakeswap.finance/swap?outputCurrency=0x9720ca160bbd4e7f3dd4bb3f8bd4227ca0342e63" target="_blank">Buy GMPD</a>
                    <a href="/">Buy NFT</a>
                    <div class="border-href btn_yellow"><a href="https://app.gamespad.io/" target="_blank">Go to App</a></div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="quick_start">
    <div class="container">
        <div class="quick_start_t">Quick start</div>
        <div class="quick_start_p">Join Us in Two Steps</div>
        <div class="quick_start_steap quick_start_steap1">
            <div class="quick_start-top">
                <div class="quick_start-left">
                    <img src="assets/images/user.png" alt="">
                </div>

                <div class="quick_start_right">
                    <h6>Step 1</h6>
                    <p>Register and complete KYC on GamesPad App</p>
                </div>
            </div>
            <a href="https://app.gamespad.io/" class="btn">Go to GamesPad App</a>
        </div>

        <div class="quick_start_steap">
            <div class="quick_start-top">
                <div class="quick_start-left">
                    <img src="assets/images/MoneyBox.png" alt="">
                </div>

                <div class="quick_start_right">
                    <h6>Step 2</h6>
                    <p>Choose the tier you want and buy the required GMPD and appropriate NFT</p>
                </div>
            </div>
            <div class="btn_steap">
                <a href="https://pancakeswap.finance/swap?outputCurrency=0x9720ca160bbd4e7f3dd4bb3f8bd4227ca0342e63" target="_blank" class="btn">Buy GMPD</a>
                <a href="/" class="btn">Buy NFT</a>
            </div>
        </div>
    </div>
</section>

<!-- <section class="form-bottom">
    <div class="container">
        <div class="form-bottom-t">Ask a Question</div>
        <form action="">
            <input type="text" placeholder="Name">
            <input type="text" placeholder="E-mail">
            <textarea name="" id=""  placeholder="Your Message"></textarea>
            <div class="border-href"><button>Save</button></div>
        </form>
        <div class="politick_form">By clicking «Send» button, you agreeing to the Gamespad Privacy Policy.</div>
    </div>
</section> -->
